import { getErc20ABI } from '@utils'
import { useContractWrite } from '@utils/contract-interaction'
import BigNumber from 'bignumber.js'
import { useState } from 'react'
import { Abi } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useApproveErc20Transfer = () => {
  const [submitApprovalError, setSubmitApprovalError] =
    useState<Maybe<Error>>(null)
  const [transactionHash, setTransactionHash] =
    useState<Maybe<EvmAddress>>(null)
  const [isPendingSubmitTransaction, setIsPendingSubmitTransaction] =
    useState(false)
  const { executeContractWrite } = useContractWrite()

  const approveErc20Transfer = async (
    chainId: ChainId,
    tokenAddress: EvmAddress,
    recipientAddress: EvmAddress,
    erc20AmountInWei: BigNumber,
  ) => {
    setIsPendingSubmitTransaction(true)
    // USDT ABI is slightly different than ERC20 standard, so have to check
    const isEthUSDT =
      chainId === 1 &&
      tokenAddress.toLowerCase() ===
        '0xdac17f958d2ee523a2206206994597c13d831ec7'.toLowerCase()

    try {
      const writeContractResponse = await executeContractWrite({
        address: tokenAddress,
        abi: getErc20ABI(isEthUSDT) as Abi,
        functionName: 'approve',
        args: [recipientAddress, BigInt(erc20AmountInWei.toString())],
      })
      setTransactionHash(writeContractResponse)
    } catch (e) {
      setSubmitApprovalError(e as Error)
    } finally {
      setIsPendingSubmitTransaction(false)
    }
  }

  const {
    data: transactionReceipt = null,
    isLoading: isLoadingTransactionReceipt,
    error: waitForTransactionReceiptError,
  } = useWaitForTransactionReceipt({
    hash: transactionHash ?? '0x',
    query: {
      enabled: transactionHash !== null,
    },
  })

  return {
    approveErc20Transfer,
    receipt: transactionReceipt,
    isPending: isPendingSubmitTransaction || isLoadingTransactionReceipt,
    error: submitApprovalError || waitForTransactionReceiptError,
  }
}

import BigNumber from 'bignumber.js'
import { EventTimelineValues } from '../types/web/read'

export const hydrateISO8601String = (dateStr: ISO8601String): Date => {
  return new Date(dateStr)
}

export const convertLegacyEventTypeToEventType = (
  legacyType: LegacyEventType,
): EventType => {
  switch (legacyType) {
    case 'DISTRIBUTE':
      return 'distribution'
    case 'RAISE':
      return 'sale'
    case 'IDENTITY':
      return 'identity'
  }
}

export const convertApiKycRestrictionTypeToKycRestrictionType = (
  legacyType: KycRestrictionDatabaseType,
): KycRestrictionType => {
  switch (legacyType) {
    case '0':
      return 'none'
    case '1':
      return 'kyc_required'
    case '3':
      return 'kyc_and_accredited_investor_required'
  }
}

export const hydrateEventDates = (
  event: EventTimelineValues<ISO8601String>,
): EventTimelineValues<Date> => {
  return {
    registrationStartTime: hydrateISO8601String(event.registrationStartTime),
    registrationEndTime:
      event.registrationEndTime !== null
        ? hydrateISO8601String(event.registrationEndTime)
        : null,
    startTime:
      event.startTime !== null ? hydrateISO8601String(event.startTime) : null,
    endTime:
      event.endTime !== null ? hydrateISO8601String(event.endTime) : null,
  }
}

export const parsePositiveInteger = (param: string): Maybe<ID> => {
  // Don't allow leading zeros
  if (param.startsWith('0')) {
    return null
  }

  const paramInt = Number(param)

  if (!isPositiveInteger(paramInt)) {
    return null
  }

  return paramInt
}

export const isPositiveInteger = (num: unknown): boolean => {
  return typeof num === 'number' && Number.isInteger(num) && num > 0
}

/**
 * Rounds a BigNumber to the smallest number of decimal places between
 * `minDecimals` and `maxDecimals` where the result is non-zero.
 * Preserves significant digits while avoiding excessive trailing zeros.
 *
 * @param value - The BigNumber to round.
 * @param minDecimals - Minimum decimal places to try (default: 2).
 * @param maxDecimals - Maximum decimal places to try (default: 6).
 * @param roundingMode - BigNumber rounding mode (e.g. ROUND_UP or ROUND_DOWN).
 * @returns The rounded BigNumber.
 */
export const roundInDigitRange = (
  value: BigNumber,
  minDecimals = 2,
  maxDecimals = 6,
  roundingMode: BigNumber.RoundingMode = BigNumber.ROUND_UP,
): BigNumber => {
  for (let i = minDecimals; i <= maxDecimals; i++) {
    const rounded = value.decimalPlaces(i, roundingMode)

    // Avoid over-rounding: don't allow more than 10x increase
    const ratio = rounded.div(value)

    if (!rounded.isZero()) {
      if (
        (roundingMode === BigNumber.ROUND_DOWN && rounded.lte(value)) ||
        (roundingMode === BigNumber.ROUND_UP &&
          rounded.gte(value) &&
          ratio.lte(2))
      ) {
        return rounded
      }
    }
  }
  return value
}

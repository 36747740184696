import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface HeaderGroupProps {
  className?: string
  children: React.ReactNode
}

export const HeaderGroup = forwardRef<HTMLDivElement, HeaderGroupProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={twMerge(
          'flex items-center flex-shrink-0 h-12 p-2 pl-4 border border-[#eff3ff] gap-2 rounded-full bg-white',
          className,
        )}
        {...props}
      >
        {children}
      </div>
    )
  },
)

HeaderGroup.displayName = 'HeaderGroup'

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

export const useGetDomainStatus = (domainName: string) => {
  const result = useQuery({
    enabled: false,
    queryKey: ['status', domainName],
    queryFn: () =>
      axios.get(
        import.meta.env.PROCESS_ENV === 'development'
          ? `http://${domainName}/up.json`
          : `https://${domainName}/up.json`,
      ),
  })

  return {
    ...result,
    domainStatus:
      JSON.stringify(result?.data?.data) === '{"up":true}'
        ? 'Active'
        : 'Inactive',
    loading: result?.isLoading || result?.isRefetching,
    getDomainStatus: result.refetch,
  }
}

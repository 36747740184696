import {
  useGetChains,
  useGetProjectSocials,
  useGetProjectTheme,
} from '@apiServices'
import { Footer, Header, Sidebar } from '@components'
import { useAuth, useLayout } from '@contexts'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom'

interface LayoutProps {
  className?: string
}

export const Layout = ({ className = '' }: LayoutProps) => {
  const { isAuthenticated } = useAuth()
  const authStatus = isAuthenticated ? 'authenticated' : 'unauthenticated'

  const { shouldHideSidebar, className: layoutClassName } = useLayout()
  const { data: chains } = useGetChains()
  const { data: projectSocials } = useGetProjectSocials()
  const { data: projectTheme } = useGetProjectTheme()

  return (
    <>
      <Helmet>
        {/* Affects CSS styling via [data-auth] attribute selectors. */}
        <body data-auth={authStatus}></body>
      </Helmet>

      {isAuthenticated && chains && (
        <>
          <Header chains={chains} />
          {!shouldHideSidebar && <Sidebar />}
        </>
      )}

      <div
        className={classNames(
          'content-body',
          className,
          !isAuthenticated || shouldHideSidebar ? '!pl-0' : '',
        )}
      >
        <div
          className={classNames(
            'container-fluid flex justify-center',
            isAuthenticated ? 'max-w-7xl' : 'max-w-full',
            layoutClassName,
          )}
        >
          <div className='h-full'>
            <Outlet />
          </div>
          {isAuthenticated && projectSocials && projectTheme && (
            <Footer
              projectSocials={projectSocials}
              highlightColor={projectTheme.colors.primary}
            />
          )}
        </div>
      </div>
    </>
  )
}

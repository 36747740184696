import {
  useGetMyPolkadotWalletRequirement,
  useGetMyWallets,
} from '@apiServices'
import { LoadingIndicator, Text } from '@components'
import { useToast } from '@contexts'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { UserWalletAddress } from 'tokensoft-shared-types'
import { EligibilityItemStatus } from './eligibility-item-status'
import { EventModal } from './event-modal'
import { PolkadotWalletVerification } from './polkadot-wallet-verification'

interface PolkadotWalletEligibilityStatusProps {
  eventId: ID
  highlightColor: HexColorCode
  onMetRequirement: () => void
}

const doesUserMeetPolkadotWalletRequirements = (
  userWallets: UserWalletAddress[],
  polkadotWalletRequirements: {
    isSpecificWalletRequired: boolean
    requiredPolkadotAddress: Maybe<SS58Address>
  },
) => {
  const { isSpecificWalletRequired, requiredPolkadotAddress } =
    polkadotWalletRequirements

  // Any polkadot wallet is valid, and user has one
  if (
    !isSpecificWalletRequired &&
    userWallets.some((wallet) => wallet.blockchainType === 'POLKADOT')
  ) {
    return true
  }

  if (isSpecificWalletRequired) {
    // A specific wallet is required, but there is no wallet listed for the
    // current user
    if (requiredPolkadotAddress === null) {
      return false
    }

    // A specific wallet is required - valid if the user has it
    return userWallets.some(
      (wallet) =>
        wallet.blockchainType === 'POLKADOT' &&
        wallet.walletAddress === requiredPolkadotAddress,
    )
  }

  return false
}

export const PolkadotWalletEligibilityStatus = ({
  eventId,
  highlightColor,
  onMetRequirement,
}: PolkadotWalletEligibilityStatusProps) => {
  const { showErrorToast } = useToast()

  const [hasMetRequirement, setHasMetRequirement] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    data: userWallets,
    isPending: areUserWalletsPending,
    error: polkadotWalletsError,
  } = useGetMyWallets()

  const {
    data: polkadotWalletRequirement,
    isPending: isPolkadotWalletRequirementPending,
    error: polkadotWalletRequirementError,
  } = useGetMyPolkadotWalletRequirement(eventId)

  const [isRequirementFailed, setIsRequirementFailed] = useState(false)

  // If user changes wallet or email, requirement status resets
  useEffect(() => {
    if (!polkadotWalletRequirement) return

    setIsRequirementFailed(false)
  }, [polkadotWalletRequirement])

  useEffect(() => {
    if (!userWallets || !polkadotWalletRequirement) return

    const doesUserMeetRequirement = doesUserMeetPolkadotWalletRequirements(
      userWallets,
      polkadotWalletRequirement,
    )

    if (doesUserMeetRequirement) {
      setHasMetRequirement(true)
      onMetRequirement()
    }
  }, [userWallets, polkadotWalletRequirement, onMetRequirement])

  useEffect(() => {
    if (polkadotWalletsError) {
      showErrorToast({ description: polkadotWalletsError.message })
    }
  }, [polkadotWalletsError, showErrorToast])

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (hasMetRequirement) {
      setIsModalOpen(false)
      onMetRequirement()
    }
  }, [hasMetRequirement, onMetRequirement])

  useEffect(() => {}, [userWallets])

  useEffect(() => {
    if (!polkadotWalletRequirement) return

    if (
      polkadotWalletRequirement.isSpecificWalletRequired &&
      polkadotWalletRequirement.requiredPolkadotAddress === null
    ) {
      setIsRequirementFailed(true)
    }
  }, [polkadotWalletRequirement])

  if (areUserWalletsPending || isPolkadotWalletRequirementPending) {
    return <LoadingIndicator />
  }

  if (!polkadotWalletRequirement) {
    return (
      <Text className='text-red-600'>
        Error loading Polkadot Wallet Requirement
      </Text>
    )
  }

  let polkadotActionTitle = 'Connect Polkadot (SS58) Wallet'
  let polkadotModalContentTitle = 'Verify Your Polkadot Wallet Ownership'

  const isAutonomys =
    window.location.origin === 'https://autonomys.tokensoft.io' ||
    window.location.origin === 'https://autonomys.qatokensoft.com'

  if (isAutonomys) {
    polkadotActionTitle = 'Connect Autonomys Wallet'
    polkadotModalContentTitle = 'Connect Autonomys Wallet'
  }

  return (
    <>
      <EventModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel={polkadotModalContentTitle}
      >
        {!isRequirementFailed ? (
          <PolkadotWalletVerification
            isAutonomys={isAutonomys}
            requiredWalletAddressMatch={
              polkadotWalletRequirement?.requiredPolkadotAddress ?? null
            }
            onVerifiedPolkadotWallet={() => setHasMetRequirement(true)}
          />
        ) : (
          <div className='pt-4 pb-5 px-6 flex flex-col items-center gap-4'>
            <Text className='text-2xl font-bold text-gray-700'>
              {polkadotModalContentTitle}
            </Text>
            <div className='flex flex-col gap-2 text-md font-medium text-gray-700'>
              <Text>
                To be eligible for this event, you must prove ownership of the{' '}
                {isAutonomys ? 'Autonomys' : 'polkadot'} wallet address the
                project admins have designated for your account. However, no{' '}
                {isAutonomys ? 'Autonomys' : 'polkadot'} wallet address is
                specified for your{' '}
                {isAutonomys ? 'email' : 'account email or ethereum address'}.
                There may be a few reasons for this:
              </Text>
              <ol>
                <li>
                  1. Your email is not verified. Please ensure your email is
                  verified on your{' '}
                  <Link className='underline' to='/account'>
                    profile page
                  </Link>
                  .
                </li>
                <li>
                  2. You are not connected with the expected email
                  {isAutonomys ? '.' : ' or ethereum wallet address.'}
                </li>
                <li>3. You are not eligible for this event.</li>
              </ol>
              <Text>
                Please contact the project admins to confirm your account
                details.
              </Text>
            </div>
          </div>
        )}
      </EventModal>
      <EligibilityItemStatus
        onClick={openModal}
        title={polkadotActionTitle}
        progressStatus={
          isRequirementFailed
            ? 'failed'
            : hasMetRequirement
              ? 'completed'
              : 'incomplete'
        }
        highlightColor={highlightColor}
      />
    </>
  )
}

import { useAdminApiClient } from '@apiClients'
import { usePagination } from '@hooks'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import {
  PrivateEventMember,
  PrivateEventMemberRaw,
} from 'tokensoft-shared-types'

interface UseGetPrivateEventMembersParams {
  eventId: number
  filters?: {
    wallet?: Maybe<string>
    email?: Maybe<string>
  }
  sorting?: {
    orderBy?: 'email' | 'wallet'
    sortOrder?: 'asc' | 'desc'
  }
}

export const useGetPrivateEventMembersPaginated = ({
  eventId,
  filters = {},
  sorting = {},
}: UseGetPrivateEventMembersParams) => {
  const defaultPageSize = 10

  const {
    pageSize,
    pageNumber,
    setPageSize,
    setPageNumber,
    fetchNextPage,
    fetchPreviousPage,
    hasPreviousPage,
    resetPagination,
  } = usePagination(defaultPageSize)

  const [resultCount, setResultCount] = useState(0)
  const fetchWithClient = useAdminApiClient()
  const queryClient = useQueryClient()

  const queryKey = [
    'privateEventMembers',
    eventId,
    filters,
    sorting,
    pageSize,
    pageNumber,
  ]

  const invalidate = () => {
    void queryClient.invalidateQueries({ queryKey })
  }

  const fetchPrivateEventMembers = async () => {
    const params = new URLSearchParams({
      offset: ((pageNumber - 1) * pageSize).toString(),
      limit: pageSize.toString(),
      ...(filters.wallet ? { wallet: filters.wallet } : {}),
      ...(filters.email ? { email: filters.email } : {}),
      ...(sorting.orderBy ? { orderBy: sorting.orderBy } : {}),
      ...(sorting.sortOrder ? { sortOrder: sorting.sortOrder } : {}),
    }).toString()

    const response = (await fetchWithClient(
      `events/${eventId}/privacy/members?${params}`,
    )) as PaginatedResponse<PrivateEventMemberRaw>

    const data: PrivateEventMember[] = response.data.map((member) => ({
      id: parseInt(member.id),
      eventId: parseInt(member.eventId),
      email: member.email,
      wallet: member.wallet,
      createdAt: new Date(member.createdAt),
      updatedAt: new Date(member.updatedAt),
    }))

    return {
      ...response,
      totalCount: response.totalCount,
      data,
    }
  }

  const result = useQuery({
    queryKey,
    queryFn: fetchPrivateEventMembers,
  })

  const hasNextPage = result.data
    ? pageNumber < Math.ceil(result.data.totalCount / pageSize)
    : false

  useEffect(() => {
    if (result.data === undefined) {
      return
    }

    const responseResultCount = result.data.totalCount

    if (responseResultCount && responseResultCount !== resultCount) {
      setResultCount(responseResultCount)
    }
  }, [result.data, resultCount])

  return {
    data: result.data?.data ?? [],
    count: resultCount,
    isLoading: result.isLoading,
    invalidateQuery: invalidate,
    pageSize,
    pageNumber,
    setPageSize,
    setPageNumber,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    resetPagination,
  }
}

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  EditIcon,
  Section,
  Stacked,
  Stretched,
  TableData,
  TableHeader,
  Text,
  TextLink,
} from '@components'
import { useAccount, useNetworks } from '@contexts'
import { capitalize, isValidDate, unixToLocalDateTime } from '@utils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const SalePaymentConfigSummary = ({ context, className = '' }: any) => {
  const { account } = useAccount()
  const navigate = useNavigate()
  const { getNetworkDetails } = useNetworks()
  const [isOpen, setIsOpen] = useState(!Object.keys(context).includes('id'))

  const { symbol: nativeSymbol, name: networkName } = getNetworkDetails(
    context?.networkId,
  )

  let paymentMethods = context.paymentMethods || []
  if (context.nativePaymentsEnabled) {
    paymentMethods = [
      ...paymentMethods,
      {
        native: true,
      },
    ]
  }

  let formattedStartTime
  let formattedEndTime

  if (context?.unixStartTime) {
    formattedStartTime = unixToLocalDateTime(
      context.unixStartTime,
      account?.timezone,
    )
  } else {
    formattedStartTime = isValidDate(context.startTime)
      ? new Date(context.startTime).toLocaleString()
      : '-'
  }

  if (context?.unixEndTime) {
    formattedEndTime = unixToLocalDateTime(
      context.unixEndTime,
      account?.timezone,
    )
  } else {
    formattedEndTime = isValidDate(context.endTime)
      ? new Date(context.endTime).toLocaleString()
      : '-'
  }

  const formattedPaymentMethods = paymentMethods
    ?.map((method: any) => {
      // remove payment methods BLU (id = 52) for all projects except for project id 2
      if (method.id === 52 && context.event.project.id !== 2) {
        return null
      }
      return method.native ? nativeSymbol : method.symbol
    })
    .filter((method: any) => method !== null)
    .join(',')

  const key = 'sale-payment-config-summary'

  return (
    <Card className={className}>
      <CardHeader place={'center'}>
        <CardTitle
          open={isOpen}
          onOpen={context.id ? setIsOpen : undefined}
          data-testid={`${key}-title`}
        >
          Network &amp; Payment Configuration
        </CardTitle>
        {context.id ? (
          <TextLink
            width={'fit'}
            onClick={() => {
              navigate(
                `/admin/project/${context.event.project.id}/event/${context.event.id}/sale/payment-config`,
              )
            }}
          >
            <EditIcon />
            <Text>Edit Details</Text>
          </TextLink>
        ) : null}
      </CardHeader>

      <CardBody open={isOpen}>
        <Stacked>
          <Section gap={5} place={'start'}>
            <Stretched gap={5} place={'start'}>
              <Stretched ygap={5}>
                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-network-header`}>
                    Network
                  </TableHeader>
                  <TableData data-testid={`${key}-network-data`}>
                    {networkName}
                  </TableData>
                </Col>

                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-payment-methods-header`}>
                    Payment Methods
                  </TableHeader>
                  <TableData data-testid={`${key}-payment-methods-data`}>
                    {formattedPaymentMethods}
                  </TableData>
                </Col>
              </Stretched>

              <Stretched ygap={5}>
                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-start-date-header`}>
                    Start Date
                  </TableHeader>
                  <TableData data-testid={`${key}-start-date-data`}>
                    {context?.saleOpenDate}
                  </TableData>
                </Col>

                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-end-date-header`}>
                    End Date
                  </TableHeader>
                  <TableData data-testid={`${key}-end-date-data`}>
                    {context?.saleCloseDate}
                  </TableData>
                </Col>
                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-asset-type-header`}>
                    Asset Type
                  </TableHeader>
                  <TableData data-testid={`${key}-asset-type-data`}>
                    {capitalize(context?.assetType ?? '')}
                  </TableData>
                </Col>
                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader
                    data-testid={`${key}-show-sale-progress-to-users-header`}
                  >
                    Show Sale Progress To Users
                  </TableHeader>
                  <TableData
                    data-testid={`${key}-show-sale-progress-to-users-data`}
                  >
                    {context?.showSaleProgressToUsers ? 'Yes' : 'No'}
                  </TableData>
                </Col>
              </Stretched>
            </Stretched>
          </Section>
        </Stacked>
      </CardBody>
    </Card>
  )
}

import { useEffect, useState } from 'react'

/**
 * A generic hook to manage data in session storage.
 * @param key - The key under which the data is stored in session storage.
 * @param initialValue - The initial value to use if no data is found in session storage.
 * @returns A stateful value and a function to update it.
 */
export const useSessionStorage = <T>(
  key: string,
  initialValue: T | (() => T),
): [T, (value: T | ((prevValue: T) => T)) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key)
      if (item) {
        const parsedValue = JSON.parse(item) as T
        return parsedValue
      }
      // If no item is found, return the initial value
      return initialValue instanceof Function ? initialValue() : initialValue
    } catch (error) {
      console.error(`Error reading session storage key "${key}":`, error)
      return initialValue instanceof Function ? initialValue() : initialValue
    }
  })

  const setValue = (value: T | ((prevValue: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore))

      // dispatch temporarily to localStorage so that other tabs will receive the 'storage' event, and can synchronize
      window.localStorage.setItem(`sync:${key}`, 'truthy')
      window.localStorage.removeItem(`sync:${key}`)
    } catch (error) {
      console.error(`Error setting session storage key "${key}":`, error)
    }
  }

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === `sync:${key}` && event.newValue) {
        sessionStorage.removeItem('synchronized')
        localStorage.setItem('getSessionStorage', 'truthy')
        localStorage.removeItem('getSessionStorage')
      }

      if (
        event.newValue &&
        (event.key === 'sessionStorage' || event.key === 'sessionStorageForced')
      ) {
        try {
          // another tab sent data <- get it
          const data = JSON.parse(event.newValue) as Record<string, string>

          if (data[key] !== null) {
            const parsedValue = JSON.parse(data[key]) as T
            setStoredValue(parsedValue)
          } else {
            // If event.newValue is null, reset to the initial value
            setStoredValue(
              initialValue instanceof Function ? initialValue() : initialValue,
            )
          }
        } catch (error) {
          console.error(`Error updating session storage key "${key}":`, error)
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [key, initialValue])

  return [storedValue, setValue]
}

import { twMerge } from 'tailwind-merge'

interface ProgressBarProps {
  className?: string
  percentComplete: number
  gradient?: {
    from: string
    to: string
  }
}

export const ProgressBar = ({
  className,
  percentComplete,
  gradient = {
    from: 'green-500',
    to: 'green-200',
  },
}: ProgressBarProps) => {
  return (
    <>
      <div
        className={twMerge(
          'w-full bg-gray-300 h-3 rounded-md overflow-hidden my-2',
          className,
        )}
      >
        <div
          className={twMerge(
            'h-3 bg-gradient-to-r',
            gradient.from,
            gradient.to,
          )}
          style={{
            width: `${percentComplete}%`,
          }}
        />
      </div>
      <div className='text-sm'>~{percentComplete}%</div>
    </>
  )
}

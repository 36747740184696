import {
  useNotifications,
  useRemoveAllNotifications,
  useRemoveNotification,
} from '@apiServices'
import { NotificationIcon, NotificationListItem } from '@components'
import { HeaderButton } from '@components/layout/header/header-button'
import { useToast } from '@contexts'
import * as Popover from '@radix-ui/react-popover'
import { FaBell } from 'react-icons/fa'
import './notification-popover.css'

export const NotificationPopover = () => {
  const { notifications } = useNotifications()
  const { showErrorToast } = useToast()

  const { mutateAsync: removeNotification } = useRemoveNotification()
  const { mutateAsync: removeAllNotifications } = useRemoveAllNotifications()

  const deleteNotification = async (id: string) => {
    try {
      await removeNotification(id)
    } catch (e) {
      showErrorToast({ description: 'Error clearing notification' })
    }
  }

  const deleteAllNotifications = async () => {
    try {
      await removeAllNotifications()
    } catch (e) {
      showErrorToast({ description: 'Error clearing notifications' })
    }
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <HeaderButton className='bg-[#eff3ff] relative'>
          <NotificationIcon />
          {notifications && notifications.length > 0 && (
            <div className='absolute h-5 min-w-5 leading-[1.2] p-1 -top-1 -right-1 text-xs rounded-full bg-red-500 text-white'>
              <span>{notifications.length}</span>
            </div>
          )}
        </HeaderButton>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className='notification-popover-panel' sideOffset={5}>
          <div className='notification-popover-container translate-y-5'>
            <div className='notification-items-container'>
              {notifications?.length > 0 && (
                <div
                  className='notification-clear-all'
                  onClick={deleteAllNotifications}
                >
                  Clear All
                </div>
              )}
              {notifications?.length > 0 ? (
                notifications.map((notification: any, i: number) => (
                  <NotificationListItem
                    key={i}
                    notification={notification}
                    onDelete={() => deleteNotification(notification.id)}
                  />
                ))
              ) : (
                <>
                  <div className='notification-no-records-found-container'>
                    <div className='notification-no-records-found-icon'>
                      <FaBell size={36} />
                    </div>
                    <p className='notification-no-records-found-text'>
                      No new notifications
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

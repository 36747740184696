export const StatusIconError = () => {
  return (
    <svg
      className='shrink-0'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1766_56823)'>
        <path
          d='M8.53255 3.29706L1.51421 15.0137C1.36961 15.2643 1.29311 15.5484 1.29233 15.8377C1.29155 16.127 1.36651 16.4115 1.50977 16.6629C1.65302 16.9142 1.85956 17.1237 2.10888 17.2705C2.35819 17.4173 2.64158 17.4962 2.93088 17.4996H16.9684C17.2577 17.4962 17.5411 17.4173 17.7904 17.2705C18.0397 17.1237 18.2462 16.9142 18.3895 16.6629C18.5327 16.4115 18.6077 16.127 18.6069 15.8377C18.6061 15.5484 18.5296 15.2643 18.385 15.0137L11.3659 3.29706C11.2181 3.05372 11.0101 2.85255 10.762 2.71294C10.5138 2.57334 10.2339 2.5 9.94921 2.5C9.6645 2.5 9.38459 2.57334 9.13646 2.71294C8.88833 2.85255 8.68034 3.05372 8.53255 3.29706Z'
          stroke='#D01010'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 7.5V10.8333'
          stroke='#D01010'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 14.166H10.0083'
          stroke='#D01010'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1766_56823'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

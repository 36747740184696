import { useLoginOptions } from '@contexts/login-options/login-options-context'
import { PrivyProvider } from '@privy-io/react-auth'
import { taurus } from '@utils/extra-chains'
import { ReactNode } from 'react'
import {
  anvil,
  arbitrum,
  avalanche,
  avalancheFuji,
  base,
  baseSepolia,
  bsc,
  bscTestnet,
  celoAlfajores,
  gnosis,
  mainnet,
  optimism,
  optimismSepolia,
  polygon,
  polygonAmoy,
  polygonMumbai,
  scroll,
  scrollSepolia,
  sepolia,
  zkSync,
  zkSyncSepoliaTestnet,
} from 'viem/chains'

interface TSPrivyProviderProps {
  children: ReactNode
}

export const TSPrivyProvider = ({ children }: TSPrivyProviderProps) => {
  const { isEmailLoginEnabled, isWalletLoginEnabled } = useLoginOptions()

  // Dynamically determine login methods
  const loginMethods: ('email' | 'wallet')[] = []

  if (isEmailLoginEnabled) {
    loginMethods.push('email')
  }

  if (isWalletLoginEnabled) {
    loginMethods.push('wallet')
  }

  return (
    <PrivyProvider
      appId={`${import.meta.env.VITE_PRIVY_APP_ID}`}
      config={{
        loginMethods,
        appearance: {
          theme: 'light',
          accentColor: '#676FFF',
        },
        embeddedWallets: {
          createOnLogin: 'users-without-wallets',
        },
        defaultChain: sepolia,
        supportedChains: [
          arbitrum,
          avalanche,
          avalancheFuji,
          base,
          baseSepolia,
          bsc,
          bscTestnet,
          celoAlfajores,
          gnosis,
          mainnet,
          optimism,
          optimismSepolia,
          polygon,
          polygonAmoy,
          polygonMumbai,
          scroll,
          scrollSepolia,
          sepolia,
          zkSync,
          zkSyncSepoliaTestnet,
          anvil,
          taurus,
        ],
      }}
    >
      {children}
    </PrivyProvider>
  )
}

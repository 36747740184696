import { useAdminApiClient } from '@apiClients'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useCreateProjectDomain = () => {
  const client = useAdminApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: { domainName: string }) => {
      return client('domains', {
        method: 'post',
        data: {
          domainName: data.domainName,
        },
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

import {
  useCreateCertificate,
  useCreateDistribution,
  useCreateProjectDomain,
  useGetCertificate,
  useGetDomainStatus,
  useGetDomainStatuses,
  useRemoveProjectDomain,
} from '@apiServices'
import {
  AnimatedCheckmark,
  Button,
  ButtonRow,
  Card,
  InputGroup,
  LoadingIndicator,
  RadioInput,
  Section,
  Stacked,
  Stretched,
  Text,
} from '@components'
import { useProject, useToast } from '@contexts'
import { VERIFICATION_STATUS } from '@enums'
import { useClipboard } from '@hooks'
import { useEffect, useState } from 'react'
import {
  AiOutlineCopy,
  AiOutlineEdit,
  AiOutlineLoading3Quarters,
} from 'react-icons/ai'
import { FaTrashAlt } from 'react-icons/fa'
import { VscLinkExternal } from 'react-icons/vsc'
import { DomainStatus } from './domain-status'
import { DomainValidationStatus } from './domain-validation-status'
import { StatusIcon } from './status-icon'
import { AxiosError } from 'axios'

export const ProjectDomains = () => {
  const { project, loading, setView } = useProject()
  const { domainStatuses } = useGetDomainStatuses(project?.domains ?? [])
  const { showSuccessToast, showErrorToast } = useToast()
  const { copyToClipboard } = useClipboard()
  const [newDomainName, setNewDomainName] = useState<Maybe<string>>(null)
  const [removedDomainId, setRemovedDomainId] = useState<Maybe<ID>>(null)
  const [step, setStep] = useState(0)
  const [selectedDomainId, setSelectedDomainId] = useState<string>('')
  const [validationMethod, setValidationMethod] =
    useState<DNSCertificateValidationMethod>('dns')

  const selectedDomain = project.domains?.find(
    (d: any) => d.id === selectedDomainId,
  )

  const {
    data: certificate,
    isLoading: certificateLoading,
    refetch: getCertificate,
  } = useGetCertificate(selectedDomainId)

  const { mutate: createDistribution, isPending: createDistributionLoading } =
    useCreateDistribution()

  const {
    domainStatus,
    isLoading: domainStatusLoading,
    getDomainStatus,
  } = useGetDomainStatus(selectedDomain?.domainName as string)

  const {
    mutate: create,
    isPending: createLoading,
    isError: createIsError,
  } = useCreateProjectDomain()

  const {
    mutate: createCertificate,
    isPending: createCertificateLoading,
    isError: createCertificateIsError,
  } = useCreateCertificate()

  const { mutate: remove, isPending: removeLoading } = useRemoveProjectDomain()

  const mutationInProgress =
    createLoading || removeLoading || createCertificateLoading

  useEffect(() => {
    if (domainStatus === 'Active') {
      setStep(4)
    }
  }, [domainStatus])

  const handleDomainNameChange = (changed: any) => {
    setNewDomainName(changed.target.value as string)
  }

  const handleAddDomain = () => {
    if (!newDomainName) return

    create(
      {
        domainName: newDomainName,
      },
      {
        onSuccess: (data) => {
          setSelectedDomainId(data.id)
          showSuccessToast({ description: 'Successfully added domain.' })
          setStep(1)
        },
        onError: (error) => {
          showErrorToast({
            description: 'Invalid or duplicate domain name.  Please try again.',
          })
        },
      },
    )
  }

  const handleRemoveDomain = (projectDomainId: number) => {
    setRemovedDomainId(projectDomainId)
    remove(
      {
        projectDomainId,
      },
      {
        onSuccess: () => {
          showSuccessToast({ description: 'Successfully removed domain.' })
          setRemovedDomainId(null)
        },
        onError: (error) => {
          showErrorToast({
            description: 'Error removing domain.  Please try again.',
          })
          setRemovedDomainId(null)
        },
      },
    )
  }

  const handleEditDomain = (id: string) => {
    setSelectedDomainId(id)
    const selectedDomain = project.domains?.find((d: any) => d.id === id)

    if (selectedDomain.distributionId) {
      setStep(3)
    } else if (selectedDomain.arn) {
      setStep(2)
    } else {
      setStep(1)
    }
  }

  const handleCreateCertificate = () => {
    createCertificate(
      {
        domainId: selectedDomainId,
        validationMethod,
      },
      {
        onSuccess: (data) => {
          showSuccessToast({
            description: 'Successfully created certificate.',
          })
          setStep(2)
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            showErrorToast({
              description: error.response?.data.message as string,
            })
            return
          }
          showErrorToast({
            description: 'Error creating certificate. Please try again.',
          })
        },
      },
    )
  }

  const handleCreateDistribution = () => {
    createDistribution(
      {
        domainId: parseInt(selectedDomainId),
      },
      {
        onSuccess: (data) => {
          showSuccessToast({
            description: 'Successfully created distribution.',
          })
          setStep(3)
        },
        onError: (error) => {
          showErrorToast({
            description: 'Error creating distribution.  Please try again.',
          })
        },
      },
    )
  }

  const handleRefresh = () => {
    getCertificate()
  }

  const handleCheckDomainStatus = () => {
    getDomainStatus()
  }

  const content = {
    0: (
      <Stacked>
        <Card title='Project Domains'>
          <div className='h-full flex flex-col justify-between'>
            <div>
              <table className={'w-full'}>
                <thead>
                  <tr>
                    <th
                      style={{ borderBottom: '1px solid var(--neutral-light)' }}
                      className={'text-left'}
                    >
                      Domain
                    </th>
                    <th
                      style={{ borderBottom: '1px solid var(--neutral-light)' }}
                      className={'text-center'}
                    >
                      Status
                    </th>
                    <th
                      style={{ borderBottom: '1px solid var(--neutral-light)' }}
                      className={'text-right'}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {project.domains?.length > 0 && (
                    <>
                      {project.domains.map((domain: any, i: number) => (
                        <tr key={i}>
                          <td className={'text-left py-2'}>
                            <a
                              target='_blank'
                              rel='noreferrer'
                              href={`https://${domain.domainName}`}
                              className='truncate'
                            >
                              <div className={'flex flex-row items-center'}>
                                <span className={'mr-2'}>
                                  {domain.domainName}
                                </span>
                                <VscLinkExternal size={16} />
                              </div>
                            </a>
                          </td>

                          <td className='py-2'>
                            <StatusIcon
                              className='mx-auto'
                              status={
                                JSON.stringify(domainStatuses[i]) ==
                                JSON.stringify({ up: true })
                                  ? VERIFICATION_STATUS.COMPLETED
                                  : VERIFICATION_STATUS.FAILED
                              }
                            />
                          </td>
                          <td className='text-right py-2'>
                            {i > 0 && (
                              <>
                                <button
                                  className={
                                    'btn btn-outline-primary btn-sm mr-2'
                                  }
                                  disabled={
                                    mutationInProgress ||
                                    JSON.stringify(domainStatuses[i]) ==
                                      JSON.stringify({ up: true })
                                  }
                                  onClick={() => {
                                    handleEditDomain(domain.id)
                                  }}
                                >
                                  <AiOutlineEdit />
                                </button>
                                <button
                                  className={'btn btn-outline-primary btn-sm'}
                                  disabled={mutationInProgress}
                                  onClick={() => {
                                    handleRemoveDomain(domain.id)
                                  }}
                                >
                                  {removeLoading &&
                                  removedDomainId === (domain.id as number) ? (
                                    <div className='flex'>
                                      <div className='animate-spin'>
                                        <AiOutlineLoading3Quarters size={14} />
                                      </div>
                                    </div>
                                  ) : (
                                    <FaTrashAlt />
                                  )}
                                </button>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}

                  {!loading && project?.domains?.length === 0 && (
                    <div className='subtitle mb-4'>
                      <p className='text-xs md:text-base'>{`No records found`}</p>
                    </div>
                  )}

                  {loading && <LoadingIndicator />}
                </tbody>
              </table>
            </div>
          </div>
        </Card>

        <Card title='Custom Domain Setup'>
          <Stacked>
            <Section>
              <Stretched xgap={4} ygap={0} place={'center'}>
                <InputGroup
                  label='Domain name'
                  value={newDomainName}
                  name='name'
                  valid={!createIsError}
                  onChange={handleDomainNameChange}
                  className='grow'
                  placeholder='Enter domain name here'
                />

                <Button
                  className={'w-full md:w-fit'}
                  disabled={mutationInProgress || !newDomainName}
                  onClick={() => {
                    handleAddDomain()
                  }}
                >
                  {createLoading ? (
                    <>
                      <div className='animate-spin'>
                        <AiOutlineLoading3Quarters size={24} />
                      </div>
                      <Text>Adding...</Text>
                    </>
                  ) : (
                    <Text>Add</Text>
                  )}
                </Button>
              </Stretched>
            </Section>
          </Stacked>
        </Card>
      </Stacked>
    ),
    1: (
      <Card title='Custom Domain Setup'>
        <div className='h-full flex flex-col justify-between'>
          <InputGroup
            label='Domain name'
            value={selectedDomain?.domainName}
            name='name'
            className='grow'
            readonly
          />
          <div className={'mb-8'}>
            <div className='col-form-label'>Validation method</div>
            <RadioInput
              className='text-md py-[5px] pl-0 md:pl-[20px] cursor-pointer'
              name='validationMethod'
              checked={validationMethod === 'dns'}
              onClick={() => setValidationMethod('dns')}
            >
              <span className='font-semibold'>DNS:</span> (preferred) add a
              CNAME record to your domain's DNS records
            </RadioInput>
            <RadioInput
              className='py-[5px] pl-0 md:pl-[20px] cursor-pointer'
              name='validationMethod'
              checked={validationMethod === 'email'}
              onClick={() => setValidationMethod('email')}
            >
              <span className='font-semibold'>Email:</span> act on an email sent
              to an address associated with your domain
            </RadioInput>
          </div>
          <ButtonRow xalign={'end'} xgap={2.5} ygap={2.5}>
            <Button
              fill={'outline'}
              className={'w-full md:w-fit'}
              disabled={mutationInProgress}
              onClick={() => setStep(0)}
            >
              Cancel
            </Button>
            <Button
              className={'w-full md:w-fit'}
              disabled={mutationInProgress}
              onClick={handleCreateCertificate}
            >
              {createCertificateLoading ? (
                <div className='flex'>
                  <div className='animate-spin'>
                    <AiOutlineLoading3Quarters size={24} />
                  </div>
                  <span className='pl-2'>Continuing...</span>
                </div>
              ) : (
                <span>Continue</span>
              )}
            </Button>
          </ButtonRow>
        </div>
      </Card>
    ),
    2: (
      <Card title='Custom Domain Setup'>
        <div className='h-full flex flex-col justify-between'>
          <div className='grid gap-6 grid-cols-1 xl:grid-cols-2'>
            <InputGroup
              label='Domain name'
              value={selectedDomain?.domainName}
              name='name'
              className='grow'
              readonly
            />
            <InputGroup
              label='Validation method'
              value={selectedDomain?.validationMethod}
              name='validationMethod'
              className='grow'
              readonly
            />
          </div>
          {selectedDomain?.validationMethod === 'DNS' ? (
            <div className='mb-6'>
              <div className='col-form-label'>
                Step 1: Add first CNAME record
              </div>
              <div className='mb-2'>
                Please add the following CNAME record to your domain's DNS
                records:
              </div>
              <div className='pl-0 md:pl-5'>
                <div className='flex space-x-1'>
                  <span className='font-semibold'>Name:</span>

                  <div className={'break-anywhere'}>
                    <span>{selectedDomain?.cnameName}</span>
                  </div>

                  <div
                    className='inline high-contrast ml-1 cursor-pointer'
                    onClick={() => copyToClipboard(selectedDomain?.cnameName)}
                  >
                    <AiOutlineCopy
                      className='inline'
                      color='var(--primary)'
                      size={16}
                    />
                  </div>
                </div>
                <div className='flex space-x-1'>
                  <span className='font-semibold'>Value:</span>

                  <div className={'break-anywhere'}>
                    <span>{selectedDomain?.cnameValue}</span>
                  </div>

                  <div
                    className='inline high-contrast ml-1 cursor-pointer'
                    onClick={() => copyToClipboard(selectedDomain?.cnameValue)}
                  >
                    <AiOutlineCopy
                      className='inline'
                      color='var(--primary)'
                      size={16}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='mb-6'>
              <div className='col-form-label'>Step 1: Check email</div>
              <div className='mb-2'>
                Please follow the instructions sent to the following email
                addresses:
              </div>
              <div className='pl-5'>
                {selectedDomain?.validationEmails
                  ?.split(',')
                  .map((email: string) => <div key={email}>{email}</div>)}
              </div>
            </div>
          )}
          <div className={'mb-8'}>
            <DomainValidationStatus
              status={selectedDomain?.validationStatus}
              onRefresh={handleRefresh}
              loading={certificateLoading}
            />
          </div>
          <ButtonRow xalign={'end'} xgap={2.5} ygap={2.5}>
            <Button
              fill={'outline'}
              className={'w-full md:w-fit'}
              disabled={mutationInProgress}
              onClick={() => setStep(0)}
            >
              Cancel
            </Button>
            <Button
              className={'w-full md:w-fit'}
              disabled={
                mutationInProgress ||
                selectedDomain?.validationStatus !== 'SUCCESS'
              }
              onClick={handleCreateDistribution}
            >
              {createDistributionLoading ? (
                <div className='flex'>
                  <div className='animate-spin'>
                    <AiOutlineLoading3Quarters size={24} />
                  </div>
                  <span className='pl-2'>Continuing...</span>
                </div>
              ) : (
                <span>Continue</span>
              )}
            </Button>
          </ButtonRow>
        </div>
      </Card>
    ),
    3: (
      <Card title='Custom Domain Setup'>
        <div className='h-full flex flex-col justify-between'>
          <div className='grid gap-6 grid-cols-1 xl:grid-cols-2'>
            <InputGroup
              label='Domain name'
              value={selectedDomain?.domainName}
              name='name'
              className='grow'
              readonly
            />
            <InputGroup
              label='Validation status'
              value={selectedDomain?.validationStatus}
              name='validation status'
              className='grow'
              readonly
            />
          </div>
          <div className='mb-6'>
            <div className='col-form-label'>
              Step 2: Add
              {selectedDomain?.validationMethod === 'DNS' ? ' second ' : ' '}
              CNAME record
            </div>
            <div className='mb-2'>
              Please add the following CNAME record to your domain's DNS
              records:
            </div>
            <div className='pl-5'>
              <div className='flex space-x-1'>
                <span className='font-semibold'>Name:</span>
                <div>
                  {selectedDomain?.domainName}
                  <div
                    className='inline high-contrast ml-1 cursor-pointer'
                    onClick={() => copyToClipboard(selectedDomain?.domainName)}
                  >
                    <AiOutlineCopy
                      className='inline'
                      color='var(--primary)'
                      size={16}
                    />
                  </div>
                </div>
              </div>
              <div className='flex space-x-1'>
                <span className='font-semibold'>Value:</span>
                <div>
                  {selectedDomain?.distributionDomain}
                  <div
                    className='inline high-contrast ml-1 cursor-pointer'
                    onClick={() =>
                      copyToClipboard(selectedDomain?.distributionDomain)
                    }
                  >
                    <AiOutlineCopy
                      className='inline'
                      color='var(--primary)'
                      size={16}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mb-8'>
            <DomainStatus
              status={domainStatus}
              onRefresh={handleCheckDomainStatus}
              disabled={mutationInProgress || domainStatusLoading}
            />
          </div>
        </div>
      </Card>
    ),
    4: (
      <Card className='mb-8 h-full'>
        <div className={'h-full flex flex-col justify-between'}>
          <div className={'flex flex-col items-center'}>
            <div>
              <AnimatedCheckmark size={'xxLarge'} />
            </div>
            <div className='mt-4 title-text text-center'>
              <span className=''>Congratulations!</span>
            </div>
            <div className='mt-3 text-center'>
              <span>
                This app is now being served on your custom domain at{' '}
                {import.meta.env.PROCESS_ENV === 'development'
                  ? `http://${selectedDomain?.domainName}`
                  : `https://${selectedDomain?.domainName}`}
              </span>
            </div>
          </div>
          <ButtonRow place={'end'}>
            <Button onClick={() => setView('project-details')}>
              Project Details
            </Button>
          </ButtonRow>
        </div>
      </Card>
    ),
  }

  return content[step as keyof typeof content]
}

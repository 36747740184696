import { useEffect, useState } from 'react'

// https://usehooks-ts.com/react-hook/use-debounce
export function useDebounce<T>(
  value: T,
  delay?: number,
  isEqual?: (a: T, b: T) => boolean,
): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => {
      // If equality function is provided, use it to compare values,
      // and only update debounced value if they are not equal
      if (isEqual) {
        if (!isEqual(value, debouncedValue)) {
          setDebouncedValue(value)
        }
      } else {
        if (value !== debouncedValue) {
          setDebouncedValue(value)
        }
      }
    }, delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

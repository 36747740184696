import { VestingSchedule } from 'tokensoft-shared-types'
import { getDurationBetweenDates } from './date-time'

export const getVestingScheduleDescription = (
  schedule: VestingSchedule,
): Maybe<string> => {
  const formatDate = (date: Date) =>
    date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })

  if (schedule.type === 'continuous' && schedule.cliffDate) {
    return `Vesting starts ${formatDate(schedule.startDate)}, unlocks fully ${formatDate(schedule.endDate)} after a delay until ${formatDate(schedule.cliffDate)}.`
  } else if (schedule.type === 'tranche') {
    const steps = schedule.tranches!.length
    return `Vesting begins ${formatDate(schedule.startDate)} and completes ${formatDate(schedule.endDate)} in ${steps} steps, reaching 100%.`
  }

  return null
}

export const getVestingDuration = (schedule: VestingSchedule) => {
  return getDurationBetweenDates(schedule.startDate, schedule.endDate)
}

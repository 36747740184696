import { AnimatePresence, motion } from 'framer-motion'

interface CopiedMessageProps {
  isVisible: boolean
  text?: string
}

export const CopiedMessage = ({
  isVisible,
  text = 'Copied!',
}: CopiedMessageProps) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className='absolute top-[-40px] left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-2 py-1 rounded text-sm'
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3 }}
        >
          {text}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

import { Button } from '@components'
import { NumberInput, SelectInput } from '@newComponents'
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormTrigger,
} from 'react-hook-form'
import { SaleSubgraphPaymentMethod } from 'tokensoft-shared-types'
import { NodePurchaseFormInputs } from '../node-sale/node-purchase-flow'
import { TokenPurchaseFormInputs } from './token-purchase-flow'
import { useCallback } from 'react'
import { useFundWallet } from '@privy-io/react-auth'
import { useTSAddress, useWagmiAccount } from '@hooks'
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query'

interface TokenPurchaseFormProps {
  errors: FieldErrors<TokenPurchaseFormInputs>
  settingMaxAmount: boolean
  onMaxClick: () => void
  onMinClick: () => void
  paymentMethods: string[]
  register: UseFormRegister<TokenPurchaseFormInputs>
  trigger: UseFormTrigger<NodePurchaseFormInputs>
  control: Control<NodePurchaseFormInputs>
  selectedPaymentMethod: SaleSubgraphPaymentMethod
  validators: FormValidators<TokenPurchaseFormInputs>
}

export const TokenPurchaseForm = ({
  errors,
  settingMaxAmount,
  onMaxClick,
  onMinClick,
  paymentMethods,
  register,
  trigger,
  control,
  selectedPaymentMethod,
  validators,
}: TokenPurchaseFormProps) => {
  const queryClient = useQueryClient()

  const { fundWallet } = useFundWallet({
    onUserExited: () => {
      if (address && chain) {
        void queryClient.invalidateQueries([
          'balance',
          {
            address,
            chainId: chain.id,
          },
        ] as InvalidateQueryFilters)
      }
    },
  })

  const { address } = useTSAddress()
  const { chain } = useWagmiAccount()

  const onAddFundsClick = useCallback(() => {
    if (address) {
      void fundWallet(address, {
        chain,
        asset:
          selectedPaymentMethod.symbol === 'USDC' ? 'USDC' : 'native-currency',
      })
    }
  }, [chain, selectedPaymentMethod.symbol, fundWallet, address])

  return (
    <>
      <div>
        <SelectInput
          className='h-full my-4'
          id='paymentMethod'
          label='Payment Method'
          options={paymentMethods.map((method) => ({
            value: method,
            label: method,
          }))}
          onValueChanged={() => void trigger('paymentMethod')}
          control={control}
          rules={{
            required: 'Payment method is required',
            validate: validators.paymentMethod,
          }}
          disabled={paymentMethods.length === 1}
          error={errors.paymentMethod?.message}
        />
        <NumberInput
          data-testid='purchase-amount'
          disabled={settingMaxAmount}
          id='amount'
          label='Amount'
          placeholder={`Amount (${selectedPaymentMethod.symbol})`}
          validate={validators.amount}
          register={register}
          error={errors.amount?.message}
        />
        <div className='flex justify-between items-center gap-2'>
          <Button
            disabled={settingMaxAmount}
            size='xs'
            onClick={onAddFundsClick}
          >
            Add Funds
          </Button>

          <div className='flex justify-end items-center gap-2'>
            <Button disabled={settingMaxAmount} size='xs' onClick={onMinClick}>
              Min
            </Button>

            <Button disabled={settingMaxAmount} size='xs' onClick={onMaxClick}>
              Max
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

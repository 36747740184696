import { ChevronDownIcon } from '@components/icon'
import { useOnClickOutside } from '@hooks'
import { useRef, useState } from 'react'
import { Blockchain } from 'tokensoft-shared-types'
import { ChainsDropdown } from './chains-dropdown'
import { CurrentChainLogo } from './current-chain-logo'
import { HeaderButton } from './header-button'
import { HeaderGroup } from './header-group'
interface ChainSelectorProps {
  chains: Blockchain[]
  currentChainId: ChainId
  switchChain: (chainId: ChainId) => void
}

export const ChainSelector = ({
  chains,
  currentChainId,
  switchChain,
}: ChainSelectorProps) => {
  const [isChainDropdownOpen, setIsChainDropdownOpen] = useState(false)
  const dropdownRef = useRef(null)
  const currentChain: Maybe<Blockchain> =
    chains.find((c) => c.id === currentChainId) ?? null

  useOnClickOutside(dropdownRef, () => setIsChainDropdownOpen(false))

  return (
    <HeaderGroup ref={dropdownRef} className='relative'>
      <div className='flex items-center gap-4'>
        {currentChain && <CurrentChainLogo logoUrl={currentChain?.logoUri} />}
        <HeaderButton
          className='bg-[#eff3ff] w-9 h-9'
          onClick={() => setIsChainDropdownOpen((prev) => !prev)}
        >
          <ChevronDownIcon />
        </HeaderButton>
      </div>

      {isChainDropdownOpen && (
        <ChainsDropdown
          className='absolute right-0 mt-2'
          activeChainId={currentChain?.id ?? -1}
          chains={chains}
          onChange={(selectedChain) => {
            switchChain(selectedChain)
            setIsChainDropdownOpen(false)
          }}
        />
      )}
    </HeaderGroup>
  )
}

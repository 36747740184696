import { useAdminApiClient } from '@api/clients'
import { useToast } from '@contexts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { error } from 'console'

export type PutPrivateEventMemberArgs = {
  eventId: ID
  member: {
    email?: Maybe<EmailAddress>
    wallet?: Maybe<EvmAddress>
  }
}

export const usePutPrivateEventMember = () => {
  const fetchWithClient = useAdminApiClient()
  const queryClient = useQueryClient()
  const { showErrorToast } = useToast()

  return useMutation({
    mutationFn: async ({
      eventId,
      member,
    }: PutPrivateEventMemberArgs): Promise<boolean> => {
      try {
        await fetchWithClient(`events/${eventId}/privacy/member`, {
          method: 'PUT',
          data: {
            ...member,
          },
        })
        return true
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 422) {
          showErrorToast({
            description: error.response?.data.message,
          })

          return false
        }

        showErrorToast({
          description: `An unexpected error occured: ${error as Error}`,
        })

        return false
      }
    },
    onSuccess: (success: boolean, variables: PutPrivateEventMemberArgs) => {
      if (success) {
        void queryClient.invalidateQueries({
          queryKey: ['privateEventMembers', variables.eventId],
        })
      }
    },
  })
}

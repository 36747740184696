import { useGetDistributorParticipantsSummary } from '@apiServices'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  EditIcon,
  Row,
  Section,
  Stacked,
  Stretched,
  TableData,
  TableHeader,
  Text,
  TextLink,
} from '@components'
import { convertBaseUnitsToDecimal, formatValue, getIPFSLink } from '@utils'
import { useState } from 'react'
import { VscLinkExternal } from 'react-icons/vsc'
import { useNavigate } from 'react-router-dom'

export const DistributorParticipatesSummary = ({
  context,
  className = '',
}: any) => {
  const navigate = useNavigate()
  const { data: distributorParticipantsData } =
    useGetDistributorParticipantsSummary(context?.event.id)
  const [isOpen, setIsOpen] = useState(!Object.keys(context).includes('id'))

  const formattedTotalParticipants =
    distributorParticipantsData?.totalParticipants
      ? formatValue(distributorParticipantsData?.totalParticipants, {
          commas: true,
        })
      : '-'
  const formattedTotalAllocations = formatValue(
    convertBaseUnitsToDecimal(
      distributorParticipantsData?.totalAllocations,
      context?.tokenInfo?.decimals || 18,
      2,
    ),
    { commas: true },
  )

  const key = 'distributor-participates-summary'

  return (
    <Card className={className} id={`${key}-card`}>
      <CardHeader place={'center'}>
        <CardTitle
          open={isOpen}
          onOpen={context.id ? setIsOpen : undefined}
          data-testid={`${key}-title`}
        >
          Participants
        </CardTitle>
        {context.id ? (
          <TextLink
            data-testid='edit-event-participants'
            width={'fit'}
            onClick={() => {
              navigate(
                `/admin/project/${context.event.project.id}/event/${context.event.id}/distributor/participants`,
              )
            }}
          >
            <EditIcon />
            <Text>Edit Details</Text>
          </TextLink>
        ) : null}
      </CardHeader>

      <CardBody open={isOpen}>
        <Stacked>
          {context.id && distributorParticipantsData ? (
            <Section gap={5} place={'start'}>
              <Stretched gap={5}>
                <Stretched ygap={5}>
                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader>Total Participants</TableHeader>
                    {distributorParticipantsData?.totalParticipants ? (
                      <Row nowrap gap={2} yalign={'center'}>
                        <TableData data-testid={`${key}-participants-data`}>
                          {formattedTotalParticipants} Addresses
                        </TableData>
                        <a
                          data-testid={`${key}-participants-data-link`}
                          href={getIPFSLink(distributorParticipantsData?.uri)}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <VscLinkExternal size={14} color={'var(--primary)'} />
                        </a>
                      </Row>
                    ) : (
                      <TableData data-testid={`${key}-participants-data`}>
                        {'-'}
                      </TableData>
                    )}
                  </Col>

                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader>Total Distribution Amount</TableHeader>
                    {distributorParticipantsData?.totalAllocations ? (
                      <Row nowrap gap={2} yalign={'center'}>
                        <TableData data-testid={`${key}-amount-data`}>
                          {formattedTotalAllocations} {context.tokenInfo.symbol}
                        </TableData>
                      </Row>
                    ) : (
                      <TableData data-testid={`${key}-amount-data`}>
                        {'-'}
                      </TableData>
                    )}
                  </Col>
                </Stretched>

                <Stretched>
                  <Col gap={2.5} width={'1/2'} className={'hidden md:block'}>
                    <TableHeader>&nbsp;</TableHeader>
                    <TableData>&nbsp;</TableData>
                  </Col>

                  <Col gap={2.5} width={'1/2'} className={'hidden md:block'}>
                    <TableHeader>&nbsp;</TableHeader>
                    <TableData>&nbsp;</TableData>
                  </Col>
                </Stretched>
              </Stretched>
            </Section>
          ) : null}
        </Stacked>
      </CardBody>
    </Card>
  )
}

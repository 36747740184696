import { useGetNonce, useRefreshToken } from '@apiServices'
import { useAuth, useNetworks, WalletContext } from '@contexts'
import { useTSAddress } from '@hooks'
import { useWallets } from '@privy-io/react-auth'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useAccount as useWagmiAccount } from 'wagmi'

interface WalletProviderProps {
  children?: ReactNode
}

export const WalletProvider: FC<WalletProviderProps> = ({ children }) => {
  const { authenticate, user, disconnect } = useAuth()
  const { getNetworkDetails } = useNetworks()
  const { chain } = useWagmiAccount()
  const { address } = useTSAddress()
  const [supportedNetwork, setSupportedNetwork] = useState<boolean>(true)
  const [, setAddWalletMode] = useState<boolean>(false)
  const [, setProvideAuthId] = useState<boolean>(false)
  const { wallets } = useWallets()
  const { refreshToken } = useRefreshToken()

  const { mutate: getNonce } = useGetNonce()

  console.log(`INFO: WalletProvider runs`)

  /**
   * Handle disconnect events
   */
  const handleDisconnect = async () => {
    try {
      console.log('INFO: WalletProvider-handleDisconnect() disconnecting')
      disconnect()
    } catch (e) {
      console.log({ e })
    }
  }

  /**
   * Handle manual switch chain events
   */
  const handleChainSwitch = async (
    chainId: ChainId,
    options?: { onSuccess: () => void },
  ): Promise<void> => {
    console.log(
      'INFO: WalletProvider-handleChainSwitch() covering Privy and Wagmi flows',
    )

    const [wallet] = wallets

    if (wallet === undefined) {
      throw new Error('missing privy wallet')
    }

    await wallet.switchChain(chainId)

    console.log(
      'INFO: WalletProvider-switchChain-onSuccess() setting supported network',
    )
    const supportedNetworks = getNetworkDetails(chainId)
    setSupportedNetwork(!!supportedNetworks?.id)

    if (options?.onSuccess) {
      options.onSuccess()
    }
  }

  /**
   * Handle manual connect events
   */
  const handleConnect = async (transferWallet?: boolean) => {
    console.log(
      'INFO: WalletProvider-handleConnect() manual connect',
      transferWallet,
    )
    if (transferWallet) {
      setProvideAuthId(true)
    }

    if (address) {
      return getNonce(address)
    }
  }

  const handleEnableAddWalletMode = () => {
    console.log('INFO: WalletProvider-handleEnableAddWalletMode()')
    setAddWalletMode(true)
  }

  const handleDisableAddWalletMode = () => {
    console.log('INFO: WalletProvider-handleDisableAddWalletMode() called')
    setAddWalletMode(false)
  }

  useEffect(() => {
    void refreshToken().then((result) => {
      authenticate({
        ...user,
        token: result.token,
        walletAddresses: result.walletAddresses,
      })
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  return (
    <>
      <WalletContext.Provider
        value={{
          connectedChainId: chain?.id,
          supportedNetwork: supportedNetwork,
          account: address,
          network: chain,
          connectWallet: handleConnect,
          disconnectWallet: handleDisconnect,
          switchChain: handleChainSwitch,
          enableAddWalletMode: handleEnableAddWalletMode,
          disableAddWalletMode: handleDisableAddWalletMode,
        }}
      >
        {children}
      </WalletContext.Provider>
    </>
  )
}

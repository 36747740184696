import { IconProps } from './icon-props'

export const ChevronUpIcon = ({
  color,
  width = 20,
  height = 16,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={color ?? 'none'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.99001 5.3355C10.1747 5.3351 10.3536 5.3971 10.4958 5.5106L15.2375 9.31816C15.3989 9.44742 15.5004 9.63316 15.5196 9.83453C15.5389 10.0359 15.4744 10.2364 15.3402 10.3919C15.2061 10.5474 15.0133 10.6452 14.8043 10.6638C14.5954 10.6823 14.3873 10.6202 14.2259 10.4909L9.99001 7.07932L5.75409 10.3691C5.67322 10.4323 5.58022 10.4795 5.48038 10.508C5.38054 10.5365 5.27584 10.5458 5.17227 10.5351C5.0687 10.5245 4.96843 10.4943 4.87702 10.4462C4.78561 10.3981 4.70502 10.3331 4.63979 10.2548C4.56742 10.1765 4.51263 10.0847 4.47883 9.985C4.44503 9.88534 4.4329 9.78002 4.44323 9.67565C4.45357 9.57127 4.48626 9.47009 4.53913 9.37844C4.592 9.28678 4.6639 9.20664 4.75043 9.14302L9.49213 5.4649C9.63841 5.3693 9.81367 5.3238 9.99001 5.3355Z'
        fill={color ?? 'black'}
        stroke={color ?? 'black'}
      />
    </svg>
  )
}

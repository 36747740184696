import { Row } from '@components'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import './button.css'

type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>

type ButtonProps = HTMLButtonProps & {
  color?: 'primary' | 'secondary'
  fill?: 'outline' | 'solid'
  size?: 'xs' | 'sm' | 'md' | 'lg'
  disabled?: boolean
  readonly?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className = '',
      onClick = () => {},
      color = 'primary',
      fill = null,
      size = null,
      disabled = false,
      readonly = false,
      children,
      ...restProps
    },
    ref,
  ) => {
    return (
      <button
        ref={ref} // Forward the ref to the button element
        disabled={disabled}
        onClick={(e) => {
          if (!readonly) {
            onClick(e)
          }
        }}
        className={classNames(
          'btn truncate',
          !fill ? `btn-${color}` : '',
          fill ? `btn-${fill}-${color}` : '',
          size ? `btn-${size}` : '',
          readonly ? 'readonly' : '',
          className,
        )}
        {...restProps}
      >
        <Row nowrap gap={2.5} place={'center'}>
          {children}
        </Row>
      </button>
    )
  },
)

// Optional: Set a display name for better debugging
Button.displayName = 'Button'

import BigNumber from 'bignumber.js'
import { roundInDigitRange } from 'tokensoft-shared-types'

export const MAX_AMOUNT_DECIMALS = 8

/**
 *
 * @param userBalances The user's wallet balances for each payment method
 * @param selectedPaymentMethodSymbol The currently selected payment method symbol
 * @param saleMinimumPurchaseInUsd The minimum purchase amount for the sale
 * @param saleMaximumPurchaseInUsd The maximum purchase amount for the sale
 * @param userWalletPurchaseTotal The amount the user has already purchased on the sale
 * @param convertCurrency A function to convert a currency value to another symbol
 * @returns A validator function for the amount input, which accepts an amount and
 * returns an error message or `true` for valid
 */
export const createAmountValidator = (
  userBalances: CurrencyValue[],
  selectedPaymentMethodSymbol: TokenSymbol,
  saleMinimumPurchaseInUsd: CurrencyValue,
  saleMaximumPurchaseInUsd: CurrencyValue,
  userWalletPurchaseTotal: CurrencyValue,
  convertCurrency: (
    value: CurrencyValue,
    toSymbol: TokenSymbol,
  ) => Maybe<CurrencyValue>,
) => {
  return (value: string | undefined): string | true => {
    // A note on rounding in this method - the approach is to handle rounding by marginally
    // *narrowing* the purchasing limits. Ultimately, the sale contract enforces the limits,
    // so we want to ensure that the user is not able to submit a purchase that will be
    // rejected by the contract due to rounding errors. This means that, when validating that
    // the user's purchase amount is within purchasing limits (eg the min/max purchase, the
    // user's max purchase amount based on purchase history, the sale cap), we will round
    // *down* the maximum purchase amount when and round up the minimum purchase amount.
    //
    // Why round at all? Why not use exact amounts? The currencies will vary when comparing,
    // for example the sale purchase limits are measured in USD, but users may be typing in
    // ETH values, which can result in long decimals after conversion, or slightly inaccurate
    // values, depending on the exchange rate. Rounding in the proper direction decreases the
    // likelihood that the user submits a purchase that will be rejected by the contract due
    // to rounding errors or slight differences in oracle prices.
    //
    // For example, a $10 min purchase amount, when converted to ETH, could be `0.00301733132574647237`.
    // We don't generally want to show that many decimals to the user, so we round up to `0.00302`,
    // which marginally narrows the range of valid purchase amounts.

    // Validate the basic amount
    const parsedAmount = new BigNumber(value || 0)
    if (parsedAmount.isNaN() || !parsedAmount.gt(0)) {
      return 'Invalid amount.'
    }

    // Validate above minimum purchase
    if (selectedPaymentMethodSymbol) {
      const minPurchase = convertCurrency(
        saleMinimumPurchaseInUsd,
        selectedPaymentMethodSymbol,
      )

      if (!minPurchase) {
        return 'Invalid minimum purchase conversion.'
      }

      const roundedMinPurchase = minPurchase.value.decimalPlaces(
        MAX_AMOUNT_DECIMALS,
        BigNumber.ROUND_UP,
      )

      if (parsedAmount.lt(roundedMinPurchase)) {
        // Try to make rounding look reasonable; eg:
        // 0.03321531 -> 0.04
        // 0.0000641 -> 0.00007
        return `Amount is less than the minimum purchase of ${roundInDigitRange(
          minPurchase.value,
          2,
          MAX_AMOUNT_DECIMALS,
          BigNumber.ROUND_UP,
        ).toString()} ${selectedPaymentMethodSymbol}.`
      }
    }

    // If amount exceeds sale maximum purchase - total user purchase, return error
    const totalRemainingPurchase = saleMaximumPurchaseInUsd.value.minus(
      userWalletPurchaseTotal.value,
    )

    if (parsedAmount.gt(totalRemainingPurchase)) {
      return `Amount exceeds your remaining purchase limit of ${totalRemainingPurchase.decimalPlaces(MAX_AMOUNT_DECIMALS, BigNumber.ROUND_DOWN).toString()} ${userWalletPurchaseTotal.symbol}.`
    }

    // Validate below maximum purchase
    if (selectedPaymentMethodSymbol) {
      const maxPurchase = convertCurrency(
        saleMaximumPurchaseInUsd,
        selectedPaymentMethodSymbol,
      )

      if (!maxPurchase) {
        return 'Invalid maximum purchase conversion.'
      }

      if (!parsedAmount.lte(maxPurchase.value)) {
        return `Amount is greater than the maximum purchase of ${maxPurchase.value.decimalPlaces(MAX_AMOUNT_DECIMALS, BigNumber.ROUND_DOWN).toString()} ${selectedPaymentMethodSymbol}.`
      }
    }

    // Validate sufficient balance
    if (userBalances && selectedPaymentMethodSymbol) {
      const balance = userBalances.find(
        (b) => b.symbol === selectedPaymentMethodSymbol,
      )
      if (!balance || parsedAmount.gt(balance.value)) {
        return 'Insufficient wallet balance.'
      }
    }

    return true
  }
}

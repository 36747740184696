import { EventPropertyRow, FormattedCurrency } from '@newComponents'

type Props = {
  assetType: SaleAssetType
  assetPrice: CurrencyValue
}

const SaleAssetPrice = ({ assetType, assetPrice }: Props) => {
  const getLabel = () => {
    let label: string = 'Price per '

    switch (assetType) {
      case 'node':
        label += 'Node License'
        break
      case 'token':
        label += 'Token'
        break
    }

    return label
  }

  return (
    <EventPropertyRow
      label={getLabel()}
      value={
        <FormattedCurrency
          currencyValue={assetPrice}
          displayDecimals={4}
          trimTrailingZeros
        />
      }
    />
  )
}

export default SaleAssetPrice

import { Text } from '@components'
import { twMerge } from 'tailwind-merge'
import { FormattedNumber } from './formatted-number'

type FormattedCurrencyValueProps = {
  currencyValue: CurrencyValue
  displayDecimals?: number
  displayCommas?: boolean
  trimTrailingZeros?: boolean
  className?: string
}

export const FormattedCurrency = ({
  currencyValue,
  displayDecimals = 6,
  displayCommas = true,
  trimTrailingZeros = true,
  className,
}: FormattedCurrencyValueProps) => {
  return (
    <Text className={twMerge('inline', className)}>
      {currencyValue.symbol === 'USD' ? '$' : ''}
      <FormattedNumber
        value={currencyValue.value}
        displayDecimals={displayDecimals}
        withCommas={displayCommas}
        trimTrailingZeros={trimTrailingZeros}
      />{' '}
      {`${currencyValue.symbol}`}
    </Text>
  )
}

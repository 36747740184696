import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

const SEO_CONFIG: Record<
  string,
  {
    title: string
    description: string
    ogTitle: string
    ogDescription: string
    ogImage: string
  }
> = {
  '/': {
    title: 'Shardeum Token Sale is Live – Get Early Access to SHM!',
    description:
      'The Shardeum Token Sale is now live! Get early access to SHM before public trading, with no lock-ups and exclusive pricing for early participants.',
    ogTitle:
      'Shardeum Token Sale is Live–Get Early Access to SHM at $0.66–$0.90!',
    ogDescription:
      'Get early access to SHM before public trading, with no lock-ups and exclusive pricing ranging from $0.66 to $0.90 per SHM',
    ogImage:
      'https://shardeum.nyc3.cdn.digitaloceanspaces.com/shardeum/2025/03/Shardeum-TOken-sale1.jpeg',
  },
  '/event/3261': {
    title: 'Shardeum Token Sale is Live –  Buy SHM at $0.71',
    description:
      'Early contributors can buy SHM at an exclusive price of $0.71 per token with a $500 minimum purchase. Secure SHM at the lowest pricing before public trading begins!',
    ogTitle: 'Shardeum Token Sale is Live –  Buy SHM at $0.71',
    ogDescription:
      'Early contributors can buy SHM at an exclusive price of $0.71 per token with a $500 minimum purchase. Secure SHM at the lowest pricing before public trading begins!',
    ogImage:
      'https://shardeum.nyc3.cdn.digitaloceanspaces.com/shardeum/2025/03/Token-sale-EC1.png',
  },
  '/event/3262': {
    title: 'Shardeum Token Sale is Live –  Buy SHM at $0.66',
    description:
      'Get SHM at the lowest price of $0.66 per token with a $2,000+ purchase. This exclusive early contributors tier offers the best rates before public trading starts!',
    ogTitle: 'Shardeum Token Sale is Live –  Buy SHM at $0.66',
    ogDescription:
      'Get SHM at the lowest price of $0.66 per token with a $2,000+ purchase. This exclusive early contributors tier offers the best rates before public trading starts!',
    ogImage:
      'https://shardeum.nyc3.cdn.digitaloceanspaces.com/shardeum/2025/03/Token-sale-EC2.png',
  },
  '/event/3259': {
    title: 'Shardeum Token Sale is Live –  Buy SHM at $0.90',
    description:
      'With a $500 purchase, public participants can buy SHM at $0.90 per token—low pricing compared to private rounds. Join now before public trading begins!',
    ogTitle: 'Shardeum Token Sale is Live –  Buy SHM at $0.90',
    ogDescription:
      'With a $500 purchase, public participants can buy SHM at $0.90 per token—low pricing compared to private rounds. Join now before public trading begins!',
    ogImage:
      'https://shardeum.nyc3.cdn.digitaloceanspaces.com/shardeum/2025/03/Token-sale-Public1.png',
  },
  '/event/3260': {
    title: 'Shardeum Token Sale is Live –  Buy SHM at $0.83',
    description:
      'Secure SHM at $0.83 per token with a $2,000+ purchase—low pricing compared to private rounds. Join now before public trading begins!',
    ogTitle: 'Shardeum Token Sale is Live –  Buy SHM at $0.83',
    ogDescription:
      'Secure SHM at $0.83 per token with a $2,000+ purchase—low pricing compared to private rounds. Join now before public trading begins!',
    ogImage:
      'https://shardeum.nyc3.cdn.digitaloceanspaces.com/shardeum/2025/03/Token-sale-Public2.png',
  },
}

export const ShardeumSEO = () => {
  const { pathname } = window.location
  const location = useLocation()

  const seo = SEO_CONFIG[location.pathname] ?? SEO_CONFIG['/']

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name='description' content={seo.description} />

      <meta
        property='og:url'
        content={`https://shardeum.tokensoft.io${pathname}`}
      />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={seo.ogTitle} />
      <meta property='og:description' content={seo.ogDescription} />
      <meta property='og:image' content={seo.ogImage} />

      <meta name='twitter:card' content='summary_large_image' />
      <meta property='twitter:domain' content='shardeum.tokensoft.io' />
      <meta
        property='twitter:url'
        content={`https://shardeum.tokensoft.io${pathname}`}
      />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:image' content={seo.ogImage} />
    </Helmet>
  )
}

import { FC, ReactNode, useState } from 'react'
import { LoginOptionsContext } from './login-options-context'

interface LoginOptionsProviderProps {
  children?: ReactNode
}

export const LoginOptionsProvider: FC<LoginOptionsProviderProps> = ({
  children,
}) => {
  const [isEmailLoginEnabled, setIsEmailLoginEnabled] = useState(true)
  const [isWalletLoginEnabled, setIsWalletLoginEnabled] = useState(true)

  return (
    <LoginOptionsContext.Provider
      value={{
        isEmailLoginEnabled,
        setIsEmailLoginEnabled,
        isWalletLoginEnabled,
        setIsWalletLoginEnabled,
      }}
    >
      {children}
    </LoginOptionsContext.Provider>
  )
}

import { ChevronDownIcon } from '@components/icon'
import { useOnClickOutside } from '@hooks'
import { useCallback, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { usePrivy } from '@privy-io/react-auth'

export const AccountMenu = () => {
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false)
  const dropdownRef = useRef(null)
  const { exportWallet } = usePrivy()

  useOnClickOutside(dropdownRef, () => setIsAccountMenuOpen(false))

  const handleExportClick = useCallback(() => {
    void exportWallet()
  }, [exportWallet])

  return (
    <div ref={dropdownRef} className='relative'>
      <div className='flex items-center gap-4'>
        <button
          className='rounded-full h-8 bg-[#eff3ff] flex items-center justify-center p-2'
          onClick={() => setIsAccountMenuOpen((prev) => !prev)}
        >
          <ChevronDownIcon />
        </button>
      </div>

      {isAccountMenuOpen && (
        <ol
          className={twMerge(
            `absolute top-full -left-12 sm:right-0 sm:left-auto py-2 flex flex-col 
          gap-1 items-start max-w-[400px] w-[280px] rounded-md shadow-lg bg-white 
          ring-1 ring-black ring-opacity-5 max-h-[calc(100vh_-_65px)] overflow-y-auto`,
            'absolute right-0 mt-2',
          )}
        >
          <button
            className='gap-[10px] w-full flex justify-start items-center px-4 h-[36px] hover:bg-[#eff3ff] transition-colors'
            onClick={handleExportClick}
          >
            <span className='text-nowrap'>Export Wallet</span>
          </button>
        </ol>
      )}
    </div>
  )
}

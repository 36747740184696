import { useGetSalesPaymentsMethodBalance } from '@apiServices'
import {
  Card,
  Col,
  Divider,
  LoadingIndicator,
  PaymentMethodBalance,
  ProgressBar,
  Row,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  TableData,
  TableHeader,
} from '@components'
import { DEFAULT_BASE_CURRENCY } from '@constants'
import { useModal, useNetworks } from '@contexts'
import { ProgressValue } from '@customTypes'
import {
  convertBaseUnitsToDecimal,
  div,
  formatValue,
  gt,
  isContinuousVestingType,
  mult,
  sub,
} from '@utils'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const SaleProgress = ({
  context,
  className,
}: {
  context: any
  className?: string
}) => {
  const navigate = useNavigate()
  const { showModal, closeModal } = useModal()
  const { getNetworkDetails } = useNetworks()
  const { symbol: nativeSymbol, name: networkName } = getNetworkDetails(
    context?.networkId,
  )
  const { mutate: getSalesPaymentsMethodBalance, isPending } =
    useGetSalesPaymentsMethodBalance()
  const [paymentMethods, setPaymentMethods] = useState<Maybe<any>>(null)

  const key = 'event-progress'

  useEffect(() => {
    if (context?.paymentMethods && context?.id) {
      if (context.paymentMethods.length !== 0) {
        context.paymentMethods.forEach((method: any) => {
          if (method.native) {
            method.symbol = nativeSymbol
          }
        })
        getSalesPaymentsMethodBalance(
          {
            networkId: context.networkId,
            paymentMethod: context.paymentMethods,
            saleId: context.id,
            recipient: context.recipient,
          },
          {
            onSuccess: (result) => {
              setPaymentMethods(result.paymentMethods)
            },
            onError: (error) => {
              console.log('error', error)
              setPaymentMethods(null)
            },
          },
        )
      } else {
        setPaymentMethods([])
      }
    }
  }, [context])

  const isNativeBaseCurrency =
    context?.nativeBaseCurrency?.symbol !== DEFAULT_BASE_CURRENCY
  const baseCurrencySymbol = isNativeBaseCurrency
    ? context?.nativeBaseCurrency?.symbol
    : '$'
  const baseCurrencyDecimal = context?.nativeBaseCurrency?.decimals

  const totalRised = mult(
    convertBaseUnitsToDecimal(context.priceBaseUnits, baseCurrencyDecimal, 4),
    div(context.purchaseTotal, context.price),
  )

  const claimableFixedNumber = isContinuousVestingType(context.interfaces)
    ? 4
    : 2

  const calculatePercentageFormated = (
    value: any,
    total: any,
    decimals = 6,
    commas = false,
  ) => {
    const percent = div(mult(value, 100), total)

    //check if percentage is integer that way we dont show 0,00%
    if (gt(sub(percent, parseInt(percent)), 0)) {
      return formatValue(percent, { commas, decimalPlaces: decimals })
    }
    return formatValue(percent, { commas, decimalPlaces: decimals })
  }

  const getPercentagePurchased = () => {
    return calculatePercentageFormated(
      context.purchaseTotal,
      context.saleMaximum,
      2,
      false,
    )
  }

  const formatCurrencyDisplay = (value: any) => {
    if (isNativeBaseCurrency) {
      return `${value} ${baseCurrencySymbol}`
    }
    return `${baseCurrencySymbol}${value}`
  }

  const calcTotalRaised = () => {
    return `${formatCurrencyDisplay(
      formatValue(totalRised, {
        commas: false,
        decimalPlaces: claimableFixedNumber,
      }),
    )}`
  }

  const calcMethodBalance = (paymentMethod: any) => {
    return `${paymentMethod.balance} ${
      !paymentMethod.native ? paymentMethod.symbol : nativeSymbol
    }`
  }

  const getTotalAmount = () => {
    return `${formatValue(
      convertBaseUnitsToDecimal(context.saleMaximum, baseCurrencyDecimal),
      { commas: true, decimalPlaces: claimableFixedNumber },
    )}`
  }

  const progress: (ProgressValue & { label: string; amount: string })[] = [
    {
      value: parseFloat(getPercentagePurchased()),
      color: 'var(--primary)',
      label: 'Purchased',
      amount: formatValue(getPercentagePurchased(), {
        commas: false,
        decimalPlaces: claimableFixedNumber,
      }),
    },
  ]

  const renderSaleDetails = () => {
    return (
      <>
        {context?.id ? (
          <>
            <ProgressBar progress={progress} />
            <Stretched className={'pt-3'}>
              <Col gap={2} className='grow' width={'1/2'}>
                <Row yalign={'center'} gap={2}>
                  <TableHeader data-testid={`${key}-total-header`}>
                    Total Raised
                  </TableHeader>
                </Row>
                <TableData
                  className={'text-3xl text-black font-bold'}
                  data-testid={`${key} -total - data`}
                >
                  {calcTotalRaised()}
                </TableData>
              </Col>
              <Col gap={2} width={'1/4'}>
                <Row yalign={'center'} gap={2}>
                  <div
                    className='w-3 h-3 rounded-full'
                    style={{ backgroundColor: 'var(--neutral-light)' }}
                  ></div>
                  <TableHeader data-testid={`${key}-total-header`}>
                    Total Amount
                  </TableHeader>
                </Row>
                <TableData data-testid={`${key}-total-data`}>
                  $ {getTotalAmount()}
                </TableData>
              </Col>

              <Col gap={2} width={'1/4'}>
                <Row yalign={'center'} gap={2}>
                  <div
                    className='w-3 h-3 rounded-full'
                    style={{ backgroundColor: 'var(--primary)' }}
                  ></div>
                  <TableHeader data-testid={`${key}-progress-purchased-header`}>
                    Purchased
                  </TableHeader>
                </Row>
                <TableData data-testid={`${key}-progress-purchased-data`}>
                  <span className='font-bold high-contrast'>
                    {getPercentagePurchased()}%
                  </span>{' '}
                  |{' '}
                  {formatValue(div(context.purchaseTotal, context.price), {
                    commas: true,
                    decimalPlaces: claimableFixedNumber,
                  })}{' '}
                  {context.tokenSymbol}
                </TableData>
              </Col>
            </Stretched>
          </>
        ) : null}
      </>
    )
  }

  const renderPaymentMethods = () => {
    if (!paymentMethods) {
      return <LoadingIndicator text={'Loading'} />
    }

    return (
      <>
        <Col className='pt-3'>
          <Divider />
        </Col>
        <Stretched gap={5}>
          {paymentMethods.length === 0 ? (
            <Row className='text-center text-neutral-medium h-20 justify-center items-center'>
              No payment methods found
            </Row>
          ) : (
            paymentMethods
              .sort((x: any, y: any) =>
                x.native === y.native ? 0 : x.native ? -1 : 1,
              )
              .map((paymentMethod: any, index: any) => {
                return (
                  <PaymentMethodBalance
                    paymentMethod={paymentMethod}
                    context={context}
                    key={index}
                  />
                )
              })
          )}
        </Stretched>
      </>
    )
  }

  return (
    <>
      <Card data-testid={`${key} `} className={className}>
        <Stacked>
          <Section gap={2.5} place={'start'}>
            <SectionTitle data-testid={`${key}-title`}>
              Sale Progress
            </SectionTitle>
            {renderSaleDetails()}

            {renderPaymentMethods()}
          </Section>
        </Stacked>
      </Card>
    </>
  )
}

import { useCurrencyRegistry, usePurchaseInputs } from '@contexts'
import BigNumber from 'bignumber.js'

export const useCalculateNodeSalePurchaseFees = (
  numberOfNodes: number,
  pricePerNodeInUsd: CurrencyValue,
  paymentMethod: TokenSymbol,
): PurchaseFees => {
  const { sale, protocolFeeInBasisPoints } = usePurchaseInputs()
  const { platformFeeInUSD } = sale
  const { convert } = useCurrencyRegistry()

  const userEnteredAmountInUsd = {
    value: pricePerNodeInUsd.value.times(numberOfNodes),
    symbol: pricePerNodeInUsd.symbol,
  }

  const userEnteredAmountInPaymentMethodCurrency = convert(
    userEnteredAmountInUsd,
    paymentMethod,
  )

  const oneDollar = {
    value: new BigNumber(1),
    symbol: 'USD',
  }

  const platformFeeInETH = convert(platformFeeInUSD ?? oneDollar, 'ETH')
  const keepDecimals = 5

  const protocolFeeAsDecimal = new BigNumber(protocolFeeInBasisPoints)
    .div(10000)
    .decimalPlaces(keepDecimals)

  const protocolFeeTotalInPaymentMethodCurrency =
    userEnteredAmountInPaymentMethodCurrency.value
      .multipliedBy(protocolFeeAsDecimal)
      .decimalPlaces(keepDecimals)

  const protocolFeeInPaymentMethodCurrency: CurrencyValue = {
    value: protocolFeeTotalInPaymentMethodCurrency,
    symbol: userEnteredAmountInPaymentMethodCurrency.symbol,
  }

  const contributionAmountInPaymentMethodCurrency: CurrencyValue = {
    symbol: userEnteredAmountInPaymentMethodCurrency.symbol,
    value: userEnteredAmountInPaymentMethodCurrency.value.minus(
      protocolFeeTotalInPaymentMethodCurrency,
    ),
  }

  const totalCostInPaymentMethodCurrency: CurrencyValue = {
    value: contributionAmountInPaymentMethodCurrency.value
      .plus(protocolFeeInPaymentMethodCurrency.value)
      .plus(
        userEnteredAmountInPaymentMethodCurrency.symbol ===
          platformFeeInETH.symbol
          ? platformFeeInETH.value
          : new BigNumber(0),
      ),
    symbol: userEnteredAmountInPaymentMethodCurrency.symbol,
  }

  const totalCostInNativeCurrency: CurrencyValue = {
    value:
      totalCostInPaymentMethodCurrency.symbol === platformFeeInETH.symbol
        ? totalCostInPaymentMethodCurrency.value
        : platformFeeInETH.value,
    symbol: platformFeeInETH.symbol,
  }

  return {
    protocolFeeInPaymentMethodCurrency,
    platformFeeInNativeCurrency: platformFeeInETH,
    contributionAmountInPaymentMethodCurrency,
    totalCostInNativeCurrency,
    totalCostInPaymentMethodCurrency,
  }
}

import { twMerge } from 'tailwind-merge'

interface EventPropertyRowProps {
  testId?: string
  className?: string
  label: string
  value: React.ReactNode
  separator?: string
}

export const EventPropertyRow = ({
  testId,
  className = '',
  label,
  separator,
  value,
}: EventPropertyRowProps) => (
  <div
    data-testid={testId}
    className={twMerge(
      `flex justify-between items-center flex-wrap gap-x-4 gap-y-2`,
      className,
    )}
  >
    <p>{label}</p>
    {separator && <span>{separator}</span>}
    {['string', 'number'].indexOf(typeof value) !== -1 ? (
      <p className='font-semibold'>{value}</p>
    ) : (
      <span className='font-semibold'>{value}</span>
    )}
  </div>
)

import { type User } from '@customTypes'
import { createContext, useContext } from 'react'

export interface AuthContextProps {
  user: User
  authenticate: (user: User) => void
  removeWalletAddress: (walletAddress: EvmAddress) => void
  disconnect: (redirectTo?: string) => void
  isAuthenticated: boolean
}

export const AuthContext = createContext<Maybe<AuthContextProps>>(null)
AuthContext.displayName = 'AuthContext'

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error(
      'AuthProvider context is undefined, please verify you are calling useAuth() as child of a <AuthProvider> component.',
    )
  }

  return context
}

import { useAdminApiClient } from '@apiClients'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useRemoveProjectDomain = () => {
  const client = useAdminApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: { projectDomainId: ID }) => {
      return client(`domains/${data.projectDomainId}`, {
        method: 'delete',
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

import { useToast } from '@contexts'
import { useBuyWithNative, useTSAddress } from '@hooks'
import { LoaderButton, StatusBox } from '@newComponents'
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { Blockchain, SaleSubgraphPaymentMethod } from 'tokensoft-shared-types'
import { BaseError, TransactionReceipt } from 'viem'
import { PurchaseAmountMode } from '../../../../../../../types/src/types/util/PurchaseAmountMode'

interface NativePurchaseButtonProps {
  saleAddress: EvmAddress
  chain: Blockchain
  purchaseAmountInNative: CurrencyValue
  paymentMethod: SaleSubgraphPaymentMethod
  platformFeeRecipient: Maybe<EvmAddress>
  platformFeeInDollars: CurrencyValue
  platformFeeInNative: CurrencyValue
  onSuccess: (receipt: TransactionReceipt) => void
  disabled: boolean
  className?: string
  purchaseAmountMode: PurchaseAmountMode
}

export const NativePurchaseButton = ({
  saleAddress,
  chain,
  purchaseAmountInNative,
  paymentMethod,
  platformFeeRecipient,
  platformFeeInDollars,
  platformFeeInNative,
  onSuccess,
  disabled,
  className = '',
  purchaseAmountMode,
}: NativePurchaseButtonProps) => {
  const queryClient = useQueryClient()
  const { showErrorToast } = useToast()
  const { address } = useTSAddress()

  const { buyWithNative, receipt, isPending, error } = useBuyWithNative(
    chain.id,
    saleAddress,
  )

  const handleBuy = async () => {
    const purchaseAmountInWei = purchaseAmountInNative.value
      .dividedBy(
        new BigNumber(
          purchaseAmountMode === 'user-enters-contribution-amount' ? 0.99 : 1,
        ),
      )
      .times(10 ** paymentMethod.decimals)

    const platformFeeInWei = platformFeeInNative.value
      .times(10 ** paymentMethod.decimals)
      .decimalPlaces(0)

    await buyWithNative(
      purchaseAmountInWei,
      platformFeeRecipient,
      platformFeeInDollars,
      platformFeeInWei,
    )
  }

  useEffect(() => {
    if (error) {
      console.log('Error: ', error)
      showErrorToast({
        description:
          error instanceof BaseError ? error.shortMessage : error.message,
      })
    }
  }, [error])

  useEffect(() => {
    if (receipt) {
      onSuccess(receipt)

      void queryClient.invalidateQueries([
        'balance',
        {
          address,
          chainId: chain.id,
        },
      ] as InvalidateQueryFilters)
    }
  }, [receipt, queryClient, address, chain, onSuccess])

  return (
    <>
      <LoaderButton
        className={className}
        onClick={handleBuy}
        disabled={disabled}
        isLoading={isPending}
        loadingText='Purchasing...'
        text='Purchase'
      >
        Purchase
      </LoaderButton>
      {window.location.hostname === 'tanssi-network.tokensoft.io' && (
        <StatusBox
          status='info'
          text='You will receive a confirmation email within 12 hours of your purchase. If you don’t receive it, please open a ticket in Tanssi’s Discord.'
        />
      )}
    </>
  )
}

import { useToast } from '@contexts'
import { getAggregatorV3ABI } from '@utils'
import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { Abi } from 'viem'
import { useReadContracts } from 'wagmi'

export const useGetPaymentMethodOraclePrices = (
  chainId: ChainId | undefined,
  oracles: OracleConfig[],
): Maybe<CurrencyValue[]> => {
  const { showErrorToast } = useToast()

  // Prepare contracts array for useReadContracts
  const contracts = oracles.flatMap(({ oracleAddress }) => [
    {
      address: oracleAddress,
      abi: getAggregatorV3ABI() as Abi,
      functionName: 'latestRoundData',
      chainId,
    },
    {
      address: oracleAddress,
      abi: getAggregatorV3ABI() as Abi,
      functionName: 'decimals',
      chainId,
    },
  ])

  const { data, isLoading, error } = useReadContracts({
    contracts,
    query: {
      enabled: chainId !== undefined && oracles.length > 0,
    },
  })

  console.log({ data, error })

  let prices: Maybe<CurrencyValue[]> = []
  let contractError: Error | undefined

  // Parse results dynamically
  if (data) {
    for (let i = 0; i < oracles.length; i++) {
      const latestRoundDataIndex = i * 2
      const decimalsIndex = i * 2 + 1

      let latestRoundData: Maybe<bigint> = null
      let decimals: Maybe<number> = null

      if (data[latestRoundDataIndex]?.status === 'success') {
        ;[, latestRoundData] = data[latestRoundDataIndex].result as [
          unknown,
          bigint,
        ]
      }

      if (data[decimalsIndex]?.status === 'success') {
        decimals = data[decimalsIndex].result as number
      }

      contractError =
        error || data[latestRoundDataIndex].error || data[decimalsIndex].error

      if (contractError) {
        prices = null
        break
      }

      if (!isLoading && latestRoundData !== null && decimals !== null) {
        // Get bignumber value

        const priceRaw = new BigNumber(latestRoundData.toString())
        const price = priceRaw.div(10 ** decimals)

        prices.push({
          value: price,
          symbol: oracles[i].symbol,
        })
      } else {
        prices = null
        break
      }
    }
  }

  useEffect(() => {
    if (contractError) {
      console.error(contractError)

      showErrorToast({ description: contractError.message })
    }
  }, [contractError, showErrorToast])

  return (prices ?? []).length > 0 ? prices : null
}

import { DependencyList, useEffect, useRef } from 'react'

/**
 * Custom hook for throttling a function call.
 * @param callback - The function to execute after the throttle delay.
 * @param delay - The throttle delay in milliseconds.
 * @param dependencies - Dependencies that trigger the throttled function.
 */
export const useThrottle = (
  callback: () => void | Promise<void>,
  delay: number,
  dependencies: DependencyList,
) => {
  const timeoutRef = useRef<Maybe<NodeJS.Timeout>>(null)

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      void callback()
    }, delay)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [...dependencies, callback, delay])
}

import { useCurrencyRegistry, usePurchaseInputs } from '@contexts'
import BigNumber from 'bignumber.js'
import { PurchaseAmountMode } from '../../../../types/src/types/util/PurchaseAmountMode'
import { usePurchaseAmountMode } from '@hooks/use-purchase-amount-mode'

export const useCalculateTokenSalePurchaseFees = (
  userEnteredAmountInPaymentMethodCurrency: CurrencyValue,
  eventId: number,
): PurchaseFees => {
  const { sale, protocolFeeInBasisPoints } = usePurchaseInputs()
  const { platformFeeInUSD } = sale
  const { convert } = useCurrencyRegistry()
  const purchaseAmountMode = usePurchaseAmountMode(eventId)

  const oneDollar = {
    value: new BigNumber(1),
    symbol: 'USD',
  }

  // TODO: pull native currency from network dynamically instead of hard-coding ETH here
  const platformFeeInETH = convert(platformFeeInUSD ?? oneDollar, 'ETH')
  const keepDecimals = 5

  const protocolFeeAsDecimal = new BigNumber(protocolFeeInBasisPoints)
    .div(10000)
    .decimalPlaces(keepDecimals)

  const contributionAmountToProtocolFeeRatio = protocolFeeAsDecimal.div(
    new BigNumber(1).minus(protocolFeeAsDecimal),
  )

  const scaleByInputMode: Record<PurchaseAmountMode, BigNumber> = {
    'user-enters-total-cost': protocolFeeAsDecimal,
    'user-enters-contribution-amount': contributionAmountToProtocolFeeRatio,
  }

  const protocolFeeTotalInPaymentMethodCurrency =
    userEnteredAmountInPaymentMethodCurrency.value
      .multipliedBy(scaleByInputMode[purchaseAmountMode])
      .decimalPlaces(keepDecimals)

  const protocolFeeInPaymentMethodCurrency: CurrencyValue = {
    value: protocolFeeTotalInPaymentMethodCurrency,
    symbol: userEnteredAmountInPaymentMethodCurrency.symbol,
  }

  const contributionAmountByInputMode: Record<
    PurchaseAmountMode,
    CurrencyValue
  > = {
    'user-enters-total-cost': {
      symbol: userEnteredAmountInPaymentMethodCurrency.symbol,
      value: userEnteredAmountInPaymentMethodCurrency.value.minus(
        protocolFeeTotalInPaymentMethodCurrency,
      ),
    },
    'user-enters-contribution-amount': {
      symbol: userEnteredAmountInPaymentMethodCurrency.symbol,
      value: userEnteredAmountInPaymentMethodCurrency.value,
    },
  }

  const contributionAmountInPaymentMethodCurrency =
    contributionAmountByInputMode[purchaseAmountMode]

  const totalCostInPaymentMethodCurrency: CurrencyValue = {
    value: contributionAmountInPaymentMethodCurrency.value
      .plus(protocolFeeInPaymentMethodCurrency.value)
      .plus(
        userEnteredAmountInPaymentMethodCurrency.symbol ===
          platformFeeInETH.symbol
          ? platformFeeInETH.value
          : new BigNumber(0),
      ),
    symbol: userEnteredAmountInPaymentMethodCurrency.symbol,
  }

  const totalCostInNativeCurrency: CurrencyValue = {
    value:
      totalCostInPaymentMethodCurrency.symbol === platformFeeInETH.symbol
        ? totalCostInPaymentMethodCurrency.value
        : platformFeeInETH.value,
    symbol: platformFeeInETH.symbol,
  }

  return {
    protocolFeeInPaymentMethodCurrency,
    platformFeeInNativeCurrency: platformFeeInETH,
    contributionAmountInPaymentMethodCurrency:
      contributionAmountByInputMode[purchaseAmountMode],
    totalCostInPaymentMethodCurrency,
    totalCostInNativeCurrency,
  }
}

import { useAdminApiClient } from '@api/clients'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useCreateDistribution = () => {
  const client = useAdminApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: { domainId: ID }) => {
      return client(`domains/${data.domainId}/distribution`, {
        method: 'post',
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

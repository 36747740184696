interface PaginationProps {
  currentPage: number
  pageSize: number
  totalItems: Maybe<number>
  hasNextPage?: Maybe<boolean>
  onPageSizeChange: (size: number) => void
  onPreviousPage: () => void
  onNextPage: () => void
}

export const Pagination = ({
  currentPage,
  pageSize,
  totalItems,
  hasNextPage = null,
  onPageSizeChange,
  onPreviousPage,
  onNextPage,
}: PaginationProps) => {
  const totalPages = totalItems ? Math.ceil(totalItems / pageSize) : undefined

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPreviousPage()
    }
  }

  const handleNextPage = () => {
    if (typeof totalPages === 'number') {
      if (currentPage < totalPages) {
        onNextPage()
      }
    } else if (hasNextPage === true) {
      onNextPage()
    }
  }

  const disableNextButton = () => {
    if (typeof totalPages === 'number') {
      return currentPage === totalPages
    } else {
      return hasNextPage === false
    }
  }

  return (
    <div className='flex items-center justify-between mb-4 p-2 border-t border-b border-gray-200'>
      <span className='text-sm text-gray-700'>
        Page {currentPage}
        {typeof totalPages === 'number' ? ` of ${totalPages}` : ''}
        {' | Total items: '}
        {typeof totalItems === 'number' ? totalItems : 'N/A'}
      </span>

      <div className='flex items-center space-x-4'>
        <select
          value={pageSize}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          className='border border-gray-300 rounded px-2 py-1 text-sm'
        >
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </select>
        <div className='flex items-center space-x-2'>
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`px-3 py-1 border rounded ${
              currentPage === 1
                ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-700 hover:bg-gray-100'
            }`}
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={disableNextButton()}
            className={`px-3 py-1 border rounded ${
              disableNextButton()
                ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-700 hover:bg-gray-100'
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

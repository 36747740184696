import { getResourceUri } from '@utils'
import { ImgHTMLAttributes } from 'react'

export const AnyImage = ({ ...props }: ImgHTMLAttributes<HTMLImageElement>) => {
  let src = props.src

  if (src && src.startsWith('ipfs://')) {
    src = getResourceUri(src)
  }

  return <img alt={props.alt} {...props} src={src} />
}

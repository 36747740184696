import { useTsPrivyApiClient } from '@apiClients'
import {
  useGetProjectSocials,
  useGetProjectTheme,
  useGetProjectWelcomeContent,
} from '@apiServices'
import { Alert, Col, Text, TokensoftPoweredByIcon } from '@components'
import {
  DEFAULT_WELCOME_BG_COLOR,
  DEFAULT_WELCOME_BG_COLOR_LEGACY,
} from '@constants'
import { useAnalytics, useAuth, useToast } from '@contexts'
import { LAYOUT_TYPE } from '@enums'
import { useBreakpoint, useTSAddress } from '@hooks'
import { FadeInImage, ProjectSocials } from '@newComponents'
import { useLogin, usePrivy, useWallets } from '@privy-io/react-auth'
import { getIPFSLink } from '@utils'
import { motion } from 'framer-motion'
import { useCallback, useEffect, useMemo } from 'react'
import { FaExclamationCircle } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import {
  ProjectWelcomeContent,
  UserWalletAddress,
} from 'tokensoft-shared-types'
import './connect.css'

export const ConnectPage = () => {
  useAnalytics('/welcome')

  const { showErrorToast } = useToast()

  const { pathname } = useLocation()
  const { data: projectSocials } = useGetProjectSocials()
  const { data: projectTheme } = useGetProjectTheme()
  const { data: projectWelcomeContent } = useGetProjectWelcomeContent()
  const { disconnect, isAuthenticated, authenticate } = useAuth()

  // Access to privy endpoints on TS API, not directly the Privy API
  const tsPrivyApiClient = useTsPrivyApiClient()

  // const { authenticate } = useAuth()
  const { address: TSAddress } = useTSAddress()
  const { wallets } = useWallets()
  const socialLinkColor = projectTheme?.colors.primary

  const isRegistration = pathname.startsWith('/admin/register')

  const getWelcomeTitle = (isRegistration: boolean, projectTitle?: string) => {
    return (
      projectTitle ||
      (isRegistration
        ? "Let's Get Started"
        : 'Delivering Integrity and Compliance')
    )
  }

  const getWelcomeSubtitle = (
    isRegistration: boolean,
    projectSubtitle?: string,
  ) => {
    return (
      projectSubtitle ||
      (isRegistration
        ? 'Thank you for choosing Tokensoft. Connect a wallet to setup your project.'
        : 'Enterprise services & tools for the leading blockchain foundations.')
    )
  }

  const getTitleAlignment = (layoutVersion?: string) => {
    switch (layoutVersion) {
      case LAYOUT_TYPE.TITLE_ICON_CENTERED:
      case LAYOUT_TYPE.TITLE_ONLY_100_CENTERED:
        return 'text-center'
      case LAYOUT_TYPE.TITLE_ONLY_100_LEFT:
        return 'text-left'
      case LAYOUT_TYPE.TITLE_ONLY_100_RIGHT:
        return 'text-right'
      default:
        return undefined
    }
  }

  const isCustomWelcomeContent = (
    projectWelcomeContent: ProjectWelcomeContent,
  ) => {
    return (
      (projectWelcomeContent.backgroundColor !==
        DEFAULT_WELCOME_BG_COLOR_LEGACY &&
        projectWelcomeContent.backgroundColor !== DEFAULT_WELCOME_BG_COLOR) ||
      projectWelcomeContent.backgroundImage !== null ||
      projectWelcomeContent.bodyText !== null
    )
  }

  const {
    welcomeTitle,
    welcomeSubtitle,
    containerClass,
    welcomeLayoutVersion,
    welcomeLogo,
    welcomeTitleAlignment,
  } = useMemo(() => {
    const usingCustomWelcomeContent =
      projectWelcomeContent !== undefined
        ? isCustomWelcomeContent(projectWelcomeContent)
        : false

    return {
      welcomeTitle: getWelcomeTitle(
        isRegistration,
        projectWelcomeContent?.title,
      ),
      welcomeSubtitle: getWelcomeSubtitle(
        isRegistration,
        projectWelcomeContent?.subtitle,
      ),
      containerClass: usingCustomWelcomeContent
        ? 'domain-content'
        : 'tokensoft-content',
      welcomeLayoutVersion: projectWelcomeContent?.layoutVersion,
      welcomeLogo:
        projectWelcomeContent?.logo !== undefined
          ? projectWelcomeContent.logo
          : '#',
      welcomeTitleAlignment: getTitleAlignment(
        projectWelcomeContent?.layoutVersion,
      ),
    }
  }, [isRegistration, projectWelcomeContent])

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.6, staggerChildren: 0.2 },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.4 } },
  }

  const breakpoint = useBreakpoint()

  const backgroundStyle = useMemo(() => {
    const { backgroundImage, backgroundImageMobile, backgroundColor } =
      projectWelcomeContent || {}

    if (backgroundImage) {
      const isMobile = breakpoint === 'sm'

      const resolvedBackgroundImage = backgroundImage.startsWith('ipfs://')
        ? getIPFSLink(backgroundImage)
        : backgroundImage

      const resolvedMobileBackgroundImage = backgroundImageMobile
        ? backgroundImageMobile.startsWith('ipfs://')
          ? getIPFSLink(backgroundImageMobile)
          : backgroundImageMobile
        : resolvedBackgroundImage // Fallback to regular background if mobile one is missing

      const resolvedStyle = {
        backgroundImage: `url(${isMobile ? resolvedMobileBackgroundImage : resolvedBackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }
      return resolvedStyle
    }

    return { backgroundColor: backgroundColor || 'transparent' }
  }, [projectWelcomeContent, breakpoint])

  const {
    ready,
    getAccessToken,
    authenticated: privyAuthenticated,
    logout: privyLogout,
  } = usePrivy()
  const { login } = useLogin({
    onComplete: async () => {
      console.log('Login onComplete')
      const accessToken = await getAccessToken()

      if (accessToken === null) {
        showErrorToast({
          description: 'Failed to authenticate with access token.',
        })
        return
      }

      const userInfo = (await tsPrivyApiClient('auth-token', accessToken)) as {
        token: string
        authId: ID
        walletAddress: EvmAddress
        walletAddresses: UserWalletAddress[]
        country: CountryCode
        region: string
      }
      authenticate(userInfo)
    },
  })

  useEffect(() => {
    if (privyAuthenticated && !isAuthenticated) {
      // if they are authenticated with Privy, but are missing their TokenSoft
      // session, we need to logout their Privy session, so that they can
      // restart the authentication process from scratch.
      //
      // Without logging out their Privy session, then Privy with fail with
      // "Attempted to log in, but user is already logged in."

      void privyLogout()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogin = () => {
    console.log('INFO: ConnectPage-handleLogin() called')
    login()
  }

  useEffect(() => {
    console.log('INFO: ConnectPage-useEffect:', {
      ready,
      wallets,
      TSAddress,
    })
  }, [wallets, ready, TSAddress])

  const handleDisconnectClick = useCallback(() => {
    disconnect()
  }, [disconnect])

  return (
    <div className='welcome-content-body'>
      {import.meta.env.VITE_PROCESS_ENV !== 'production' && (
        <Alert
          type='alert-info'
          className='header-alert square-borders !fixed top-1 z-20'
          icon={<FaExclamationCircle className='danger' />}
        >
          <div className='flex items-center'>
            This site is used for testing purposes only. Do not submit personal
            information.
          </div>
        </Alert>
      )}

      <motion.div
        style={backgroundStyle}
        className={`welcome-content welcome-color ${containerClass} relative z-10`}
        initial='hidden'
        animate='visible'
        variants={containerVariants}
      >
        <div
          className={`flex flex-col ${projectWelcomeContent?.alignment || 'center-center'} w-full mb-4`}
        >
          {projectWelcomeContent &&
          isCustomWelcomeContent(projectWelcomeContent) &&
          projectWelcomeContent.bodyText ? (
            <motion.div
              variants={itemVariants}
              dangerouslySetInnerHTML={{
                __html: projectWelcomeContent.bodyText,
              }}
            />
          ) : (
            <>
              {welcomeLayoutVersion === LAYOUT_TYPE.TITLE_ICON_CENTERED &&
                welcomeLogo !== undefined && (
                  <FadeInImage
                    src={welcomeLogo}
                    className='h-32 mx-auto'
                    alt='Welcome Logo'
                    width='auto'
                  />
                )}
              <Text
                className={`mb-5 mt-6 welcome-title ${welcomeTitleAlignment}`}
              >
                {welcomeTitle}
              </Text>
              <motion.p
                className={`welcome-subtitle ${welcomeTitleAlignment}`}
                variants={itemVariants}
              >
                {welcomeSubtitle}
              </motion.p>
            </>
          )}

          <Col className='mt-12' xalign='center'>
            <motion.div className='mt-4' variants={itemVariants}>
              {isAuthenticated ? (
                <button
                  data-testid='disconnect-button'
                  className='connect-button'
                  onClick={handleDisconnectClick}
                  disabled={!ready}
                >
                  Logout
                </button>
              ) : (
                <button
                  data-testid='connect-button'
                  className='connect-button'
                  onClick={handleLogin}
                  disabled={!ready}
                >
                  Login
                </button>
              )}
            </motion.div>

            {projectWelcomeContent && (
              <motion.div className='mt-5' variants={itemVariants}>
                <TokensoftPoweredByIcon />
              </motion.div>
            )}
          </Col>
        </div>

        <motion.div className='h-[36px]' variants={itemVariants}>
          <ProjectSocials
            socialLinks={projectSocials}
            color={socialLinkColor}
          />
        </motion.div>
      </motion.div>
    </div>
  )
}

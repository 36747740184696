export const StatusIconSuccess = () => {
  return (
    <svg
      className='shrink-0'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1766_56560)'>
        <path
          d='M8.3329 17.3134C7.60758 17.1488 6.91097 16.8767 6.26624 16.5059'
          stroke='#0D914F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.6666 2.68555C13.3234 3.06394 14.8027 3.99362 15.8622 5.32239C16.9217 6.65115 17.4987 8.30026 17.4987 9.99971C17.4987 11.6992 16.9217 13.3483 15.8622 14.677C14.8027 16.0058 13.3234 16.9355 11.6666 17.3139'
          stroke='#0D914F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.81547 14.244C3.36089 13.5835 3.01564 12.8542 2.79297 12.084'
          stroke='#0D914F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.60413 8.74896C2.73746 7.95729 2.99413 7.20729 3.35413 6.51979L3.49496 6.26562'
          stroke='#0D914F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.75525 3.81555C6.53496 3.2788 7.40968 2.89531 8.33275 2.68555'
          stroke='#0D914F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.5 10.0007L9.16667 11.6673L12.5 8.33398'
          stroke='#0D914F'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1766_56560'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

interface ChainDropdownOptionProps {
  chainName: string
  chainIconUrl: HttpsUrl
  onClick: () => void
  isActive: boolean
}

export const ChainDropdownOption = ({
  chainName,
  chainIconUrl,
  onClick,
  isActive,
}: ChainDropdownOptionProps) => {
  return (
    <button
      className='gap-[10px] w-full flex justify-start items-center px-4 h-[36px] hover:bg-[#eff3ff] transition-colors'
      onClick={onClick}
    >
      <img className='h-5 w-5' alt={`${chainName} icon`} src={chainIconUrl} />
      <span className='text-nowrap'>{chainName}</span>
    </button>
  )
}

import { ErrorBoundary } from '@components'
import '@components/common.css'
import {
  AccountProvider,
  AnalyticsProvider,
  AuthProvider,
  LayoutProvider,
  LoginOptionsProvider,
  ModalProvider,
  NetworkProvider,
  ToastProvider,
  TSPrivyProvider,
  TSWagmiProvider,
  WalletProvider,
} from '@contexts'
import '@rainbow-me/rainbowkit/styles.css'
import './instrument'
import './share-session-storage-between-tabs'
// import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ScrollToTop } from '@utils'
import { Container, createRoot } from 'react-dom/client'
import Modal from 'react-modal'
import { BrowserRouter as Router } from 'react-router-dom'
import { TSRoutes } from './routes'

Modal.setAppElement('#root')

// const replay = import.meta.env.VITE_SENTRY_REPLAY || 'false';
// console.log('Session replay:', replay);

// if (import.meta.env.VITE_SENTRY_DSN) {
//   Sentry.init({
//     dsn: import.meta.env.VITE_SENTRY_DSN,
//     environment: import.meta.env.VITE_PROCESS_ENV,
//     tracesSampleRate:
//       import.meta.env.VITE_PROCESS_ENV === 'development' ? 0.0 : 1.0,
//     release: import.meta.env.VITE_VERSION,
//     // see common filters here: https://docs.sentry.io/platforms/javascript/configuration/filtering/
//     ignoreErrors: [
//       'closeTransport called before connection was established',
//       'To use QR modal, please install @web3modal/standalone package',
//       // generic Axios network error https://tokensoft.sentry.io/issues/4331138801/
//       'Network Error',
//       'No matching key. keychain',
//       'No matching key. history',
//       // walletconnect websocket issue
//       /WebSocket connection failed for host: wss:\/\/relay\.walletconnect\.com/,
//     ],
//     denyUrls: [
//       // Chrome extensions
//       /extensions\//i,
//       /^chrome:\/\//i,
//       /^chrome-extension:\/\//i,
//     ],
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//     integrations: replay === 'true' ? [new Sentry.Replay()] : [],
//   });
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

if (typeof window === 'object') {
  // @ts-expect-error exposing functions to global scope
  window.__TOKENSOFT_QUERY_CLIENT__ = queryClient
}

const Root = () => (
  // <StrictMode>
  <ErrorBoundary>
    <LoginOptionsProvider>
      <TSPrivyProvider>
        <QueryClientProvider client={queryClient}>
          <NetworkProvider>
            <TSWagmiProvider>
              <ModalProvider>
                <ToastProvider>
                  <Router>
                    <ScrollToTop />
                    <AnalyticsProvider>
                      <AuthProvider>
                        <AccountProvider>
                          <WalletProvider>
                            <LayoutProvider>
                              <TSRoutes />
                            </LayoutProvider>
                          </WalletProvider>
                        </AccountProvider>
                      </AuthProvider>
                    </AnalyticsProvider>
                  </Router>
                </ToastProvider>
              </ModalProvider>
            </TSWagmiProvider>
          </NetworkProvider>
        </QueryClientProvider>
      </TSPrivyProvider>
    </LoginOptionsProvider>
  </ErrorBoundary>
  // </StrictMode>
)

const rootElement = document.getElementById('root')
const root = createRoot(rootElement as Container)
root.render(<Root />)

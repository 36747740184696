import { useGetPrivateEventMembersPaginated } from '@apiServices'
import { Button } from '@components/button/button'
import { ChevronDownIcon, ChevronUpIcon } from '@components/icon'
import { useDebounce, useOnClickOutside } from '@hooks'
import { Pagination } from '@new-components'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import React, { useMemo, useRef, useState } from 'react'
import { PrivateEventMember } from 'tokensoft-shared-types'

interface PrivateEventMembersTableProps {
  eventId: number
}

export const PrivateEventMembersTable: React.FC<
  PrivateEventMembersTableProps
> = ({ eventId }) => {
  const [walletFilter, setWalletFilter] = useState('')
  const [emailFilter, setEmailFilter] = useState('')
  const [tempWalletFilter, setTempWalletFilter] = useState('')
  const [tempEmailFilter, setTempEmailFilter] = useState('')
  const [sorting, setSorting] = useState<SortingState>([])
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const debouncedWalletFilter = useDebounce(tempWalletFilter, 500)
  const debouncedEmailFilter = useDebounce(tempEmailFilter, 500)

  const {
    data,
    count,
    isLoading,
    pageSize,
    pageNumber,
    setPageSize,
    setPageNumber,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useGetPrivateEventMembersPaginated({
    eventId,
    filters: { wallet: walletFilter, email: emailFilter },
    sorting: sorting[0]
      ? {
          orderBy: sorting[0].id as 'email' | 'wallet',
          sortOrder: sorting[0].desc ? 'desc' : 'asc',
        }
      : {},
  })

  React.useEffect(() => {
    setWalletFilter(debouncedWalletFilter)
    setEmailFilter(debouncedEmailFilter)
    setPageNumber(1)
  }, [debouncedWalletFilter, debouncedEmailFilter, setPageNumber])

  const columns: ColumnDef<PrivateEventMember>[] = useMemo(
    () => [
      { header: 'Email', accessorKey: 'email', enableSorting: true },
      { header: 'Wallet', accessorKey: 'wallet', enableSorting: true },
      {
        header: 'Created At',
        accessorKey: 'createdAt',
        cell: ({ getValue }) => new Date(getValue() as string).toLocaleString(),
      },
    ],
    [],
  )

  const table = useReactTable({
    data: data || [],
    columns,
    state: { sorting },
    onSortingChange: (updater) => {
      setSorting(updater)
      setPageNumber(1)
    },
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
  })

  const handlePageSizeChange = (size: number) => {
    setPageSize(size)
    setPageNumber(1)
  }

  const filtersRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(filtersRef, () => setIsFilterOpen(false))

  const toggleFilterDropdown = () => setIsFilterOpen((prev) => !prev)

  return (
    <div className='min-w-full'>
      {/* Filter Dropdown */}
      <div className='flex justify-between items-center relative mb-4'>
        <Button
          onClick={toggleFilterDropdown}
          className='ml-auto px-4 py-2 text-white rounded-md hover:bg-primary-dark'
        >
          Filters{' '}
          {isFilterOpen ? (
            <ChevronUpIcon color='#fff' />
          ) : (
            <ChevronDownIcon color='#fff' />
          )}
        </Button>
        {isFilterOpen && (
          <div
            ref={filtersRef}
            className='absolute top-full right-0 z-10 mt-2 p-4 bg-white border border-gray-200 rounded-md shadow-lg w-64'
          >
            <div className='mb-3'>
              <label
                htmlFor='walletFilter'
                className='block text-sm font-medium text-gray-700 mb-1'
              >
                Wallet
              </label>
              <input
                id='walletFilter'
                type='text'
                value={tempWalletFilter}
                onChange={(e) => setTempWalletFilter(e.target.value)}
                className='w-full px-2 py-1 border border-gray-300 rounded-md text-sm'
                placeholder='Filter wallets...'
              />
            </div>
            <div>
              <label
                htmlFor='emailFilter'
                className='block text-sm font-medium text-gray-700 mb-1'
              >
                Email
              </label>
              <input
                id='emailFilter'
                type='text'
                value={tempEmailFilter}
                onChange={(e) => setTempEmailFilter(e.target.value)}
                className='w-full px-2 py-1 border border-gray-300 rounded-md text-sm'
                placeholder='Filter emails...'
              />
            </div>
          </div>
        )}
      </div>

      {/* Table Container with Fixed Height */}
      <div className='overflow-x-auto min-h-[400px]'>
        {isLoading ? (
          <div className='flex items-center justify-center h-full'>
            <div className='inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-light'></div>
          </div>
        ) : (
          <table className='min-w-full border border-gray-200'>
            <thead className='bg-gray-50'>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className='px-6 py-3 text-left text-sm font-semibold text-gray-900 border-b border-gray-200'
                    >
                      <div
                        className={
                          header.column.getCanSort()
                            ? 'cursor-pointer select-none flex items-center gap-1'
                            : ''
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {header.column.getCanSort() && (
                          <span>
                            {{
                              asc: '↑',
                              desc: '↓',
                            }[
                              header.column.getSortIndex() !== -1
                                ? (header.column.getIsSorted() as string)
                                : ''
                            ] || '↕'}
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='min-h-[300px]'>
              {table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row, index) => (
                  <tr
                    key={row.id}
                    className={`${index % 2 === 0 ? 'bg-primary-light/10' : 'bg-white'} hover:bg-gray-50`}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className='px-6 py-4 border-b border-gray-200 text-sm text-gray-700'
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={columns.length}
                    className='px-6 py-4 text-center text-sm text-gray-500 border-b border-gray-200 h-[300px]'
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      {/* Pagination */}
      {!isLoading && (
        <Pagination
          currentPage={pageNumber}
          pageSize={pageSize}
          totalItems={count}
          hasNextPage={hasNextPage}
          onPageSizeChange={handlePageSizeChange}
          onPreviousPage={fetchPreviousPage}
          onNextPage={fetchNextPage}
        />
      )}
    </div>
  )
}

import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { BigNumber } from 'bignumber.js'
import { DistributorMerkleTreeLeaf } from 'tokensoft-shared-types'
import { Hex } from 'viem'
import { decodeVestingSchedule } from './use-get-my-distributor-claimable-amount'

export const useGetMyDistributorMerkleLeaf = (
  distributorAddress: EvmAddress,
  vestingType: 'continuous' | 'tranche' | null = null, // Add vestingType parameter
  enabled: boolean = true,
) => {
  const fetchWithClient = useWebApiClient()

  return useQuery<Maybe<DistributorMerkleTreeLeaf>>({
    queryKey: [
      'distributors',
      distributorAddress,
      'merkle-tree-leaf',
      vestingType,
    ],
    queryFn: async () => {
      try {
        const leafRaw = (await fetchWithClient(
          `distributors/${distributorAddress}/merkle-tree-leaf`,
        )) as Record<string, unknown>

        if (!leafRaw) {
          return null
        }

        const leaf: DistributorMerkleTreeLeaf = {
          amount: new BigNumber(leafRaw.amount as string),
          beneficiary: leafRaw.beneficiary as Hex,
          index: leafRaw.index as number,
          merkleProof:
            leafRaw.proof as DistributorMerkleTreeLeaf['merkleProof'],
          encodedVestingSchedule: leafRaw.encodedVestingSchedule as Hex,
        }

        // Decode vesting schedule if present and vestingType is known
        if (
          leaf.encodedVestingSchedule &&
          leaf.encodedVestingSchedule !== '0x' &&
          vestingType
        ) {
          leaf.vestingSchedule = decodeVestingSchedule(
            leaf.encodedVestingSchedule,
            vestingType,
          )
        }

        return leaf
      } catch (e) {
        if (e instanceof AxiosError && e.response?.status === 404) {
          return null
        }
        throw e
      }
    },
    enabled: enabled,
  })
}

import { Text } from '@components'
import { VscLinkExternal } from 'react-icons/vsc'
import { twMerge } from 'tailwind-merge'

type ExternalUrlProps = {
  url: Url
  text: string
  className?: string
}

export const ExternalUrl = ({ url, text, className }: ExternalUrlProps) => {
  return (
    <a
      href={url}
      className={twMerge(
        'flex gap-2 items-center text-primary-medium',
        className,
      )}
      target='_blank'
      rel='noreferrer'
    >
      <Text className='text-sm font-semibold'>{text}</Text>
      <VscLinkExternal size={14} />
    </a>
  )
}

import axios, { AxiosRequestConfig, Method } from 'axios'
import { useCallback } from 'react'

interface RequestConfig extends AxiosRequestConfig {
  token?: string
}

const tsPrivyApiClient = (
  endpoint: string,
  accessToken: string,
  { data, headers: customHeaders, ...customConfig }: RequestConfig,
) => {
  const method: Method = data ? 'post' : 'get'

  const config: AxiosRequestConfig = {
    method,
    url: `${import.meta.env.VITE_API_URL}/privy/v1/${endpoint}`,
    data: data || undefined,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...(data ? { 'Content-Type': 'application/json' } : {}),
      ...customHeaders,
    },
    ...customConfig,
  }

  return axios.request(config).then(
    (res) => res.data,
    (error) => {
      const { status: statusCode } = error.toJSON() as { status: number }

      if (statusCode === 500) {
        return Promise.reject({
          message: error.response.data.message,
        })
      }

      return Promise.reject(error)
    },
  )
}

const useTsPrivyApiClient = () => {
  return useCallback(
    (
      endpoint: string,
      accessToken: string,
      config: Omit<RequestConfig, 'token' | 'signOut'> = {},
    ) =>
      tsPrivyApiClient(endpoint, accessToken, {
        ...config,
        token: accessToken,
      }),
    [],
  )
}

export { tsPrivyApiClient, useTsPrivyApiClient }

import { getErc20ABI } from '@utils'
import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import { Abi } from 'viem'
import { useReadContract } from 'wagmi'

export const useErc20Allowance = (
  chainId: number,
  tokenAddress: EvmAddress,
  owner: string,
  spender: string,
) => {
  const isEthUSDT =
    chainId === 1 &&
    tokenAddress.toLowerCase() === '0xdac17f958d2ee523a2206206994597c13d831ec7'
  const erc20Abi = getErc20ABI(isEthUSDT) as Abi

  const { data, isLoading, isError, error, refetch } = useReadContract({
    address: tokenAddress,
    abi: erc20Abi,
    functionName: 'allowance',
    args: [owner, spender],
  })

  const allowance = useMemo(() => {
    if (data === undefined) {
      return new BigNumber(0)
    }
    return new BigNumber((data as bigint).toString())
  }, [data])

  return { allowance, isLoading, isError, error, refetch }
}

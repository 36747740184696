import { FieldValues, Path } from 'react-hook-form'

interface TextInputProps<T extends FieldValues, K extends Path<T>>
  extends BaseInputProps<T, K> {
  label: string
  placeholder: string
  isRequired?: boolean
}

export const TextInput = <T extends FieldValues, K extends Path<T>>({
  id,
  label,
  placeholder,
  register,
  validate,
  className,
  inputClassName,
  testId,
  error,
  isRequired = true,
}: TextInputProps<T, K> & { inputClassName?: string; testId?: string }) => (
  <div className={className}>
    <label
      className='block text-gray-700 text-md font-medium mb-2'
      htmlFor={id as string}
    >
      {label}
    </label>
    <input
      id={id as string}
      type='text'
      placeholder={placeholder}
      // Don't show 1Pass
      // https://1password.community/discussion/117501/as-a-web-developer-how-can-i-disable-1password-filling-for-a-specific-field/p4
      data-1p-ignore='true'
      {...register(id, {
        required: isRequired ? `${label} is required` : false,
        validate,
      })}
      className={`shadow appearance-none border rounded w-full py-4 px-3 mb-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
        error ? 'border-red-500' : ''
      } ${inputClassName}`}
      data-testid={testId}
    />
    {error && <p className='text-red-500 text-xs italic'>{error}</p>}
  </div>
)

import { Badge } from './badge'

type BadgeList = {
  titles: string[]
  customizeBadgeClass?: (title: string) => string
}

export const BadgeList = ({ titles, customizeBadgeClass }: BadgeList) => {
  return (
    <div className='flex flex-row flex-wrap gap-1 overflow-hidden'>
      {titles.map((title, i) => (
        <Badge
          key={i}
          className={customizeBadgeClass ? customizeBadgeClass(title) : ''}
          text={title}
        />
      ))}
    </div>
  )
}

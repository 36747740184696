// ... existing imports ...

import { motion } from 'framer-motion'
import { Children, ReactElement, ReactNode } from 'react'

interface AnimatedStepperProps {
  children: ReactNode
  step: number
  nextHidden: boolean
  onNext: () => void
  onPrevious: () => void
  isNextDisabled: (stepIndex: number) => boolean
  startsAtIndex?: number
  showNavigationButtons?: boolean
}

export const AnimatedStepper = ({
  children,
  step,
  nextHidden,
  onNext,
  onPrevious,
  isNextDisabled,
  startsAtIndex = 0,
  showNavigationButtons = true,
}: AnimatedStepperProps) => {
  const steps = Children.toArray(children) as ReactElement[]

  return (
    <motion.div
      key={step}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: 'just', stiffness: 50, duration: 0.2 }}
    >
      {steps[step]}
      {showNavigationButtons && (
        <div className='flex justify-between items-center mt-4 mx-auto p-6 w-2/3'>
          <button onClick={onPrevious} className='btn btn-outline-dark'>
            {step === startsAtIndex ? 'Cancel' : 'Back'}
          </button>
          {!nextHidden && (
            <button
              className='btn btn-primary'
              onClick={onNext}
              disabled={isNextDisabled(step)}
            >
              Next
            </button>
          )}
        </div>
      )}
    </motion.div>
  )
}

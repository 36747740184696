import { FieldValues, Path } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

interface NumberInputProps<T extends FieldValues, K extends Path<T>>
  extends BaseInputProps<T, K> {
  placeholder: string
  maxAllowedDecimals?: number
  maxAllowedIntegerDigits?: number
  children?: React.ReactNode
}

export const NumberInput = <T extends FieldValues, K extends Path<T>>({
  id,
  label,
  placeholder,
  register,
  error,
  className,
  validate,
  maxAllowedDecimals = 8,
  maxAllowedIntegerDigits = 12,
  children,
  ...rest
}: NumberInputProps<T, K>) => {
  // Default validation function
  const _validate =
    validate ??
    ((value: string) => {
      const numValue = parseFloat(value)
      if (isNaN(numValue)) return 'Please enter a valid number'

      return true
    })

  return (
    <div className={twMerge('relative', className)}>
      <label
        className='block text-gray-700 text-md font-medium mb-2'
        htmlFor={id as string}
      >
        {label}
      </label>
      <input
        id={id as string}
        type='text' // Input type is 'text' allows more control
        placeholder={placeholder}
        inputMode='decimal' // Suggest numeric keypad on mobile devices
        {...register(id, {
          required: `${label} is required`,
          validate: _validate,
        })}
        {...rest}
        className={`shadow appearance-none border rounded w-full py-4 px-3 mb-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
          error ? 'border-red-500' : ''
        }`}
        onInput={(e) => {
          const input = e.target as HTMLInputElement

          // Process raw value to remove invalid characters
          let value = input.value.replace(
            maxAllowedDecimals === 0 ? /[^\d]/g : /[^\d.]/g,
            '',
          )

          // Ensure only one decimal point
          value = value.replace(/(\..*)\./g, '$1')

          // Limit integer and decimal parts
          let [integerPart, decimalPart] = value.split('.')
          integerPart = integerPart.slice(0, maxAllowedIntegerDigits)
          if (decimalPart) {
            decimalPart = decimalPart.slice(0, maxAllowedDecimals)
          }

          // Update the input value
          input.value =
            decimalPart || value.includes('.')
              ? `${integerPart}.${decimalPart}`
              : integerPart
        }}
      />
      {children && (
        <div className='absolute right-0 inset-y-0 flex items-center gap-2'>
          {children}
        </div>
      )}
      {error && <p className='text-red-500 text-xs italic'>{error}</p>}
    </div>
  )
}

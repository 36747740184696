import { useGetChain, useGetSaleByContractAddress } from '@apiServices'
import { useCurrencyRegistry } from '@contexts'
import {
  CollapsibleCard,
  EventPropertyRow,
  FormattedNumber,
  ProgressBar,
} from '@newComponents'
import { useEffect } from 'react'
import SaleAssetPrice from './event-properties/sale-asset-price'
import SalePurchaseLimit from './event-properties/sale-purchase-limit'
import SaleTotal from './event-properties/sale-total'

type SaleDetailsCardProps = {
  className?: string
  saleAddress: EvmAddress
}

export const SaleDetailsCard = ({
  className = '',
  saleAddress,
}: SaleDetailsCardProps) => {
  const {
    data: sale,
    isLoading,
    isError,
  } = useGetSaleByContractAddress(saleAddress)

  // Regiser sale token to currency registry
  const { registerPair } = useCurrencyRegistry()
  useEffect(() => {
    if (sale) {
      registerPair({
        base: sale.token.symbol, // Token being sold
        quote: sale.token.price.symbol, // Typically USD
        rate: sale.token.price.value, // Price of token in USD
      })
    }
  }, [sale, registerPair])

  const {
    data: chain,
    isPending: isChainPending,
    isError: isChainError,
  } = useGetChain(sale?.chainId)

  if (isLoading || isChainPending) {
    return <div>Loading...</div>
  }

  if (isError || isChainError || !sale) {
    return <div>Error loading sale data</div>
  }

  const saleProgressAsDecimal = sale.totalPurchases.value.dividedBy(
    sale.saleCap.value,
  )
  const percentCompleteStr = saleProgressAsDecimal.multipliedBy(100).toFixed(2)
  const percentComplete = parseFloat(percentCompleteStr)

  return (
    <CollapsibleCard cardTitle='Sale Details' className={className}>
      <div className='flex flex-col items-start'>
        {/* Sale progress and goal */}
        {sale.showSaleProgressToUsers && (
          <>
            <div className='text-4xl font-bold'>
              {sale.assetType === 'node' ? (
                <>
                  <FormattedNumber
                    value={sale.totalPurchases.value.div(
                      sale.token.price.value,
                    )}
                    displayDecimals={0}
                  />
                  &nbsp;/&nbsp;
                  <FormattedNumber
                    value={sale.saleCap.value.div(sale.token.price.value)}
                    displayDecimals={0}
                  />
                </>
              ) : (
                <>
                  $
                  <FormattedNumber
                    value={sale.totalPurchases.value}
                    displayDecimals={0}
                  />
                  &nbsp;/&nbsp;
                  <FormattedNumber
                    value={sale.saleCap.value}
                    displayDecimals={0}
                  />
                </>
              )}
            </div>
            <ProgressBar percentComplete={percentComplete} />
          </>
        )}

        {/* Sale details using EventPropertyRow */}
        <div className='grid grid-cols-2 w-full gap-4 sm:gap-x-16 md:gap-x-32 mt-4'>
          <EventPropertyRow label='Chain' value={chain?.name} />
          <SalePurchaseLimit
            assetType={sale.assetType}
            pricePerAsset={sale.token.price.value}
            purchaseLimit={sale.minimumPurchase}
            propertyName='minimum'
          />
          {window.location.hostname !== 'tanssi-network.tokensoft.io' && (
            <SaleTotal
              assetType={sale.assetType}
              pricePerAsset={sale.token.price.value}
              saleCap={sale.saleCap}
            />
          )}
          <SalePurchaseLimit
            assetType={sale.assetType}
            pricePerAsset={sale.token.price.value}
            purchaseLimit={sale.maximumPurchase}
            propertyName='maximum'
          />
          <SaleAssetPrice
            assetType={sale.assetType}
            assetPrice={sale.token.price}
          />
        </div>
      </div>
    </CollapsibleCard>
  )
}

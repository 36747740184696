import {
  useGetChain,
  useGetDistributorByContractAddress,
  useGetMyDistributorClaimableAmount,
  useGetMyDistributorMerkleLeaf,
  useGetTokenDetails,
} from '@apiServices'
import { Button, LoadingIndicator, Text } from '@components'
import { useToast } from '@contexts'
import { Card, ChainGate, FormattedCurrency, StatusBox } from '@newComponents'
import { eq, getBlockExplorerTokenUrl } from '@utils'
import { getVestingScheduleDescription } from '@utils/vesting'
import BigNumber from 'bignumber.js'
import { useEffect, useState } from 'react'
import { VscLinkExternal } from 'react-icons/vsc'
import { TSEvent } from 'tokensoft-shared-types'
import { TransactionReceipt } from 'viem'
import { EventModal } from '../eligibility/event-modal'
import { ClaimFlow } from './claim-flow'

type ClaimCardProps = {
  event: TSEvent
  className?: string
  distributorAddress: EvmAddress
  chainId: ChainId
  isClaimAvailable: boolean
}

export const ClaimCard = ({
  event,
  className = '',
  distributorAddress,
  chainId,
  isClaimAvailable,
}: ClaimCardProps) => {
  const { showErrorToast } = useToast()

  const [didClaimSuccessfully, setDidClaimSuccessfully] = useState(false)
  const [transactionHash, setTransactionHash] =
    useState<Maybe<EvmAddress>>(null)
  const {
    data: distributor,
    isPending: isDistributorPending,
    isError: isDistributorError,
    error: distributorError,
  } = useGetDistributorByContractAddress(distributorAddress)

  const {
    data: chain,
    isPending: isChainPending,
    isError: isChainError,
    error: chainError,
  } = useGetChain(chainId)

  const { data: merkleLeaf, isPending: isMerkleLeafPending } =
    useGetMyDistributorMerkleLeaf(
      distributorAddress,
      distributor?.vestingType?.toLowerCase() as VestingType,
    )

  const { data: token, isPending: isTokenPending } = useGetTokenDetails(
    chainId,
    distributor?.token.address ?? null,
  )

  const {
    data: claimableAmount,
    isPending: isClaimablePending,
    error: claimableAmountError,
  } = useGetMyDistributorClaimableAmount({
    distributor: {
      address: distributorAddress,
      version: event.useExperimentalContractFeatures ? 'v5' : 'legacy',
    },
    merkleLeaf: merkleLeaf ?? undefined,
  })

  useEffect(() => {
    // TODO: should we report these to Sentry?

    if (distributorError) {
      console.error(distributorError)

      showErrorToast({
        description: distributorError.message,
      })
    }

    if (chainError) {
      console.error(chainError)

      showErrorToast({
        description: chainError.message,
      })
    }

    // claimableAmountError is ignored because it is expected to fail when the
    // claim is not initialized

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributorError, chainError, claimableAmountError])

  const [claimModalOpen, setClaimModalOpen] = useState(false)
  const openClaimModal = () => {
    setClaimModalOpen(true)
  }

  const closeClaimModal = () => {
    setClaimModalOpen(false)
  }

  if (
    isDistributorPending ||
    isChainPending ||
    isMerkleLeafPending ||
    isTokenPending ||
    (merkleLeaf && isClaimablePending)
  ) {
    return <LoadingIndicator />
  }

  if (isDistributorError) return <div>Error: {distributorError.message}</div>

  if (isChainError) return <div>Error: {chainError.message}</div>

  const calculateAllocation = (allocation: BigNumber, decimals: number) => {
    return allocation.dividedBy(new BigNumber(10).pow(decimals))
  }

  const handleClaimSuccess = (receipt: TransactionReceipt) => {
    setTransactionHash(receipt.transactionHash)
    setDidClaimSuccessfully(true)
  }

  const handleChainChanged = (_chainId: number, isRequiredChain: boolean) => {
    if (!isRequiredChain) {
      setClaimModalOpen(false)
    }
  }

  return (
    <Card className={className} title='Token Claims'>
      <Text className='text-md'>
        {merkleLeaf?.vestingSchedule
          ? getVestingScheduleDescription(merkleLeaf.vestingSchedule)
          : 'Tokens vested over time will be available to claim here.'}
      </Text>
      <ChainGate
        requiredChainId={distributor.chainId}
        onChainChanged={handleChainChanged}
      >
        {claimableAmount !== null && token !== null && (
          <div className='flex flex-wrap justify-between sm:justify-around gap-x-20 gap-y-10 mt-5'>
            <div className='flex-1'>
              <Text className='text-3xl whitespace-nowrap mb-1 font-semibold text-black'>
                {didClaimSuccessfully ? (
                  0
                ) : (
                  <FormattedCurrency
                    currencyValue={{
                      value: claimableAmount,
                      symbol: token.symbol,
                    }}
                    displayDecimals={2}
                  />
                )}
              </Text>
              <Text className='mb-2 text-xs font-normal'>
                Available to claim
              </Text>
              <a
                target='_blank'
                href={
                  ['airdrop.shardeum.org', 'shardeum.tokensoft.io'].includes(
                    window.location.hostname,
                  ) && Number(event.id) === 3239
                    ? 'https://shardeum.org/'
                    : getBlockExplorerTokenUrl(chain, distributor.token.address)
                }
              >
                <Text className='flex whitespace-nowrap items-center gap-1 text-primary-medium text-sm'>
                  Contract Details <VscLinkExternal size={14} />
                </Text>
              </a>
            </div>
            <div className='flex-1'>
              <Text className='text-2xl whitespace-nowrap font-semibold mb-1'>
                <FormattedCurrency
                  currencyValue={{
                    value: calculateAllocation(
                      merkleLeaf?.amount ?? new BigNumber(0),
                      distributor.token.decimals,
                    ),
                    symbol: token.symbol,
                  }}
                  displayDecimals={2}
                />
              </Text>
              <Text className='text-xs font-normal'>
                Total claim eligibility after vesting ends.
              </Text>
            </div>
          </div>
        )}
        {claimableAmount !== null && didClaimSuccessfully && (
          <div className='mt-6'>
            <StatusBox
              status='success'
              text={`${parseFloat(claimableAmount.toFixed(6)).toString()} ${token?.symbol} Claimed Successfully`}
            />
          </div>
        )}
        {isClaimAvailable && !!merkleLeaf && (
          <div className='flex justify-center mt-8'>
            <Button
              className='w-full rounded-full'
              onClick={openClaimModal}
              disabled={
                token === null ||
                claimableAmount === null ||
                eq(claimableAmount, 0) ||
                transactionHash !== null ||
                didClaimSuccessfully
              }
            >
              Claim Tokens
            </Button>
          </div>
        )}
        {isClaimAvailable && !isMerkleLeafPending && merkleLeaf === null && (
          <Text className='bg-yellow-50 rounded-md p-4 text-center text-gray-500 mt-2'>
            The connected wallet has no claims on this distributor.
          </Text>
        )}
      </ChainGate>
      <EventModal
        isOpen={claimModalOpen}
        onRequestClose={closeClaimModal}
        contentLabel='Claim Modal'
        shouldCloseOnOverlayClick={false}
      >
        <ClaimFlow
          event={event}
          distributor={distributor}
          chainId={chainId}
          distributorAddress={distributorAddress}
          merkleLeaf={merkleLeaf!}
          token={token!}
          claimAmount={claimableAmount ?? new BigNumber(0)}
          onClaimSuccess={handleClaimSuccess}
          onFinished={closeClaimModal}
        />
      </EventModal>
    </Card>
  )
}

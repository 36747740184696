import { useIsEmailInUse, usePostForgotWalletsEmail } from '@apiServices'
import { useToast } from '@contexts'
import { useThrottle } from '@hooks'
import { validateEmail } from '@utils'
import { useState } from 'react'
import { InputGroup, InputGroupProps } from '../input-group/input-group'

interface EmailInputProps extends InputGroupProps {
  value: string
}

export const EmailInput = ({
  value,
  error = null,
  readonly = false,
  disabled = false,
  colWidths,
  ...rest
}: EmailInputProps) => {
  const [emailExists, setEmailExists] = useState<boolean>(false)
  const { mutateAsync: checkEmailInUse } = useIsEmailInUse()
  const sendForgotWalletsEmail = usePostForgotWalletsEmail()
  const [lastCheckedEmail, setLastCheckedEmail] = useState<Maybe<string>>(null)

  const { showSuccessToast } = useToast()

  useThrottle(
    async () => {
      if (!value || readonly || disabled) return
      if (!validateEmail(value)) return
      if (value === lastCheckedEmail) return

      try {
        const result = await checkEmailInUse({ email: value })
        setLastCheckedEmail(value)
        setEmailExists(result)
      } catch (error) {
        console.error('Error checking email:', error)
      }
    },
    1000,
    [value, readonly, disabled],
  )

  const handleSendEmail = (e: React.MouseEvent) => {
    e.preventDefault()
    if (emailExists) {
      sendForgotWalletsEmail.mutate({ email: value })
      showSuccessToast({ description: 'Wallets reminder email sent.' })
    }
  }

  return (
    <div>
      <InputGroup
        type='email'
        value={value}
        error={
          // Only show emailExists error if not readonly
          emailExists && !readonly ? (
            <>
              This email is already registered.{' '}
              <a
                href='#'
                role='button'
                onClick={handleSendEmail}
                className='underline'
              >
                Click here
              </a>{' '}
              to send a reminder with your registered wallet addresses.
            </>
          ) : (
            error || undefined
          )
        }
        readonly={readonly}
        disabled={disabled}
        colWidths={colWidths}
        {...rest}
      />
    </div>
  )
}

import { useUploadToS3 } from '@apiServices'
import {
  Alert,
  ButtonLink,
  CheckboxInput,
  Col,
  DownloadIcon,
  LoadingIndicator,
  Row,
  Stacked,
  Text,
  Toggle,
} from '@components'
import { FileUploadResult } from '@custom-types'
import { FileSelector } from '@new-components'
import { areFilesDifferent } from '@utils'
import { useCallback, useEffect, useState } from 'react'

interface EventVisibilityOptionsProps {
  isPrivate: boolean
  onIsPrivateToggled: () => void
  isEmailRequired: boolean
  isWalletRequired: boolean
  uploadedFileName: string | null
  isPendingValidation: boolean
  uploadedData: any
  uploadFileErrorMessage: string | null
  onEmailRequiredToggled: () => void
  onWalletRequiredToggled: () => void
  onFileUploaded: (data: FileUploadResult) => void
  onFileRemoved: () => void
}

export const EventVisibilityOptions: React.FC<EventVisibilityOptionsProps> = ({
  isPrivate,
  onIsPrivateToggled,
  isEmailRequired,
  isWalletRequired,
  uploadedFileName,
  isPendingValidation,
  uploadedData,
  uploadFileErrorMessage,
  onEmailRequiredToggled,
  onWalletRequiredToggled,
  onFileUploaded,
  onFileRemoved,
}) => {
  const [file, setFile] = useState<Maybe<File>>(null)
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const {
    mutateAsync: uploadFile,
    isPending: isUploadingFile,
    data: uploadedFileResult,
  } = useUploadToS3()

  const handleFileChange = useCallback(
    (files: File[]) => {
      if (files.length === 0) {
        setFile(null)
        onFileRemoved()
        return
      }

      const newFile = files[0]

      if (file !== null && !areFilesDifferent(file, newFile)) {
        return
      }

      setFile(newFile)
      setIsFileUploaded(false)

      void uploadFile({
        file: newFile,
        isPublicResource: false,
      })
    },
    [file, uploadFile, onFileRemoved, setIsFileUploaded, setFile],
  )

  useEffect(() => {
    if (
      isUploadingFile ||
      !uploadedFileResult ||
      isFileUploaded ||
      file === null
    )
      return

    setIsFileUploaded(true)
    onFileUploaded({
      filename: file.name,
      contentType: file.type,
      httpsUrl: uploadedFileResult.fileUrl,
      s3Uri: uploadedFileResult.s3Uri,
      file: file,
    })
  }, [
    uploadedFileResult,
    isFileUploaded,
    onFileUploaded,
    file,
    isUploadingFile,
  ])

  return (
    <Stacked>
      <Row yalign='center' gap={3}>
        <Toggle checked={isPrivate} onChange={onIsPrivateToggled} />
        <Text className='text-md font-medium'>
          {isPrivate ? 'Private' : 'Public'}
        </Text>
      </Row>
      {isPrivate && (
        <>
          <Row yalign='start' xalign='between'>
            <Col className='w-3/4'>
              <Text className='font-bold'>Do you have a template?</Text>
              <Text>
                Use this template to create your CSV file. You may select
                whether you want to require either a wallet address, email
                address, or both.
              </Text>
            </Col>
            <ButtonLink
              fill='outline'
              href={
                'https://s3.amazonaws.com/media.tokensoft.io/private_event_participants.csv'
              }
              target={'blank'}
            >
              <Text>Download Template</Text>
              <DownloadIcon />
            </ButtonLink>
          </Row>
          <Col>
            <CheckboxInput
              name='email-required'
              checked={isEmailRequired}
              onClick={onEmailRequiredToggled}
            >
              Require Email?
            </CheckboxInput>
            <CheckboxInput
              name='wallet-required'
              checked={isWalletRequired}
              onClick={onWalletRequiredToggled}
            >
              Require Wallet Address?
            </CheckboxInput>
          </Col>
          <Col gap={2} xalign='start'>
            <Text className='font-semibold'>
              Upload your participants list*
            </Text>
            <FileSelector
              files={file ? [file] : []}
              onFileChange={handleFileChange}
              allowMultiple={false}
              allowedFileExtensions={['csv']}
            />
            {uploadedFileName && (
              <Text className='text-sm'>{uploadedFileName}</Text>
            )}
            {isPendingValidation && (
              <Row xalign='center'>
                <LoadingIndicator
                  text={'Verifying participants list'}
                  className={'no-padding'}
                />
              </Row>
            )}
          </Col>
          {!isPendingValidation && !uploadedData && uploadFileErrorMessage && (
            <Alert type='alert-danger'>{uploadFileErrorMessage}</Alert>
          )}
        </>
      )}
    </Stacked>
  )
}

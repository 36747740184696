import { useState, useEffect } from 'react'

interface UseStepperProps {
  totalSteps: number
  startsAtIndex?: number
  onCancel?: () => void
  onFinished?: () => void
}

export const useStepper = ({
  totalSteps,
  startsAtIndex = 0,
  onCancel,
  onFinished,
}: UseStepperProps) => {
  const [step, setStep] = useState(startsAtIndex)
  const [nextHidden, setNextHidden] = useState(false)

  const goToNextStep = () => {
    if (step < totalSteps - 1) {
      setStep(step + 1)
    } else {
      onFinished?.()
    }
  }

  const goToPreviousStep = () => {
    if (step === startsAtIndex) {
      onCancel?.()
    } else {
      setStep(step - 1)
    }
  }

  useEffect(() => {
    setNextHidden(step === totalSteps - 1)
  }, [step, totalSteps])

  useEffect(() => {
    if (startsAtIndex < 0 || startsAtIndex >= totalSteps) {
      console.warn(
        `Invalid startsAtIndex value: ${startsAtIndex}. Falling back to step 0.`,
      )
      setStep(0)
    }
  }, [startsAtIndex, totalSteps])

  return {
    step,
    setStep,
    nextHidden,
    goToNextStep,
    goToPreviousStep,
  }
}

import { removeSessionStorageKey } from '@apiClients'
import { AuthContext } from '@contexts'
import { User } from '@custom-types'
import { useSentryContext, useUserStore } from '@hooks'
import { usePrivy } from '@privy-io/react-auth'
import { jwtDecode } from 'jwt-decode'
import { FC, ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface AuthProviderProps {
  children?: ReactNode
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useUserStore()
  const { authenticated, logout, ready } = usePrivy()
  const navigate = useNavigate()

  // Persist user context for Sentry error tracking
  useSentryContext(user)

  const disconnect = () => {
    void logout()
    removeSessionStorageKey()
    setUser(null)
    // Remove path and set to /
    navigate('/')
  }

  useEffect(() => {
    if (user === null && ready && authenticated) {
      void logout()
    }

    if (user) {
      if (ready && !authenticated) {
        // we likely encounter this state when the session storage has been
        // received from another tab, which would mean that the session
        // information from Privy has also been received.
        //
        // if that is the case, we can just reload the page and Privy will see
        // the Privy session data and then `authenticated` will be true
        //
        // However, we have seen a bug where users will get stuck in a loop
        // where the page is refreshing over and over, which means that there
        // is still a possibility of encountering this state when the Privy
        // session information is not valid enough to authenticate the user.
        //
        // So if we want to do window.location.reload() here, we need to first
        // check the Privy session information to predict that Privy will
        // successfully find an authenticated session to work with.
        //
        // Until we know how to do that, we will just erase the TokenSoft
        // session data, which might interrupt other tabs that are open, but at
        // least that is much better than the page getting stuck in a refresh
        // loop
        // TODO: it seems that there is a race condition where this condition
        //       is reached after logging in, when the JWT/session storage is
        //       updated before privy switches to the 'authenticated' state
        // removeSessionStorageKey()
        // setUser(null)
      } else {
        const decodedToken = jwtDecode(user.token)

        if ((decodedToken.exp ?? 0) < Math.floor(Date.now() / 1000)) {
          disconnect()
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <>
      <AuthContext.Provider
        value={{
          // TODO: Remove the `as User` cast once the user store is properly typed
          user: user || ({} as User),
          disconnect,
          isAuthenticated: !!user && authenticated,
          authenticate: setUser,
          removeWalletAddress: (walletAddress: EvmAddress) => {
            if (!user) return

            setUser({
              ...user,
              walletAddresses: user.walletAddresses.filter(
                (wa) => wa.walletAddress !== walletAddress,
              ),
            })
          },
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  )
}

import { useQueries } from '@tanstack/react-query'
import axios from 'axios'

export const useGetDomainStatuses = (projectDomains: string[]) => {
  const domainStatuses = useQueries({
    queries: projectDomains.map((domainName) => ({
      queryKey: ['status', domainName],
      queryFn: () =>
        axios.get(
          import.meta.env.PROCESS_ENV === 'development'
            ? `http://${domainName}/up.json`
            : `https://${domainName}/up.json`,
        ),
    })),
  }).map((result) => result?.data)

  return { domainStatuses }
}

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

export const HeaderButton = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, className, ...buttonProps }, ref) => {
  return (
    <button
      ref={ref}
      className={twMerge(
        'rounded-full h-12 w-12 flex items-center justify-center hover:opacity-80',
        className,
      )}
      type='button'
      {...buttonProps}
    >
      {children}
    </button>
  )
})

HeaderButton.displayName = 'HeaderButton'

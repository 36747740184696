import BigNumber from 'bignumber.js'
import { roundInDigitRange } from 'tokensoft-shared-types'

type FormattedNumberProps = {
  value: BigNumber
  displayDecimals: number
  withCommas?: boolean
  trimTrailingZeros?: boolean
  minDecimals?: number
  maxDecimals?: number
  roundingMode?: BigNumber.RoundingMode
}

/**
 * Formats a BigNumber into a human-readable string with specified decimal places and optional commas.
 * Applies dynamic rounding within a digit range for better UX with small values.
 */
export const FormattedNumber: React.FC<FormattedNumberProps> = ({
  value,
  displayDecimals,
  withCommas = true,
  trimTrailingZeros = true,
  minDecimals = 2,
  maxDecimals = 6,
  roundingMode = BigNumber.ROUND_UP,
}) => {
  const rounded = roundInDigitRange(
    value,
    minDecimals,
    maxDecimals,
    roundingMode,
  )
  let formattedNumber = rounded.toFixed(displayDecimals)

  if (withCommas) {
    const [integerPart, fractionalPart] = formattedNumber.split('.')
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    formattedNumber =
      fractionalPart !== undefined
        ? `${formattedInteger}.${fractionalPart}`
        : formattedInteger
  }

  if (trimTrailingZeros) {
    formattedNumber = formattedNumber
      .replace(/(\.\d*?)0+$/, '$1')
      .replace(/\.$/, '')
  }

  return <span>{formattedNumber}</span>
}

import { LogOutIcon, NavHeader, NotificationPopover } from '@components'
import { useAuth, useTSWagmi, useWallet } from '@contexts'
import { useClipboard, useTSAddress } from '@hooks'
import { FormattedCurrency } from '@new-components'
import { usePrivy, useWallets } from '@privy-io/react-auth'
import { getTruncatedEvmAddress } from '@utils'
import BigNumber from 'bignumber.js'
import { useEffect, useMemo } from 'react'
import { Blockchain } from 'tokensoft-shared-types'
import { useBalance } from 'wagmi'
import { ChainSelector } from './chain-selector'
import { HeaderButton } from './header-button'
import { HeaderGroup } from './header-group'
import './header.css'
import { AccountMenu } from './account-menu'

interface HeaderProps {
  chains: Blockchain[]
}

export const Header = ({ chains }: HeaderProps) => {
  const { isAuthenticated, disconnect } = useAuth()
  const { address: userWalletAddress, chainId } = useTSAddress()
  const { data: balance } = useBalance({
    address: userWalletAddress,
  })
  const { activeChains } = useTSWagmi()
  const { authenticated, ready, user } = usePrivy()
  const { wallets } = useWallets()
  const { switchChain } = useWallet()
  const currentChain = chains.find((chain) => chain.id === chainId)

  const { copyToClipboard } = useClipboard()

  const handleCopy = () => {
    if (userWalletAddress === undefined) return
    copyToClipboard(userWalletAddress)
  }

  useEffect(() => {
    console.log('INFO: Header-useEffect() called')
    console.log({ chainId })
    console.log({ activeChains })
  }, [chainId, activeChains])

  /**
   * Handle disconnect events
   */
  const handleDisconnect = async () => {
    try {
      console.log('INFO: Header-handleDisconnect() called')
      // console.log('provider: disconnecting...');
      // First disconnect any non-injected wallets
      for (const wallet of wallets) {
        try {
          await (wallet.disconnect() as unknown as Promise<void>)
        } catch (e) {
          console.error(`Failed to disconnect wallet ${wallet.address}:`, e)
        }
      }
      disconnect()
    } catch (e) {
      console.log({ e })
    }
  }

  const parsedBalance: CurrencyValue = useMemo(() => {
    if (!balance) {
      return {
        value: new BigNumber(0),
        symbol: '--',
      }
    }

    const balanceRaw = new BigNumber(balance?.value.toString())
    const balanceValue = balanceRaw.dividedBy(
      new BigNumber(10).pow(balance.decimals),
    )
    return {
      value: balanceValue,
      symbol: balance.symbol,
    }
  }, [balance])

  return (
    <div className='fixed w-full z-10'>
      <NavHeader />
      {isAuthenticated && (
        <div
          className='flex justify-end items-center gap-5 h-[60px] pr-5 bg-[#eff3ff]'
          data-testid='authenticated-header'
        >
          <HeaderGroup className='select-none font-medium text-black hidden sm:flex'>
            {balance && (
              <FormattedCurrency
                currencyValue={parsedBalance}
                displayDecimals={4}
                trimTrailingZeros
              />
            )}
            <button
              className='rounded-full h-8 bg-[#eff3ff] flex items-center justify-center py-2 px-4'
              onClick={handleCopy}
            >
              {userWalletAddress && getTruncatedEvmAddress(userWalletAddress)}
            </button>

            {user?.wallet?.walletClientType === 'privy' && <AccountMenu />}
          </HeaderGroup>
          <ChainSelector
            chains={chains}
            currentChainId={currentChain?.id ?? -1}
            switchChain={switchChain}
          />
          <HeaderGroup className='bg-[#eff3ff] pl-0'>
            <NotificationPopover />
            <div className='nav-item'>
              {authenticated && (
                <HeaderButton
                  data-testid='disconnect-button'
                  className='bg-[#d01010]'
                  onClick={() => {
                    void handleDisconnect()
                  }}
                  disabled={!ready}
                >
                  {/* Icon shape makes it seem off-center. Adjusting to make it appear more centered. */}
                  <span className='relative left-[1.5px]'>
                    <LogOutIcon />
                  </span>
                </HeaderButton>
              )}
            </div>
          </HeaderGroup>
        </div>
      )}
    </div>
  )
}

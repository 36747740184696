import {
  useGetPolkadotWalletVerificationNonce,
  usePostPolkadotWalletVerificationSignature,
} from '@apiServices'
import { useToast } from '@contexts'
import {
  web3Accounts,
  web3Enable,
  web3FromSource,
} from '@polkadot/extension-dapp'
import * as Sentry from '@sentry/react'
import { useCallback, useState } from 'react'

interface UsePolkadotWalletVerificationProps {
  requiredWalletAddressMatch: Maybe<SS58Address>
  onVerified: () => void
}

type InjectedWallet = Awaited<ReturnType<typeof web3Accounts>>[number]
type InjectedExtension = Awaited<ReturnType<typeof web3Enable>>[number]

export const usePolkadotWalletVerification = ({
  requiredWalletAddressMatch,
  onVerified,
}: UsePolkadotWalletVerificationProps) => {
  const [availableAccounts, setAvailableAccounts] =
    useState<Maybe<InjectedWallet[]>>(null)
  const [noMatchingWallet, setNoMatchingWallet] = useState(false)
  const [connectedAccount, setConnectedAccount] =
    useState<Maybe<InjectedWallet>>(null)
  const [isConnectingWallet, setIsConnectingWallet] = useState(false)
  const [isDoingChallenge, setIsDoingChallenge] = useState(false)

  const { data: nonce } = useGetPolkadotWalletVerificationNonce(
    connectedAccount?.address ?? null,
  )

  const { mutate: verifySignature, isPending: isPendingVerificationResult } =
    usePostPolkadotWalletVerificationSignature()

  const { showErrorToast, showSuccessToast } = useToast()

  /** Initialize list of SS58 extensions as a prerequisite for wallet connection request */
  const loadSS58Extensions = useCallback(async (): Promise<
    InjectedExtension[]
  > => {
    try {
      return web3Enable(window.origin)
    } catch (error) {
      Sentry.captureException(error)
      showErrorToast({ description: 'Failed to enable Polkadot extensions.' })
      return []
    }
  }, [showErrorToast])

  /** Get all accounts from extensions and select one that matches requirement address,
   * if specific address is required. Otherwise, just take first one.
   */
  const getRequiredAccount = useCallback(async (): Promise<
    Maybe<InjectedWallet>
  > => {
    const accounts = await web3Accounts()
    if (accounts.length > 0) {
      const requiredAccount =
        accounts.find((account) =>
          requiredWalletAddressMatch
            ? account.address === requiredWalletAddressMatch
            : true,
        ) ?? null

      setAvailableAccounts(accounts)
      return requiredAccount
    } else {
      showErrorToast({
        description: 'No accounts found in Polkadot extensions.',
      })
      return null
    }
  }, [requiredWalletAddressMatch, showErrorToast, setAvailableAccounts])

  const connectPolkadotWallet = useCallback(async () => {
    reset()
    setIsConnectingWallet(true)
    try {
      const extensions = await loadSS58Extensions()
      if (extensions.length === 0) {
        return
      }
      const requiredAccount = await getRequiredAccount()
      if (requiredAccount !== null) {
        setConnectedAccount(requiredAccount)
        setNoMatchingWallet(false)
      } else {
        setNoMatchingWallet(true)
      }
    } catch (error) {
      Sentry.captureException(error)
      showErrorToast({ description: 'Failed to connect Polkadot wallet.' })
    } finally {
      setIsConnectingWallet(false)
    }
  }, [
    showErrorToast,
    getRequiredAccount,
    loadSS58Extensions,
    setIsConnectingWallet,
  ])

  const startChallenge = useCallback(async () => {
    if (!connectedAccount) {
      showErrorToast({ description: 'No connected account found.' })
      return
    }

    if (!nonce) {
      showErrorToast({ description: 'Failed to retrieve nonce for signing.' })
      return
    }

    setIsDoingChallenge(true)
    try {
      if (!connectedAccount) {
        showErrorToast({
          description: 'Connected account not found in wallet.',
        })
        return
      }

      const injector = await web3FromSource(connectedAccount.meta.source)
      const { signer } = injector

      if (!signer.signRaw) {
        showErrorToast({
          description: 'Polkadot signer does not support raw signatures.',
        })
        return
      }

      const { signature } = await signer.signRaw({
        address: connectedAccount.address,
        data: nonce,
        type: 'bytes',
      })

      verifySignature(
        { polkadotAddress: connectedAccount.address, signature },
        {
          onSuccess: () => {
            showSuccessToast({ description: 'Wallet verified successfully!' })
            onVerified()
          },
          onError: (error) => {
            Sentry.captureException(error)
            showErrorToast({ description: 'Failed to verify wallet.' })
          },
        },
      )
    } catch (error) {
      Sentry.captureException(error)
      showErrorToast({ description: 'Error during challenge signing process.' })
    } finally {
      setIsDoingChallenge(false)
    }
  }, [
    connectedAccount,
    nonce,
    verifySignature,
    showErrorToast,
    showSuccessToast,
    onVerified,
  ])

  const reset = () => {
    setConnectedAccount(null)
    setAvailableAccounts(null)
    setNoMatchingWallet(false)
    setIsConnectingWallet(false)
    setIsDoingChallenge(false)
  }

  return {
    availableAccounts,
    connectedAccount,
    noMatchingWallet,
    isConnectingWallet,
    isDoingChallenge,
    isPendingVerificationResult,
    connectPolkadotWallet,
    startChallenge,
    reset,
  }
}

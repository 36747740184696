import {
  useGetChain,
  useGetDistributorByContractAddress,
  useGetMyDistributorMerkleLeaf,
} from '@apiServices'
import { Text } from '@components'
import { useToast } from '@contexts'
import {
  CollapsibleCard,
  EventPropertyRow,
  FormattedCurrency,
} from '@newComponents'
import {
  capitalize,
  getBlockExplorerTokenUrl,
  getTruncatedAddress,
} from '@utils'
import { getVestingDuration } from '@utils'
import { useEffect } from 'react'
import { VscLinkExternal } from 'react-icons/vsc'

type DistributionDetailsCardProps = {
  className?: string
  distributorAddress: EvmAddress
}

export const DistributionDetailsCard = ({
  className = '',
  distributorAddress,
}: DistributionDetailsCardProps) => {
  const { showErrorToast } = useToast()
  const {
    data: distributor,
    isPending: isDistributorPending,
    error: distributorError,
  } = useGetDistributorByContractAddress(distributorAddress)

  const {
    data: chain,
    isPending: isChainPending,
    error: chainError,
  } = useGetChain(distributor?.chainId)

  const {
    data: merkleLeaf,
    isPending: isMerkleLeafPending,
    error: merkleLeafError,
  } = useGetMyDistributorMerkleLeaf(
    distributorAddress,
    distributor?.vestingType,
  )

  const error = distributorError || chainError || merkleLeafError

  useEffect(() => {
    if (error) {
      showErrorToast({ description: error.message })
    }
  }, [showErrorToast, error])

  if (isDistributorPending || isChainPending || isMerkleLeafPending) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error loading distribution data.</div>
  }

  return (
    <CollapsibleCard cardTitle='Distribution Details' className={className}>
      <div className='grid grid-cols-2 w-full gap-4 sm:gap-x-16 md:gap-x-32 mt-4'>
        <EventPropertyRow
          label='Token Address'
          value={
            <>
              <a
                target='_blank'
                href={
                  ['airdrop.shardeum.org', 'shardeum.tokensoft.io'].includes(
                    window.location.hostname,
                  ) && window.location.pathname.includes('/event/3239')
                    ? 'https://shardeum.org/'
                    : getBlockExplorerTokenUrl(chain, distributor.token.address)
                }
              >
                <Text className='flex whitespace-nowrap items-center gap-1 text-primary-medium'>
                  {getTruncatedAddress(distributor.token.address)}{' '}
                  <VscLinkExternal size={14} />
                </Text>
              </a>
            </>
          }
        />
        <EventPropertyRow
          label='Total Allocations'
          value={
            <FormattedCurrency
              currencyValue={{
                symbol: distributor.token.symbol,
                value: distributor.totalAllocation.div(
                  10 ** distributor.token.decimals,
                ),
              }}
              displayDecimals={0}
            />
          }
        />
        {merkleLeaf?.vestingSchedule && (
          <EventPropertyRow
            label='Vesting Duration'
            value={getVestingDuration(merkleLeaf.vestingSchedule)}
          />
        )}
        <EventPropertyRow
          label='Vesting Type'
          value={capitalize(distributor.vestingType)}
        />
        <EventPropertyRow label='Chain' value={chain?.name} />
      </div>
    </CollapsibleCard>
  )
}

import { forwardRef, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { Blockchain } from 'tokensoft-shared-types'
import { ChainDropdownOption } from './chain-dropdown-option'

interface ChainsDropdownProps {
  className?: string
  chains: Blockchain[]
  activeChainId: number
  onChange: (selected: number) => void
}

export const ChainsDropdown = forwardRef<HTMLOListElement, ChainsDropdownProps>(
  ({ className, chains, activeChainId, onChange, ...props }, ref) => {
    const alphabetizedChains = useMemo(() => {
      const chainsCopy = [...chains]
      return chainsCopy.sort((a, b) => a.name.localeCompare(b.name))
    }, [chains])

    return (
      <ol
        ref={ref}
        className={twMerge(
          `absolute top-full -left-12 sm:right-0 sm:left-auto py-2 flex flex-col 
          gap-1 items-start max-w-[400px] w-[280px] rounded-md shadow-lg bg-white 
          ring-1 ring-black ring-opacity-5 max-h-[calc(100vh_-_65px)] overflow-y-auto`,
          className,
        )}
        {...props}
      >
        {alphabetizedChains.map((chain, i) => (
          <ChainDropdownOption
            key={i}
            chainIconUrl={chain.logoUri}
            chainName={chain.name}
            onClick={() => onChange(chain.id)}
            isActive={chain.id === activeChainId}
          />
        ))}
      </ol>
    )
  },
)

ChainsDropdown.displayName = 'ChainsDropdown'

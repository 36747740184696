import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const usePagination = (
  initialPageSize = 15,
  pageKey: Maybe<string> = null,
) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const initialPageNumber = Number(searchParams.get('page')) || 1
  const initialUrlPageSize =
    Number(searchParams.get('pageSize')) || initialPageSize

  // Allows persistent pagination states for different keys, eg filters
  const [paginationStates, setPaginationStates] = useState(
    new Map<Maybe<string>, { pageNumber: number; pageSize: number }>(),
  )

  // Retrieve or initialize pagination state for the current pageKey
  const getPaginationState = useCallback(
    () =>
      paginationStates.get(pageKey) || {
        pageNumber: initialPageNumber,
        pageSize: initialUrlPageSize,
      },
    [pageKey, paginationStates, initialPageNumber, initialUrlPageSize],
  )

  const {
    pageNumber: initialPageNumberForKey,
    pageSize: initialPageSizeForKey,
  } = getPaginationState()

  const [pageNumber, setPageNumber] = useState(initialPageNumberForKey)
  const [pageSize, setPageSize] = useState(initialPageSizeForKey)

  // Update state when pageKey changes
  useEffect(() => {
    const { pageNumber, pageSize } = getPaginationState()
    setPageNumber(pageNumber)
    setPageSize(pageSize)
  }, [pageKey, getPaginationState])

  // Save pagination state when values change
  useEffect(() => {
    setPaginationStates((prev) => {
      const newState = new Map(prev)
      newState.set(pageKey, { pageNumber, pageSize })
      return newState
    })
  }, [pageKey, pageNumber, pageSize])

  // Sync with URL search params
  useEffect(() => {
    const newParams = new URLSearchParams(searchParams)
    newParams.set('page', String(pageNumber))
    newParams.set('pageSize', String(pageSize))
    setSearchParams(newParams)
  }, [pageNumber, pageSize, setSearchParams, searchParams])

  const fetchNextPage = useCallback(() => {
    setPageNumber((prevPage) => prevPage + 1)
  }, [])

  const fetchPreviousPage = useCallback(() => {
    setPageNumber((prevPage) => Math.max(prevPage - 1, 1))
  }, [])

  const resetPagination = useCallback(() => {
    setPageNumber(1)
  }, [])

  return {
    pageSize,
    pageNumber,
    setPageSize,
    setPageNumber,
    fetchNextPage,
    fetchPreviousPage,
    hasPreviousPage: pageNumber > 1,
    resetPagination,
  }
}

import { useAdminApiClient } from '@api/clients'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

export const useGetCertificate = (projectDomainId: string) => {
  const client = useAdminApiClient()
  const queryClient = useQueryClient()
  const result = useQuery({
    enabled: false,
    queryKey: ['certificate', projectDomainId],
    queryFn: () => client(`domains/${projectDomainId}/certificate`),
  })

  useEffect(() => {
    void queryClient.invalidateQueries({ queryKey: ['project'] })
  }, [result.isSuccess, queryClient])

  return result
}

import {
  useDeletePrivateEventMember,
  usePutPrivateEventMember,
} from '@apiServices'
import { Button, Col, Row, Section, SectionTitle, Text } from '@components'
import { useToast } from '@contexts'
import { Card, TextInput } from '@new-components'
import { useQueryClient } from '@tanstack/react-query'
import { validateEmail } from '@utils'
import { validateEthereumWallet } from '@utils/form-validation'
import { useForm } from 'react-hook-form'

interface PrivateEventMemberEditorProps {
  eventId: number
}

interface FormData {
  email: Maybe<EmailAddress>
  wallet: Maybe<EvmAddress>
}

export const PrivateEventMemberEditor = ({
  eventId,
}: PrivateEventMemberEditorProps) => {
  const { showSuccessToast } = useToast()
  const queryClient = useQueryClient()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: { email: null, wallet: null },
    mode: 'onBlur',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
  })

  const { mutateAsync: addMember, isPending: isAdding } =
    usePutPrivateEventMember()
  const { mutateAsync: removeMember, isPending: isRemoving } =
    useDeletePrivateEventMember()

  const handleRemove = async (data: FormData) => {
    const success = await removeMember({
      eventId,
      member: { email: data.email, wallet: data.wallet },
    })
    if (success) {
      showSuccessToast({ description: 'Member removed successfully' })
      reset()
    }
  }

  const handleAdd = async (data: FormData) => {
    const success = await addMember({
      eventId,
      member: { email: data.email, wallet: data.wallet },
    })

    if (success) {
      showSuccessToast({ description: 'Member added successfully' })
      reset()
    }
  }

  return (
    <Card className='m-2'>
      <Section>
        <SectionTitle>Add/Remove Individual Members</SectionTitle>
        <form>
          <Row gap={2}>
            <Col>
              <TextInput
                id='email'
                label='Email'
                isRequired={false}
                register={register}
                validate={(value) => {
                  if (!value) {
                    return true
                  }

                  if (!validateEmail(value)) {
                    return 'Invalid email'
                  }

                  return true
                }}
                placeholder='Enter email'
              />
              {errors.email && (
                <Text className='text-red-500'>{errors.email.message}</Text>
              )}
            </Col>
            <Col>
              <TextInput
                id='wallet'
                label='Wallet'
                register={register}
                isRequired={false}
                validate={(value) => {
                  if (!value) {
                    return true
                  }

                  if (!validateEthereumWallet(value)) {
                    return 'Invalid Ethereum address'
                  }

                  return true
                }}
                placeholder='Enter wallet address'
              />
              {errors.wallet && (
                <Text className='text-red-500'>{errors.wallet.message}</Text>
              )}
            </Col>
          </Row>
          <Row gap={2}>
            <Button
              type='button'
              onClick={handleSubmit(handleAdd) as () => void}
              disabled={isAdding}
            >
              {isAdding ? 'Adding...' : 'Add Member'}
            </Button>
            <Button
              type='button'
              onClick={handleSubmit(handleRemove) as () => void}
              fill='outline'
              disabled={isRemoving}
            >
              {isRemoving ? 'Removing...' : 'Remove Member'}
            </Button>
          </Row>
        </form>
      </Section>
    </Card>
  )
}

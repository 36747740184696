export const NotificationIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2170_48598)'>
        <path
          d='M8.33203 4.16667C8.33203 3.72464 8.50763 3.30072 8.82019 2.98816C9.13275 2.67559 9.55667 2.5 9.9987 2.5C10.4407 2.5 10.8646 2.67559 11.1772 2.98816C11.4898 3.30072 11.6654 3.72464 11.6654 4.16667C12.6224 4.61919 13.4382 5.32361 14.0254 6.20442C14.6126 7.08523 14.9491 8.10923 14.9987 9.16667V11.6667C15.0614 12.1848 15.2449 12.6809 15.5344 13.1151C15.8239 13.5493 16.2112 13.9095 16.6654 14.1667H3.33203C3.78615 13.9095 4.17354 13.5493 4.46302 13.1151C4.7525 12.6809 4.93599 12.1848 4.9987 11.6667V9.16667C5.04833 8.10923 5.38478 7.08523 5.97199 6.20442C6.5592 5.32361 7.37502 4.61919 8.33203 4.16667Z'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.5 14.166V14.9993C7.5 15.6624 7.76339 16.2983 8.23223 16.7671C8.70107 17.236 9.33696 17.4993 10 17.4993C10.663 17.4993 11.2989 17.236 11.7678 16.7671C12.2366 16.2983 12.5 15.6624 12.5 14.9993V14.166'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2170_48598'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

import { IconProps } from './icon-props'

export const ClaimCompleteIcon = ({
  color,
  className,
  size = 96,
}: IconProps) => {
  return (
    <div className={`${className && className}`}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 96 96'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='96' height='96' rx='48' fill='#E8FDF2' />
        <g clip-path='url(#clip0_822_16092)'>
          <path
            d='M43.9991 65.5532C42.2583 65.1584 40.5864 64.5051 39.0391 63.6152'
            stroke='#0D914F'
            stroke-width='3'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M52 30.4453C55.9763 31.3535 59.5265 33.5847 62.0693 36.7737C64.6121 39.9628 65.9969 43.9206 65.9969 47.9993C65.9969 52.078 64.6121 56.0359 62.0693 59.2249C59.5265 62.4139 55.9763 64.6452 52 65.5533'
            stroke='#0D914F'
            stroke-width='3'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M33.1571 58.186C32.0661 56.6009 31.2375 54.8505 30.7031 53.002'
            stroke='#0D914F'
            stroke-width='3'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M30.25 44.9991C30.57 43.0991 31.186 41.2991 32.05 39.6491L32.388 39.0391'
            stroke='#0D914F'
            stroke-width='3'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M37.8125 33.1573C39.6838 31.8691 41.7831 30.9487 43.9985 30.4453'
            stroke='#0D914F'
            stroke-width='3'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M42 48L46 52L54 44'
            stroke='#0D914F'
            stroke-width='3'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_822_16092'>
            <rect
              width='48'
              height='48'
              fill='white'
              transform='translate(24 24)'
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

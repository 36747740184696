import { useToast } from '@contexts'
import {
  useApproveErc20Transfer,
  useBuyWithToken,
  useErc20Allowance,
  useTSAddress,
} from '@hooks'
import { LoaderButton, StatusBox } from '@newComponents'
import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { Blockchain, SaleSubgraphPaymentMethod } from 'tokensoft-shared-types'
import { BaseError, TransactionReceipt } from 'viem'
import { PurchaseAmountMode } from '../../../../../../../types/src/types/util/PurchaseAmountMode'

interface TokenPurchaseButtonProps {
  saleAddress: EvmAddress
  chain: Blockchain
  purchaseAmountInToken: CurrencyValue
  paymentMethod: SaleSubgraphPaymentMethod
  platformFeeRecipient: Maybe<EvmAddress>
  platformFeeInDollars: CurrencyValue
  platformFeeInNative: CurrencyValue
  /** The number of decimals in the chain native token */
  nativeDecimals: number
  onSuccess: (receipt: TransactionReceipt) => void
  disabled: boolean
  className?: string
  purchaseAmountMode: PurchaseAmountMode
}

export const TokenPurchaseButton = ({
  saleAddress,
  chain,
  purchaseAmountInToken,
  paymentMethod,
  platformFeeRecipient,
  platformFeeInDollars,
  platformFeeInNative,
  nativeDecimals,
  onSuccess,
  disabled,
  className = '',
  purchaseAmountMode,
}: TokenPurchaseButtonProps) => {
  const { showErrorToast } = useToast()

  const {
    approveErc20Transfer,
    isPending: isPendingErc20TransferApproval,
    receipt: approveErc20TranferReceipt,
    error: approveErc20TransferError,
  } = useApproveErc20Transfer()

  const { address } = useTSAddress()

  // Convert ERC20 purchase amount to wei based on token decimals.
  // Can't pass decimals into contract, so round up to make sure the value
  // remains above the required platform fee.
  const requiredAmountWei = purchaseAmountInToken.value
    .dividedBy(
      new BigNumber(
        purchaseAmountMode === 'user-enters-contribution-amount' ? 0.99 : 1,
      ),
    )
    .times(10 ** paymentMethod.decimals)
    .decimalPlaces(0, BigNumber.ROUND_UP)

  const {
    allowance,
    isLoading: isAllowanceLoading,
    error: allowanceError,
    refetch: refetchAllowance,
  } = useErc20Allowance(
    chain.id,
    paymentMethod.token || '0x',
    address || '0x',
    saleAddress,
  )

  const isApprovalNeeded =
    !isAllowanceLoading && allowance.lt(requiredAmountWei)

  const {
    buyWithToken,
    receipt: buyWithTokenReceipt,
    isPending: isPendingBuyWithToken,
    error: buyWithTokenError,
  } = useBuyWithToken(chain.id, saleAddress)

  const handleApprove = async () => {
    if (!paymentMethod.token) {
      throw new Error('Payment method address is missing')
    }

    await approveErc20Transfer(
      chain.id,
      paymentMethod.token,
      saleAddress,
      requiredAmountWei,
    )
  }

  const handleBuy = async () => {
    if (!paymentMethod.token) {
      throw new Error('Payment method address is missing')
    }

    // Platform fee in native is always paid as `value` in the transaction.
    // Round up to make sure the value remains above the required platform fee.
    const platformFeeInNativeWei = platformFeeInNative.value
      .times(10 ** nativeDecimals)
      .decimalPlaces(0, BigNumber.ROUND_UP)

    await buyWithToken(
      paymentMethod.token,
      requiredAmountWei,
      platformFeeRecipient,
      platformFeeInDollars.value,
      platformFeeInNativeWei,
    )
  }

  useEffect(() => {
    if (buyWithTokenError) {
      showErrorToast({
        description:
          buyWithTokenError instanceof BaseError
            ? buyWithTokenError.shortMessage
            : buyWithTokenError.message,
      })
    }

    if (approveErc20TransferError) {
      showErrorToast({
        description:
          approveErc20TransferError instanceof BaseError
            ? approveErc20TransferError.shortMessage
            : approveErc20TransferError.message,
      })
    }
  }, [
    buyWithTokenError,
    approveErc20TranferReceipt,
    showErrorToast,
    approveErc20TransferError,
  ])

  useEffect(() => {
    if (buyWithTokenReceipt) {
      onSuccess(buyWithTokenReceipt)
    }
  }, [buyWithTokenReceipt, onSuccess])

  useEffect(() => {
    if (allowanceError) {
      showErrorToast({ description: allowanceError.message })
    }
  }, [allowanceError, showErrorToast])

  useEffect(() => {
    if (approveErc20TranferReceipt) {
      void refetchAllowance()
    }
  }, [approveErc20TranferReceipt, refetchAllowance])

  return (
    <>
      <div className='flex gap-4 justify-center'>
        {isAllowanceLoading ? (
          <LoaderButton
            className={className}
            disabled={disabled}
            isLoading={true}
            loadingText='Checking Allowance...'
            text='Please wait'
          />
        ) : isApprovalNeeded ? (
          <LoaderButton
            className={className}
            disabled={disabled}
            onClick={handleApprove}
            isLoading={isPendingErc20TransferApproval}
            loadingText='Approving...'
            text='Approve Payment Transfer'
          />
        ) : (
          <>
            <LoaderButton
              className={className}
              disabled={disabled}
              onClick={handleBuy}
              isLoading={isPendingBuyWithToken}
              loadingText='Purchasing...'
              text='Confirm Purchase'
            />
            {window.location.hostname === 'tanssi-network.tokensoft.io' && (
              <StatusBox
                status='info'
                text='You will receive a confirmation email within 12 hours of your purchase. If you don’t receive it, please open a ticket in Tanssi’s Discord.'
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

interface CurrentChainLogoProps {
  logoUrl: HttpsUrl
}

export const CurrentChainLogo = ({ logoUrl }: CurrentChainLogoProps) => {
  return (
    <div className='w-6 h-6 flex items-center justify-center bg-[#eff3ff] rounded-full'>
      <img alt='current chain icon' src={logoUrl} />
    </div>
  )
}

import { useAuthApiClient, usePublicApiClient } from '@apiClients'
import { useAuth } from '@contexts'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { UserWalletAddress } from 'tokensoft-shared-types'

export const useCreateReferral = () => {
  const authApiClient = useAuthApiClient()
  const {
    user: { authId, walletAddress },
  } = useAuth()

  return useMutation({
    mutationFn: (referralCode: string) => {
      return authApiClient('referrals', {
        data: {
          userId: authId,
          walletAddress: walletAddress,
          referralCode: referralCode,
        },
      })
    },
  })
}

export const useGetNonce = () => {
  const client = usePublicApiClient()

  return useMutation({
    mutationFn: (walletAddress: string) => {
      return client(`wallets/nonce`, {
        data: { walletAddress },
      })
    },
  })
}

export const useGetToken = () => {
  const client = usePublicApiClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client('wallets/connect', {
        data: data,
      })
    },
  })
}

export const useRefreshToken = () => {
  const client = useAuthApiClient()
  const queryClient = useQueryClient()

  const refreshToken = async () => {
    const data = await queryClient.fetchQuery<{
      token: string
      walletAddresses: UserWalletAddress[]
    }>({
      queryKey: ['refreshToken'],
      queryFn: () => client('token/refresh'),
      staleTime: 0,
    })

    return data
  }

  return { refreshToken }
}
export const useSetPrimaryWalletAddress = () => {
  const client = useAuthApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`wallets/primary/${data.walletAddress}`, {
        method: 'post',
        data: data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  })
}

export const useCheckUserWalletAddress = () => {
  const client = useAuthApiClient()

  const [walletAddress, setWalletAddress] = useState<string>('')

  const result = useQuery({
    queryKey: ['walletAddresses', walletAddress],
    queryFn: () =>
      client(`wallets/check/${walletAddress}`).then((data) => data.status),
    enabled: false,
  })

  useEffect(() => {
    if (result && walletAddress) {
      result.refetch()
    }
  }, [walletAddress])

  return {
    ...result,
    status: result?.data,
    checkUserWalletAddress: setWalletAddress,
  }
}

export const useCreateUserPolkadotWallet = () => {
  const client = useAuthApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`wallets/polkadot`, {
        method: 'post',
        data: data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  })
}

export const useTransferUserWalletAddress = () => {
  const client = useAuthApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`wallets/transfer`, {
        method: 'post',
        data: data,
      })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  })
}

export const useRemoveUserWalletAddress = () => {
  const client = useAuthApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`wallets/${data.id}`, {
        method: 'delete',
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  })
}

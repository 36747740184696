import { useAdminApiClient } from '@api/clients'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useCreateCertificate = () => {
  const client = useAdminApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {
      domainId: string
      validationMethod: DNSCertificateValidationMethod
    }) => {
      return client(`domains/${data.domainId}/certificate`, {
        method: 'post',
        data: {
          validationMethod: data.validationMethod,
        },
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

import { createContext, useContext } from 'react'

export interface LoginOptionsContextProps {
  isEmailLoginEnabled: boolean
  setIsEmailLoginEnabled: (isEmailLoginEnabled: boolean) => void
  isWalletLoginEnabled: boolean
  setIsWalletLoginEnabled: (isWalletLoginEnabled: boolean) => void
}

export const LoginOptionsContext =
  createContext<Maybe<LoginOptionsContextProps>>(null)
LoginOptionsContext.displayName = 'LoginOptionsContext'

export const useLoginOptions = (): LoginOptionsContextProps => {
  const context = useContext(LoginOptionsContext)

  if (!context) {
    throw new Error(
      'LoginOptionsProvider context is undefined, please verify you are calling useLoginOptions() as child of a <LoginOptionsProvider> component.',
    )
  }

  return context
}

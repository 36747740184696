import { EventPropertyRow, FormattedCurrency, StatusBox } from '@newComponents'
import { getTruncatedAddress } from '@utils'
import { twMerge } from 'tailwind-merge'

interface TokenPurchaseSummaryProps {
  className?: string
  userAmount: CurrencyValue
  tokensPurchased: CurrencyValue
  purchaseFees: {
    protocolFeeInPaymentMethodCurrency: CurrencyValue
    platformFeeInNativeCurrency: CurrencyValue
    contributionAmountInPaymentMethodCurrency: CurrencyValue
    totalCostInPaymentMethodCurrency: CurrencyValue
    totalCostInNativeCurrency: CurrencyValue
  }
  recipientWallet: EvmAddress
  isNativePaymentMethod: boolean
}

export const TokenPurchaseSummary = ({
  className,
  tokensPurchased,
  purchaseFees,
  recipientWallet,
  isNativePaymentMethod,
}: TokenPurchaseSummaryProps) => {
  return (
    <div
      className={twMerge(
        className,
        'flex flex-col w-full justify-between gap-2 mx-auto mb-4 border border-gray-300 rounded-md p-4',
      )}
    >
      <EventPropertyRow
        className='w-full'
        label='Network Fee'
        value={
          <FormattedCurrency
            currencyValue={purchaseFees.protocolFeeInPaymentMethodCurrency}
          />
        }
      />
      {window.location.hostname === 'tanssi-network.tokensoft.io' && (
        <StatusBox
          status='info'
          text='Tanssi will initially cover the 1% Network fee, ensuring buyers do not incur additional charges. This amount will be converted into $TANSSI.'
        />
      )}
      <EventPropertyRow
        className='w-full'
        label='Processing Fee'
        value={
          <FormattedCurrency
            currencyValue={purchaseFees.platformFeeInNativeCurrency}
          />
        }
      />
      <EventPropertyRow
        testId='purchase-contribution-amount'
        className='w-full'
        label='Contribution Amount'
        value={
          <span>
            <FormattedCurrency
              currencyValue={
                purchaseFees.contributionAmountInPaymentMethodCurrency
              }
            />
          </span>
        }
      />
      <EventPropertyRow
        className='w-full'
        label='Total Cost'
        value={
          <>
            {isNativePaymentMethod ? (
              <FormattedCurrency
                currencyValue={purchaseFees.totalCostInPaymentMethodCurrency}
              />
            ) : (
              <>
                <FormattedCurrency
                  currencyValue={purchaseFees.totalCostInPaymentMethodCurrency}
                />{' '}
                +{' '}
                <FormattedCurrency
                  currencyValue={purchaseFees.totalCostInNativeCurrency}
                />
              </>
            )}
          </>
        }
      />
      <EventPropertyRow
        className='w-full'
        label='Tokens Purchased'
        value={
          <span>
            ~
            <FormattedCurrency
              currencyValue={tokensPurchased}
              displayDecimals={0}
            />
          </span>
        }
      />
      <EventPropertyRow
        className='w-full'
        label='Recipient Wallet'
        value={getTruncatedAddress(recipientWallet)}
      />
    </div>
  )
}

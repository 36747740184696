import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import { BigNumber } from 'bignumber.js'
import { Claim } from 'tokensoft-shared-types'

export const useGetMyDistributorClaims = (distributorAddress: EvmAddress) => {
  const fetchWithClient = useWebApiClient()

  return useQuery<Claim[]>({
    queryKey: ['distributors', distributorAddress, 'claims'],
    queryFn: async () => {
      const claimsRaw = await fetchWithClient(
        `distributors/${distributorAddress}/claims`,
      )

      const claims: Claim[] = claimsRaw.map((claimRaw: any) => {
        const claim: Claim = {
          amount: new BigNumber(claimRaw.amount),
          createdAt: new Date(claimRaw.createdAt),
          transactionHash: claimRaw.transactionHash,
        }

        return claim
      })

      // Order claims by createdAt date desc
      claims.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())

      return claims
    },
    enabled: distributorAddress !== '0x',
  })
}

import { USDCIcon, USDTIcon } from '@components'
import { useCurrencyRegistry, usePurchaseInputs, useToast } from '@contexts'
import { useCalculateNodeSalePurchaseFees, useHeightAnimation } from '@hooks'
import { AnyImage, CheckboxInput } from '@newComponents'
import { getTxUrl } from '@utils'
import BigNumber from 'bignumber.js'
import { motion } from 'framer-motion'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { VscLinkExternal } from 'react-icons/vsc'
import { Sale } from 'tokensoft-shared-types'
import { TransactionReceipt } from 'viem'
import { useAccount as useWagmiAccount } from 'wagmi'
import { NativePurchaseButton } from '../purchase-flow/purchase-buttons/native-purchase-button'
import { TokenPurchaseButton } from '../purchase-flow/purchase-buttons/token-purchase-button'
import {
  validateCompanyRightToRefuseAgreement,
  validatePurchasesFinalAgreement,
} from '../purchase-flow/purchase-confirmation-form-validations'
import { validatePaymentMethod } from '../purchase-flow/purchase-form-validations'
import { PurchaseLimits } from '../purchase-flow/purchase-limits'
import {
  NodePurchaseForm,
  PurchaseFormPaymentMethod,
} from './node-purchase-form'
import { createAmountValidator } from './node-purchase-form-validations'
import { NodePurchaseSummary } from './node-purchase-summary'
import { usePurchaseAmountMode } from '@hooks/use-purchase-amount-mode'

export interface NodePurchaseFormInputs {
  amount: string
  paymentMethod: string
  potentialLossAgreement: boolean
  feeResponsibilityAgreement: boolean
}

interface NodePurchaseFlowProps {
  sale: Sale
  onFinished: () => void
}

export const NodePurchaseFlow = ({
  sale,
  onFinished,
}: NodePurchaseFlowProps) => {
  const { address: userWalletAddress } = useWagmiAccount()
  const purchaseAmountMode = usePurchaseAmountMode(sale.eventId)

  const { convert } = useCurrencyRegistry()

  const getPurchaseOptions = (): number[] => {
    const discretePurchaseQuantities: Maybe<number[]> = []

    for (
      let i = sale.minimumPurchaseQuantity;
      i <= sale.maximumPurchaseQuantity;
      i++
    ) {
      discretePurchaseQuantities.push(i)
    }

    return discretePurchaseQuantities
  }

  const {
    register,
    watch,
    setValue,
    trigger,
    control,
    formState: { errors: formErrors, isValid: isFormValid },
  } = useForm<NodePurchaseFormInputs>({
    mode: 'onTouched',
    defaultValues: {
      paymentMethod: sale.contract.paymentMethods[0].symbol ?? '',
      amount: sale.minimumPurchaseQuantity.toString(),
      potentialLossAgreement: false,
      feeResponsibilityAgreement: false,
    },
  })

  const values = watch()

  const {
    amount: userEnteredAmount,
    paymentMethod: selectedPaymentMethodSymbol,
  } = values

  const { userPaymentMethodBalances, userWalletPurchaseTotal, chain } =
    usePurchaseInputs()

  // The number of licenses the user has already purchased
  const userWalletPurchaseQuantity = sale.totalPurchases.value
    .div(sale.token.price.value)
    .toNumber()

  const validateAmount = useCallback(
    createAmountValidator(
      userPaymentMethodBalances,
      selectedPaymentMethodSymbol,
      sale.minimumPurchaseQuantity,
      sale.maximumPurchaseQuantity,
      userWalletPurchaseQuantity,
      sale.token.price,
      convert,
    ),
    [
      userPaymentMethodBalances,
      selectedPaymentMethodSymbol,
      sale.minimumPurchase,
      sale.maximumPurchase,
      convert,
    ],
  )

  const formValidators: FormValidators<NodePurchaseFormInputs> = {
    amount: validateAmount,
    paymentMethod: validatePaymentMethod,
    potentialLossAgreement: validatePurchasesFinalAgreement,
    feeResponsibilityAgreement: validateCompanyRightToRefuseAgreement,
  }

  const selectedPaymentMethod = sale.contract.paymentMethods.find(
    (pm) => pm.symbol === selectedPaymentMethodSymbol,
  )!

  let numberOfNodes = new BigNumber(0)

  if (userEnteredAmount) {
    try {
      numberOfNodes = new BigNumber(userEnteredAmount)
    } catch (e) {
      console.error('Error processing user input:', e)
    }
  }

  useEffect(() => {
    // Revalidate amount field
    trigger('amount')
  }, [selectedPaymentMethod, trigger])

  const purchaseFees: PurchaseFees = useCalculateNodeSalePurchaseFees(
    numberOfNodes.toNumber(),
    sale.token.price,
    selectedPaymentMethod.symbol,
  )

  // Show summary on first valid entry, and persist from then on
  const shouldShowSummary = numberOfNodes.gt(0)
  const [didShowSummary, setDidShowSummary] = useState(false)
  useEffect(() => {
    if (shouldShowSummary && !didShowSummary) {
      setDidShowSummary(true)
    }
  }, [shouldShowSummary, didShowSummary])

  const { contentRef, ...motionProps } = useHeightAnimation(didShowSummary)

  const { showSuccessToast, showInfoToast } = useToast()

  const handleTransactionSuccess = (receipt: TransactionReceipt) => {
    const txUrl = getTxUrl(receipt?.transactionHash, chain)

    showSuccessToast({
      description: (
        <a
          target='_blank'
          rel='noreferrer'
          href={txUrl}
          className='text-white flex items-center justify-center gap-1'
          onClick={(e) => e.stopPropagation()}
        >
          <span>
            Your purchase was successful! Click here to view your transaction on
            the block explorer.
          </span>
          <VscLinkExternal size={24} color='white' />
        </a>
      ),
    })

    onFinished()
  }

  // const handleMinClick = useCallback(() => {
  //   setValue('amount', sale.minimumPurchaseQuantity.toString())
  //   trigger('amount')
  // }, [sale.minimumPurchaseQuantity, setValue, trigger])

  const handleMaxClick = useCallback(() => {
    /** The number of licenses the user can purchase per payment method based
     * on their current balances. */
    const userPaymentMethodQuantityBalances = userPaymentMethodBalances.map(
      (balance) => {
        const tokenPriceInPaymentMethodCurrency = convert(
          sale.token.price,
          balance.symbol,
        )

        return {
          symbol: balance.symbol,
          // Round quantity to nearest whole number
          // quantity: balance.value.div(sale.token.price.value).toNumber(),
          quantity: balance.value
            .div(tokenPriceInPaymentMethodCurrency.value)
            .integerValue(BigNumber.ROUND_DOWN)
            .toNumber(),
        }
      },
    )

    if (userPaymentMethodQuantityBalances.length === 0) {
      return
    }

    const userMaxPurchaseableInPaymentMethod =
      userPaymentMethodQuantityBalances.find(
        (balance) => balance.symbol === selectedPaymentMethod.symbol,
      )

    if (!userMaxPurchaseableInPaymentMethod) {
      return
    }

    const actualMaxPurchaseQuantity = Math.min(
      userMaxPurchaseableInPaymentMethod.quantity,
      sale.maximumPurchaseQuantity,
    )

    if (actualMaxPurchaseQuantity < sale.maximumPurchaseQuantity) {
      showInfoToast({
        description: `You can purchase a maximum of ${actualMaxPurchaseQuantity} licenses with your current ${selectedPaymentMethod.symbol} balance.`,
      })
    }

    setValue('amount', actualMaxPurchaseQuantity.toString())
    trigger('amount')
  }, [
    setValue,
    trigger,
    selectedPaymentMethod,
    convert,
    sale,
    showInfoToast,
    userPaymentMethodBalances,
  ])

  const userAmount = convert(
    {
      value: numberOfNodes.times(sale.token.price.value),
      symbol: 'USD',
    },
    selectedPaymentMethod.symbol,
  )

  const getIconForPaymentMethod = (
    symbol: string,
    isNative: boolean,
  ): Maybe<JSX.Element> => {
    if (isNative) {
      return (
        <AnyImage
          src={chain.logoUri}
          className='w-7 h-7'
          alt='native payment method'
        />
      )
    }

    switch (symbol) {
      case 'USDC':
        return <USDCIcon size={28} />
      case 'USDT':
        return <USDTIcon size={28} />
      default:
        return null
    }
  }

  const purchaseFormPaymentMethods: PurchaseFormPaymentMethod[] =
    sale.contract.paymentMethods.map((method) => {
      return {
        symbol: method.symbol,
        icon: getIconForPaymentMethod(method.symbol, method.native) ?? <></>,
      }
    })

  return (
    <>
      <div className='flex flex-col gap-6 mb-8'>
        <PurchaseLimits
          sale={sale}
          userWalletPurchaseTotal={userWalletPurchaseTotal}
        />
        <NodePurchaseForm
          key='purchase-step'
          register={register}
          trigger={trigger}
          control={control}
          errors={formErrors}
          paymentMethods={purchaseFormPaymentMethods}
          // onMinClick={handleMinClick}
          onMaxClick={handleMaxClick}
          validators={formValidators}
          discreteAmountOptions={getPurchaseOptions()}
        />
        <NodePurchaseSummary
          className='mt-2 bg-[#f9fafb] p-2 rounded-none'
          nodesPurchased={numberOfNodes.toNumber()}
          pricePerNodeInUsd={sale.token.price}
          paymentMethod={selectedPaymentMethod.symbol}
          purchaseFees={{
            protocolFeeInPaymentMethodCurrency:
              purchaseFees.protocolFeeInPaymentMethodCurrency,
            platformFeeInNativeCurrency:
              purchaseFees.platformFeeInNativeCurrency,
          }}
          recipientWallet={userWalletAddress ?? '0x'}
          isNativePaymentMethod={selectedPaymentMethod.native}
        />
      </div>
      {/* Conditional Purchase Summary */}
      <motion.div {...motionProps}>
        <div ref={contentRef} className='flex flex-col gap-6'>
          <div className='my-4 flex flex-col gap-6'>
            <CheckboxInput
              id='potentialLossAgreement'
              label={`I understand that my payment must be made on the ${chain.name} network, and that my payment will be lost if I do not follow instructions exactly.`}
              register={register}
              validate={formValidators.potentialLossAgreement}
              error={formErrors.potentialLossAgreement?.message}
            />
            <CheckboxInput
              id='feeResponsibilityAgreement'
              label='I further acknowledge and accept that the Company (as defined in the purchase agreement) reserves the right to refuse or cancel these documents at any time at its sole discretion.'
              register={register}
              validate={formValidators.feeResponsibilityAgreement}
              error={formErrors.feeResponsibilityAgreement?.message}
            />
          </div>
          <div>
            {selectedPaymentMethod.native ? (
              <NativePurchaseButton
                chain={chain}
                className='w-full'
                saleAddress={sale.id}
                paymentMethod={selectedPaymentMethod}
                purchaseAmountInNative={userAmount}
                platformFeeInDollars={sale.platformFeeInUSD}
                platformFeeInNative={purchaseFees.platformFeeInNativeCurrency}
                platformFeeRecipient={chain.platformFeeRecipient}
                onSuccess={handleTransactionSuccess}
                disabled={!isFormValid}
                purchaseAmountMode={purchaseAmountMode}
              />
            ) : (
              <TokenPurchaseButton
                // Remount on payment method or amount change to prompt new
                // approval and purchase sequence
                key={`${userAmount.value.toString()}${selectedPaymentMethod.symbol}`}
                saleAddress={sale.id}
                chain={chain}
                className='w-full'
                purchaseAmountInToken={userAmount}
                paymentMethod={selectedPaymentMethod}
                platformFeeRecipient={chain.platformFeeRecipient}
                platformFeeInDollars={sale.platformFeeInUSD}
                platformFeeInNative={purchaseFees.platformFeeInNativeCurrency}
                nativeDecimals={chain.decimals}
                onSuccess={handleTransactionSuccess}
                disabled={!isFormValid}
                purchaseAmountMode={purchaseAmountMode}
              />
            )}
          </div>
        </div>
      </motion.div>
    </>
  )
}

// following the instructions from this stackoverflow post:
//
// - https://stackoverflow.com/a/32766809

export const refreshOtherSessions = (options: { force?: boolean } = {}) => {
  const { force = false } = options
  const key = force ? 'sessionStorageForced' : 'sessionStorage'

  // another tab asked for the sessionStorage -> send it
  localStorage.setItem(key, JSON.stringify(sessionStorage))
  // the other tab should now have it, so we're done with it.
  localStorage.removeItem(key) // <- could do short timeout as well.
}

const synchronizeSessionStorage = (serializedSessionStorage: string) => {
  // another tab sent data <- get it
  const data = JSON.parse(serializedSessionStorage) as Record<string, string>

  for (const key in data) {
    sessionStorage.setItem(key, data[key])
  }

  sessionStorage.setItem('synchronized', 'true')
}

// transfers sessionStorage from one tab to another
const handleStorageEvent = (event: StorageEvent) => {
  if (!event.newValue) return // do nothing if no value to work with

  if (event.key == 'getSessionStorage') {
    refreshOtherSessions()
  } else if (
    event.key === 'sessionStorageForced' ||
    (event.key == 'sessionStorage' &&
      sessionStorage.getItem('synchronized') !== 'true')
  ) {
    synchronizeSessionStorage(event.newValue)
  }
}

// listen for changes to localStorage
if (window.addEventListener) {
  window.addEventListener('storage', handleStorageEvent, false)
} else {
  ;(window as unknown as { attachEvent: typeof addEventListener }).attachEvent(
    'onstorage' as 'storage',
    handleStorageEvent,
  )
}

// Ask other tabs for session storage (this is ONLY to trigger event)
if (sessionStorage.getItem('synchronized') !== 'true') {
  localStorage.setItem('getSessionStorage', 'truthy')
  localStorage.removeItem('getSessionStorage')
}

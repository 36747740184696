import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  EditIcon,
  Row,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  TableData,
  TableHeader,
  Text,
  TextLink,
} from '@components'
import {
  DEFAULT_BASE_CURRENCY,
  DEFAULT_BASE_CURRENCY_DECIMALS,
} from '@constants'
import { useNetworks } from '@contexts'
import { useClipboard } from '@hooks'
import {
  capitalize,
  convertBaseUnitsToDecimal,
  formatValue,
  getAddressUrl,
  getTruncatedAddress,
} from '@utils'
import { useState } from 'react'
import { AiOutlineCopy } from 'react-icons/ai'
import { VscLinkExternal } from 'react-icons/vsc'
import { useNavigate } from 'react-router-dom'

export const SaleSetupSummary = ({ context, className = '' }: any) => {
  const navigate = useNavigate()
  const { copyToClipboard } = useClipboard()
  const { getNetworkDetails } = useNetworks()
  const [isOpen, setIsOpen] = useState(!Object.keys(context).includes('id'))

  const formattedSaleContract = context.id
    ? getTruncatedAddress(context.id)
    : '-'
  const formattedRecipient = context.recipient
    ? getTruncatedAddress(context.recipient)
    : '-'
  const formattedOwner = context.owner
    ? getTruncatedAddress(context.owner)
    : '-'
  const formattedMaxQueueTime = context.fairQueueEnabled
    ? `${context.maxQueueIntervalLength} ${context.maxQueueIntervalUnits}(s)`
    : 'Not enabled'

  let formattedSalePrice
  let formattedMaxRaise
  let formattedMinPurchase
  let formattedUserLimit

  const isNativeBaseCurrency =
    context?.nativeBaseCurrency?.symbol !== DEFAULT_BASE_CURRENCY
  const baseCurrencySymbol = isNativeBaseCurrency
    ? context?.nativeBaseCurrency?.symbol
    : '$'
  const baseCurrencyDecimal = isNativeBaseCurrency
    ? context?.nativeBaseCurrency?.decimals
    : DEFAULT_BASE_CURRENCY_DECIMALS

  const formatCurrencyDisplay = (value: any) => {
    if (isNativeBaseCurrency) {
      return `${value} ${baseCurrencySymbol}`
    }
    return `${baseCurrencySymbol}${value}`
  }

  if (context?.priceBaseUnits) {
    formattedSalePrice = `${formatCurrencyDisplay(
      formatValue(
        convertBaseUnitsToDecimal(
          context.priceBaseUnits,
          baseCurrencyDecimal,
          6,
        ),
        { commas: true },
      ),
    )}`
  } else {
    formattedSalePrice = context.price
      ? `${formatCurrencyDisplay(formatValue(context.price, { commas: true }))}`
      : '-'
  }

  if (context?.maxRaiseBaseUnits) {
    formattedMaxRaise = `${formatCurrencyDisplay(
      formatValue(
        convertBaseUnitsToDecimal(
          context.maxRaiseBaseUnits,
          baseCurrencyDecimal,
        ),
        { commas: true },
      ),
    )}`
  } else {
    formattedMaxRaise = context.maxRaise
      ? `${formatCurrencyDisplay(
          formatValue(context.maxRaise, { commas: true }),
        )}`
      : '-'
  }

  if (context?.minPurchaseBaseUnits) {
    formattedMinPurchase = `${formatCurrencyDisplay(
      formatValue(
        convertBaseUnitsToDecimal(
          context.minPurchaseBaseUnits,
          baseCurrencyDecimal,
        ),
        { commas: true },
      ),
    )}`
  } else {
    formattedMinPurchase = context.minPurchase
      ? `${formatCurrencyDisplay(
          formatValue(context.minPurchase, { commas: true }),
        )}`
      : '-'
  }

  if (context?.userLimitBaseUnits) {
    formattedUserLimit = `${formatCurrencyDisplay(
      formatValue(
        convertBaseUnitsToDecimal(
          context.userLimitBaseUnits,
          baseCurrencyDecimal,
          1,
        ),
        { commas: true },
      ),
    )}`
  } else {
    formattedUserLimit = context.userLimit
      ? `${formatCurrencyDisplay(
          formatValue(context.userLimit, { commas: true }),
        )}`
      : '-'
  }

  const isSaleDeployed = !!context.id
  const key = 'sale-setup-summary'

  return (
    <Card className={className}>
      <CardHeader place={'center'}>
        <CardTitle
          open={isOpen}
          onOpen={context.id ? setIsOpen : undefined}
          data-testid={`${key}-title`}
        >
          Sale Details
        </CardTitle>
        {context.id ? (
          <Row xalign={'end'} xgap={5}>
            <TextLink
              width={'fit'}
              onClick={() => {
                navigate(
                  `/admin/project/${context.event.project.id}/event/${context.event.id}/sale/setup`,
                )
              }}
            >
              <EditIcon />
              <Text>Edit Details</Text>
            </TextLink>
          </Row>
        ) : null}
      </CardHeader>

      <CardBody open={isOpen}>
        <Stacked>
          {context.id ? (
            <Section gap={5} place={'start'}>
              <SectionTitle data-testid={`${key}-contract-details-title`}>
                Contract Details
              </SectionTitle>

              <Stretched xgap={10} ygap={5} place={'start'}>
                <Stretched ygap={5}>
                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader data-testid={`${key}-sale-contract-header`}>
                      Sale Contract
                    </TableHeader>
                    <TextLink place={'start'}>
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={getAddressUrl(
                          context?.id,
                          getNetworkDetails(context.networkId),
                        )}
                      >
                        <Row nowrap place={'start-center'} gap={2.5}>
                          <Text
                            className={'font-normal'}
                            data-testid={`${key}-sale-contract-data`}
                          >
                            {formattedSaleContract}
                          </Text>
                          <VscLinkExternal color={'var(--primary)'} />
                        </Row>
                      </a>
                    </TextLink>
                  </Col>

                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader data-testid={`${key}-owner-header`}>
                      Owner
                    </TableHeader>
                    <Row nowrap gap={2} yalign={'center'}>
                      <div
                        className='text-primary-medium cursor-pointer'
                        onClick={() => copyToClipboard(context.owner)}
                      >
                        <AiOutlineCopy size={16} />
                      </div>
                      <TableData
                        className='break-anywhere'
                        data-testid={`${key}-owner-data`}
                      >
                        {formattedOwner}
                      </TableData>
                    </Row>
                  </Col>
                </Stretched>

                <Row nowrap>
                  <Col gap={2.5} width={'1/2'} className={'hidden md:block'}>
                    <TableHeader>&nbsp;</TableHeader>
                    <Row nowrap>&nbsp;</Row>
                  </Col>

                  <Col gap={2.5} width={'1/2'} className={'hidden md:block'}>
                    <TableHeader>&nbsp;</TableHeader>
                    <Row nowrap>&nbsp;</Row>
                  </Col>
                </Row>
              </Stretched>
            </Section>
          ) : null}

          <Section gap={5} place={'start'}>
            <SectionTitle data-testid={`${key}-purchase-limits-title`}>
              Sale Basics
            </SectionTitle>

            <Stretched gap={5} place={'start'}>
              <Stretched ygap={5}>
                {!isSaleDeployed && (
                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader data-testid={`${key}-asset-type-header`}>
                      Asset Type
                    </TableHeader>
                    <TableData data-testid={`${key}-asset-type-data`}>
                      {capitalize(context?.assetType)}
                    </TableData>
                  </Col>
                )}
                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-sale-price-header`}>
                    Base Currency
                  </TableHeader>
                  <TableData data-testid={`${key}-base-currency-data`}>
                    {context?.nativeBaseCurrency?.symbol}
                  </TableData>
                </Col>
                {!isSaleDeployed && (
                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader
                      data-testid={`${key}-show-sale-progress-to-users`}
                    >
                      Show Sale Progress To Users
                    </TableHeader>
                    <TableData
                      data-testid={`${key}-show-sale-progress-to-users-data`}
                    >
                      {context?.showSaleProgressToUsers ? 'Yes' : 'No'}
                    </TableData>
                  </Col>
                )}
              </Stretched>
            </Stretched>

            <Stretched gap={5} place={'start'}>
              <Stretched ygap={5}>
                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-start-date-header`}>
                    Token Name
                  </TableHeader>
                  <TableData data-testid={`${key}-start-date-data`}>
                    {context?.tokenName}
                  </TableData>
                </Col>

                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-end-date-header`}>
                    Token Symbol
                  </TableHeader>
                  <TableData data-testid={`${key}-end-date-data`}>
                    {context?.tokenSymbol}
                  </TableData>
                </Col>
              </Stretched>

              <Stretched ygap={5}>
                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-sale-price-header`}>
                    Sale Price
                  </TableHeader>
                  <TableData data-testid={`${key}-sale-price-data`}>
                    {formattedSalePrice} per token
                  </TableData>
                </Col>

                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-recipient-header`}>
                    Payment Recipient
                  </TableHeader>
                  <Row nowrap gap={2} yalign={'center'}>
                    <div
                      className='text-primary-medium cursor-pointer'
                      onClick={() => copyToClipboard(context.recipient)}
                    >
                      <AiOutlineCopy size={16} />
                    </div>
                    <TableData
                      className='break-anywhere'
                      data-testid={`${key}-recipient-data`}
                    >
                      {formattedRecipient}
                    </TableData>
                  </Row>
                </Col>
              </Stretched>
            </Stretched>

            <Stretched gap={5} place={'start'}>
              <Stretched ygap={5}>
                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-max-raise-header`}>
                    Max Raise
                  </TableHeader>
                  <TableData data-testid={`${key}-max-raise-data`}>
                    {formattedMaxRaise}
                  </TableData>
                </Col>

                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-min-purchase-header`}>
                    Min Purchase
                  </TableHeader>
                  <TableData data-testid={`${key}-min-purchase-data`}>
                    {formattedMinPurchase}
                  </TableData>
                </Col>
              </Stretched>

              <Stretched ygap={5}>
                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-user-limit-header`}>
                    Per-User Limit
                  </TableHeader>
                  <TableData data-testid={`${key}-user-limit-data`}>
                    {formattedUserLimit}
                  </TableData>
                </Col>

                <Col gap={2.5} className={'w-full md:w-1/2'}>
                  <TableHeader data-testid={`${key}-max-queue-header`}>
                    Max Queue Time
                  </TableHeader>
                  <TableData data-testid={`${key}-max-queue-data`}>
                    {formattedMaxQueueTime}
                  </TableData>
                </Col>
              </Stretched>
            </Stretched>
          </Section>
        </Stacked>
      </CardBody>
    </Card>
  )
}

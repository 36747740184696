import {
  useGetChain,
  useGetDistributorByContractAddress,
  useMyEventUser,
} from '@apiServices'
import { LoadingIndicator, Text } from '@components'
import { useEventDocuments, useTSAddress } from '@hooks'
import { CollapsibleCard } from '@newComponents'
import { getTxUrl } from '@utils'
import { twMerge } from 'tailwind-merge'
import {
  Claim,
  EventDocument,
  EventUserDocumentStatus,
} from 'tokensoft-shared-types'
import HistoricalClaimItem from './historical-claim-item'

export interface DocumentSigningOverview {
  documentName: string
  documentUrl: HttpsUrl
  requiresSignature: boolean
  wasSigned: boolean
}

type MyClaimsCardProps = {
  eventId: ID
  claims: Claim[]
  distributorAddress: EvmAddress
  chainId: ChainId
  className?: string
}

export const MyClaimsCard = ({
  eventId,
  claims,
  distributorAddress,
  chainId,
  className = '',
}: MyClaimsCardProps) => {
  const {
    data: chain,
    isPending: isPendingChain,
    error: chainError,
  } = useGetChain(chainId)

  const {
    data: distributor,
    isPending: isPendingDistributor,
    error: distributorError,
  } = useGetDistributorByContractAddress(distributorAddress)

  const {
    data: eventUser,
    isPending: isPendingEventUser,
    error: eventUserError,
  } = useMyEventUser(eventId)

  const {
    data: eventDocumentData,
    isPending: isPendingEventDocuments,
    error: eventDocumentsError,
  } = useEventDocuments(eventId)

  const mapDocumentsToSigningOverview = (
    documents: EventDocument[],
    userStatuses: EventUserDocumentStatus[],
  ): DocumentSigningOverview[] => {
    const statusMap = new Map<number, EventUserDocumentStatus>(
      userStatuses.map((status) => [status.eventDocumentVersionId, status]),
    )

    return documents.map((document) => ({
      documentName: document.name,
      documentUrl: document.uri as HttpsUrl,
      requiresSignature: document.requiresSignature,
      wasSigned: Boolean(statusMap.get(document.documentId)?.signedAt),
    }))
  }

  const { address } = useTSAddress()

  if (
    isPendingChain ||
    isPendingDistributor ||
    isPendingEventUser ||
    isPendingEventDocuments
  ) {
    return <LoadingIndicator />
  }

  if (chainError || distributorError || eventUserError || eventDocumentsError) {
    return (
      <Text className='text-red-500'>
        {chainError?.message ||
          distributorError?.message ||
          eventUserError?.message ||
          eventDocumentsError?.message}
      </Text>
    )
  }

  const claimCurrencyValues: CurrencyValue[] = claims.map((claim) => {
    return {
      value: claim.amount.div(10 ** distributor.token.decimals),
      symbol: distributor.token.symbol,
    }
  })

  const claimTransactionUrls: HttpsUrl[] = claims.map(
    (claim) => getTxUrl(claim.transactionHash, chain) as HttpsUrl,
  )

  const recipientWallet = eventUser?.beneficiary_wallet ?? address ?? '0x'
  return (
    <CollapsibleCard
      cardTitle='Claims'
      className={twMerge(claims.length === 0 ? 'hidden' : '', className)}
    >
      <div className='flex flex-col gap-6'>
        {claims?.map((claim, index) => (
          <div
            key={index}
            className='pb-6 first:pt-4 last:pb-0 border-b last:border-none'
          >
            <HistoricalClaimItem
              claim={claim}
              transactionUrl={claimTransactionUrls[index]}
              claimCurrencyValue={claimCurrencyValues[index]}
              recipientWallet={recipientWallet}
              documentsSigningOverview={mapDocumentsToSigningOverview(
                eventDocumentData.eventDocuments ?? [],
                eventDocumentData.eventUserDocumentsStatus ?? [],
              )}
            />
          </div>
        ))}
      </div>
    </CollapsibleCard>
  )
}

import { useGetChain } from '@api/services'
import { useMyEventUser } from '@api/services/event-service'
import {
  Button,
  ClaimCompleteIcon,
  ClaimInitializeIcon,
  Text,
} from '@components'
import { FormattedCurrency, LoaderButton } from '@new-components'
import { getTxUrl, useSubmitClaim } from '@utils'
import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { VscLinkExternal } from 'react-icons/vsc'
import {
  Distributor,
  DistributorMerkleTreeLeaf,
  Erc20Token,
  TSEvent,
} from 'tokensoft-shared-types'
import { TransactionReceipt } from 'viem'

interface ClaimStepProps {
  event: TSEvent
  distributor: Distributor
  chainId: number
  distributorAddress: EvmAddress
  token: Erc20Token
  claimAmount: BigNumber
  merkleLeaf: DistributorMerkleTreeLeaf
  onClaimSuccess: (receipt: TransactionReceipt) => void
  onFinished: () => void
}

export const ClaimStep = ({
  event,
  distributor,
  chainId,
  distributorAddress,
  token,
  claimAmount,
  merkleLeaf,
  onClaimSuccess,
  onFinished,
}: ClaimStepProps) => {
  const {
    write: submitClaim,
    isSubmitting: isClaiming,
    ...waitForTransactionResponse
  } = useSubmitClaim({ event, distributor })

  const {
    data: chain,
    isPending: isChainPending,
    error: chainError,
  } = useGetChain(chainId)

  useEffect(() => {
    if (waitForTransactionResponse.isSuccess) {
      onClaimSuccess(waitForTransactionResponse.data)
    }
  }, [waitForTransactionResponse, onClaimSuccess])

  const handleClaim = () => {
    if (event.useExperimentalContractFeatures && event.useCustomBeneficiary) {
      const beneficiaryWallet = eventUser.data?.beneficiary_wallet

      if (beneficiaryWallet === undefined) {
        throw new Error('required Beneficiary Wallet is missing')
      }
    }

    // [proofIndex, beneficiary, amount, merkleProof]
    void submitClaim(chainId, distributorAddress, [
      merkleLeaf.index,
      merkleLeaf.beneficiary,
      merkleLeaf.amount,
      merkleLeaf.merkleProof,
    ])
  }

  const eventUser = useMyEventUser(event.id)

  if (isChainPending || eventUser.isLoading) {
    return (
      <div className='flex flex-col items-center w-full p-8 gap-4'>
        <ClaimInitializeIcon />
        <Text className='text-2xl font-semibold text-black'>Loading...</Text>
      </div>
    )
  }

  if (chainError || eventUser.error) {
    return (
      <div className='flex flex-col items-center w-full p-8 gap-4'>
        <ClaimInitializeIcon />
        <Text className='text-2xl font-semibold text-black'>
          Error loading user data:{' '}
          {chainError?.message || eventUser.error?.message}
        </Text>
      </div>
    )
  }

  return (
    <div className='flex flex-col items-center p-8 gap-4 justify-center'>
      {waitForTransactionResponse.isSuccess ? (
        <ClaimCompleteIcon />
      ) : (
        <ClaimInitializeIcon />
      )}
      <Text className='text-2xl font-semibold text-black'>
        {isClaiming ? 'Claim Initiated!' : 'Claim Token'}
      </Text>
      {waitForTransactionResponse.isLoading ? (
        <Text textAlign='center'>
          You have successfully submitted your claim for{' '}
          <FormattedCurrency
            currencyValue={{ value: claimAmount, symbol: token.symbol }}
            displayDecimals={2}
          />{' '}
          to be claimed on the {chain.name} network.
        </Text>
      ) : !waitForTransactionResponse.isSuccess ? (
        <div className='flex gap-2 items-center'>
          <Text className=''>Amount:</Text>
          <Text
            className='text-xl font-bold text-black'
            data-testid='claimable-amount'
          >
            <FormattedCurrency
              data-testid='claimable-amount'
              currencyValue={{ value: claimAmount, symbol: token.symbol }}
              displayDecimals={2}
            />{' '}
          </Text>
        </div>
      ) : (
        <></>
      )}
      {event.useCustomBeneficiary &&
        (eventUser.data ? (
          <div className='flex gap-2 items-center'>
            <Text className='text-sm'>Beneficiary:</Text>
            <Text
              className='text-sm font-bold text-black font-mono'
              data-testid='claimBeneficiaryWallet'
            >
              {eventUser.data.beneficiary_wallet}
            </Text>
          </div>
        ) : (
          <div className='flex gap-2 items-center'>
            <Text className=''>Beneficiary:</Text>
            <Text className='text-xl font-bold text-gray-100'>Loading</Text>
          </div>
        ))}
      {waitForTransactionResponse.isSuccess && (
        <>
          <p className='max-w-96 text-center'>
            You have successfully claimed{' '}
            <FormattedCurrency
              currencyValue={{ value: claimAmount, symbol: token.symbol }}
              displayDecimals={2}
            />{' '}
            on the {chain.name} network.
          </p>
          <span>
            <a
              data-testid='claimTransactionLink'
              className='text-primary-medium flex justify-start gap-1 items-center'
              href={getTxUrl(
                waitForTransactionResponse.data.transactionHash,
                chain,
              )}
              target='_blank'
              rel='noreferrer'
            >
              Transaction Details <VscLinkExternal size={14} />
            </a>
          </span>
        </>
      )}
      {waitForTransactionResponse.isSuccess ? (
        <Button className='w-full rounded-full' onClick={onFinished}>
          Got It
        </Button>
      ) : (
        <LoaderButton
          className='w-full rounded-full'
          onClick={handleClaim}
          isLoading={isClaiming || waitForTransactionResponse.isLoading}
          loadingText='Submitting'
          text='Claim'
          disabled={
            isClaiming ||
            waitForTransactionResponse.isLoading ||
            eventUser.isLoading
          }
        />
      )}
    </div>
  )
}

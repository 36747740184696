import { IconProps } from './icon-props'

export const ChevronDownIcon = ({
  color,
  width = 20,
  height = 16,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={color ?? 'none'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.99001 10.6645C9.80535 10.6649 9.62641 10.6029 9.48422 10.4894L4.74252 6.68184C4.58113 6.55258 4.47964 6.36684 4.46038 6.16547C4.44111 5.96411 4.50564 5.76362 4.63979 5.60811C4.77393 5.45259 4.96669 5.3548 5.17566 5.33623C5.38463 5.31766 5.5927 5.37985 5.75409 5.50911L9.99001 8.92068L14.2259 5.63095C14.3068 5.5677 14.3998 5.52046 14.4996 5.49195C14.5995 5.46345 14.7042 5.45424 14.8077 5.46485C14.9113 5.47547 15.0116 5.5057 15.103 5.55381C15.1944 5.60191 15.275 5.66695 15.3402 5.74518C15.4126 5.82348 15.4674 5.91534 15.5012 6.015C15.535 6.11466 15.5471 6.21998 15.5368 6.32435C15.5264 6.42873 15.4937 6.52991 15.4409 6.62156C15.388 6.71322 15.3161 6.79336 15.2296 6.85698L10.4879 10.5351C10.3416 10.6307 10.1663 10.6762 9.99001 10.6645Z'
        fill={color ?? 'black'}
        stroke={color ?? 'black'}
      />
    </svg>
  )
}
